import React, { Suspense, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router';
import * as Sentry from '@sentry/react';
import { useServiceWorkerContext } from 'goldilocks/context/ServiceWorkerContext';
import { CurrentVersionQuery } from 'goldilocks/graphql/version/queries.graphql';
import { lazyWithRetry as lazy } from '@/utilities/lazyWithRetry';
import MakeRouteWithSubRoutes from './makeRouteWithSubRoutes';

const ScoringRuleset = lazy(
  () =>
    import(
      /* webpackChunkName: "CreateEditScoringRuleset", webpackPrefetch: true */ 'goldilocks/pages/LeadScoringRules/CreateEditScoringRuleset'
    )
);
const DashboardAnalytics = lazy(
  () =>
    import(/* webpackChunkName: "DashboardAnalytics", webpackPrefetch: true */ 'goldilocks/pages/Dashboards/Analytics')
);
const DashboardAttribution = lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardAttribution", webpackPrefetch: true */ 'goldilocks/pages/Dashboards/Attribution'
    )
);
const DashboardAutomationComparison = lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardAutomationComparison", webpackPrefetch: true */ 'goldilocks/pages/Dashboards/AutomationComparison/AutomationComparisonDashboard'
    )
);
const AutomationPerformanceDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "AutomationPerformanceDashboard", webpackPrefetch: true */ 'goldilocks/pages/Dashboards/AutomationPerformance'
    )
);
const MyLists = lazy(() => import(/* webpackChunkName: "MyLists", webpackPrefetch: true */ './pages/Lists/MyLists'));
const MarketingContributionDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "MarketingContributionDashboard", webpackPrefetch: true */ 'goldilocks/pages/Dashboards/MarketingContribution'
    )
);
const EmailEngagementDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "EmailEngagementDashboard", webpackPrefetch: true */ 'goldilocks/pages/Dashboards/EmailEngagement'
    )
);
const TrafficSourcesDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "TrafficSourcesDashboard", webpackPrefetch: true */ 'goldilocks/pages/Dashboards/TrafficSources'
    )
);
const BounceReasonsDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "BounceReasonsDashboard", webpackPrefetch: true */ 'goldilocks/pages/Dashboards/BounceReasons'
    )
);
const Account = lazy(
  () => import(/* webpackChunkName: "Account", webpackPrefetch: true */ 'goldilocks/pages/Accounts/Account')
);
const LandingPageDomainWizard = lazy(
  () =>
    import(
      /* webpackChunkName: "LandingPageDomainWizard", webpackPrefetch: true */ '@/components/Settings/Account/Setup/Wizards/LandingPageDomain'
    )
);
const JoyRideExample = lazy(
  () => import(/* webpackChunkName: "JoyRideExample", webpackPrefetch: true */ '@/components/Examples/JoyRide')
);
const DashletExamples = lazy(
  () => import(/* webpackChunkName: "DashletExamples", webpackPrefetch: true */ '@/components/Examples/Dashlets')
);
const ManageCustomObject = lazy(
  () =>
    import(
      /* webpackChunkName: "ManageCustomObject", webpackPrefetch: true */ 'goldilocks/pages/CustomObjects/ManageCustomObject'
    )
);
const MyCustomObjectX = lazy(
  () =>
    import(/* webpackChunkName: "MyCustomObjectX", webpackPrefetch: true */ 'goldilocks/pages/CustomObjects/MyObjectX')
);
const DashboardHome = lazy(
  () => import(/* webpackChunkName: "DashboardHome", webpackPrefetch: true */ 'goldilocks/pages/Dashboards')
);
const Home = lazy(() => import(/* webpackChunkName: "Home", webpackPrefetch: true */ './pages/Home'));
const NoMatch = lazy(() => import(/* webpackChunkName: "NoMatch", webpackPrefetch: true */ './pages/NoMatch'));
const Examples = lazy(() => import(/* webpackChunkName: "Examples", webpackPrefetch: true */ './pages/Examples'));
const OldDashboardDownloads = lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardDownloads", webpackPrefetch: true */ './pages/Dashboards/Downloads/OldDashboardDownloads'
    )
);
const DashboardDownloads = lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardDownloads", webpackPrefetch: true */ './pages/Dashboards/Downloads/DashboardDownloads'
    )
);
const Segments = lazy(
  () =>
    import(/* webpackChunkName: "SegmentBuilder", webpackPrefetch: true */ './pages/GlobalSegments/MyGlobalSegments')
);
const Actions = lazy(() => import(/* webpackChunkName: "Actions", webpackPrefetch: true */ './pages/AutomatedActions'));
const CreateTasks = lazy(
  () =>
    import(
      /* webpackChunkName: "CreateTasks", webpackPrefetch: true */ 'goldilocks/pages/AutomatedActions/CreateCrmTasksActions'
    )
);
const MyCampaigns = lazy(
  () => import(/* webpackChunkName: "MyCampaigns", webpackPrefetch: true */ './pages/Automations/MyAutomations')
);
const Approvals = lazy(
  () => import(/* webpackChunkName: "Approvals", webpackPrefetch: true */ './pages/Automations/Approvals')
);
const WorkFlowsCreateEdit = lazy(
  () => import(/* webpackChunkName: "WorkFlowsCreateEdit", webpackPrefetch: true */ './pages/Automations/CreateEdit')
);
const DripSeriesCreateEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "DripSeriesCreateEdit", webpackPrefetch: true */ 'goldilocks/pages/Automations/DripSeries'
    )
);
const MarketingCampaigns = lazy(
  () =>
    import(/* webpackChunkName: "MarketingCampaigns", webpackPrefetch: true */ 'goldilocks/pages/MarketingCampaigns')
);
const MyHotJobAlerts = lazy(
  () => import(/* webpackChunkName: "MyHotJobAlerts", webpackPrefetch: true */ './pages/HotJobAlerts/MyHotJobAlerts')
);
const MyRecurringJobSummaries = lazy(
  () =>
    import(
      /* webpackChunkName: "MyHotJobAlerts", webpackPrefetch: true */ './pages/RecurringJobSummaries/MyRecurringJobSummaries'
    )
);
const MySms = lazy(() => import(/* webpackChunkName: "MySms", webpackPrefetch: true */ './pages/SmsMessages/MySms'));
const SocialAccounts = lazy(
  () => import(/* webpackChunkName: "SocialAccounts", webpackPrefetch: true */ './pages/SocialPosts/SocialAccounts')
);
const SocialPosts = lazy(
  () => import(/* webpackChunkName: "SocialPosts", webpackPrefetch: true */ './pages/SocialPosts')
);
const SocialPostsCalendarView = lazy(
  () =>
    import(
      /* webpackChunkName: "SocialPostsCalendarView", webpackPrefetch: true */ './pages/SocialPosts/SocialPostsCalendarView/index'
    )
);
const SocialPostsListView = lazy(
  () =>
    import(
      /* webpackChunkName: "SocialPostsListView", webpackPrefetch: true */ './pages/SocialPosts/SocialPostsListView/index'
    )
);
const WebsiteVisitorsDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "WebsiteVisitorsDashboard", webpackPrefetch: true */
      './pages/Dashboards/WebsiteVisitors'
    )
);
const PopularWebPages = lazy(
  () =>
    import(
      /* webpackChunkName: "PopularWebPages", webpackPrefetch: true */ 'goldilocks/pages/Dashboards/PopularWebPages'
    )
);

const TrafficSources = lazy(
  () => import(/* webpackChunkName: "TrafficSources", webpackPrefetch: true */ './pages/Dashboards/TrafficSources')
);
const ContactsDashboard = lazy(
  () => import(/* webpackChunkName: "ContactsDashboard", webpackPrefetch: true */ './pages/Dashboards/Contacts')
);
const EmailClientsDashboard = lazy(
  () => import(/* webpackChunkName: "EmailClientsDashboard", webpackPrefetch: true */ './pages/Dashboards/EmailClients')
);
const ListDashboard = lazy(
  () => import(/* webpackChunkName: "ListDashboard", webpackPrefetch: true */ './pages/Dashboards/MarketingLists/index')
);
const MyWebinars = lazy(
  () => import(/* webpackChunkName: "MyEmails", webpackPrefetch: true */ './pages/Webinars/MyWebinars')
);
const WebinarDashboard = lazy(
  () => import(/* webpackChunkName: "WebinarDashboard", webpackPrefetch: true */ './pages/Dashboards/Webinars/index')
);
const LenovoDashboard = lazy(
  () => import(/* webpackChunkName: "LenovoDashboard", webpackPrefetch: true */ './pages/Dashboards/Lenovo/index')
);
const AutomationOverviewDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "AutomationOverviewDashboard", webpackPrefetch: true */ './pages/Dashboards/AutomationOverview'
    )
);
const SocialPageOverviewDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "SocialPageOverviewDashboard", webpackPrefetch: true */ './pages/Dashboards/SocialPageOverview'
    )
);
const DashboardSubscription = lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardSubscription", webpackPrefetch: true */ './pages/Dashboards/SubscriptionLists/index'
    )
);
const DashboardsConversations = lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardsConversations", webpackPrefetch: true */ './pages/Dashboards/Conversations/DashboardsConversations'
    )
);
// const MyLeads = lazy(
//   () => import(/* webpackChunkName: "MyLeads", webpackPrefetch: true */ './pages/Dashboards/MyLeads/MyLeads')
// );
const SalesTasks = lazy(
  () => import(/* webpackChunkName: "SalesTasks", webpackPrefetch: true */ './pages/Sales/Tasks')
);
const Opportunities = lazy(
  () => import(/* webpackChunkName: "Opportunities", webpackPrefetch: true */ './pages/Sales/Opportunities')
);
const Calendar = lazy(() => import(/* webpackChunkName: "Calendar", webpackPrefetch: true */ './pages/Calendar'));
const UrlBuilder = lazy(() => import(/* webpackChunkName: "UrlBuilder", webpackPrefetch: true */ './pages/UrlBuilder'));
const MyAccounts = lazy(
  () => import(/* webpackChunkName: "MyAccounts", webpackPrefetch: true */ './pages/Accounts/MyAccounts')
);
const MyAlerts = lazy(
  () => import(/* webpackChunkName: "MyAlerts", webpackPrefetch: true */ './pages/Alerts/MyAlerts')
);
const MyCustomObjects = lazy(
  () =>
    import(
      /* webpackChunkName: "MyCustomObjects", webpackPrefetch: true */ './pages/Accounts/CustomObjects/MyCustomObjects'
    )
);
const MyForms = lazy(
  () =>
    import(
      /* webpackChunkName: "MyForms", webpackPrefetch: true */ 'goldilocks/pages/NextGenForms/components/FormTable'
    )
);
const FormSubmissions = lazy(
  () =>
    import(/* webpackChunkName: "FormSubmissions", webpackPrefetch: true */ '@/components/Forms/FormSubmissionsTable')
);
const FormBuilder = lazy(
  () => import(/* webpackChunkName: "FormsEdit", webpackPrefetch: true */ 'goldilocks/pages/Builders/Forms/EditForm')
);
const WebsitePersonalizationBuilder = lazy(
  () =>
    import(
      /* webpackChunkName: "WebsitePersonalizationEdit", webpackPrefetch: true */ 'goldilocks/pages/Builders/WebsitePersonalization/components/EditWebsitePersonalization'
    )
);
const MyNextGenEmails = lazy(
  () =>
    import(
      /* webpackChunkName: "EmailEdit", webpackPrefetch: true */ 'goldilocks/pages/NextGenEmails/components/EmailTable'
    )
);
const EmailBuilder = lazy(
  () =>
    import(
      /* webpackChunkName: "EmailEdit", webpackPrefetch: true */ 'goldilocks/pages/Builders/Emails/components/EditEmail'
    )
);
const ClassicForms = lazy(
  () => import(/* webpackChunkName: "ClassicForms", webpackPrefetch: true */ './pages/Forms/MyForms')
);
const FormsCreateEdit = lazy(
  () => import(/* webpackChunkName: "FormsCreateEdit", webpackPrefetch: true */ './pages/Forms/CreateEdit')
);
const MyMappedForms = lazy(
  () => import(/* webpackChunkName: "MyMappedForms", webpackPrefetch: true */ './pages/MappedForms/MyMappedForms')
);
const CreateEditMappedForm = lazy(
  () =>
    import(
      /* webpackChunkName: "CreateEditMappedForm", webpackPrefetch: true */ './pages/MappedForms/CreateEditMappedForm'
    )
);
const MappedFormConversions = lazy(
  () =>
    import(
      /* webpackChunkName: "MappedFormConversions", webpackPrefetch: true */ './pages/MappedForms/MappedFormConversions'
    )
);
const Settings = lazy(() => import(/* webpackChunkName: "Settings", webpackPrefetch: true */ './pages/Settings'));
const MyLandingPages = lazy(
  () => import(/* webpackChunkName: "MyLandingPages", webpackPrefetch: true */ './pages/LandingPages/MyLandingPages')
);
const CustomHtmlLandingPageCreateEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "CustomHtmlLandingPageCreateEdit", webpackPrefetch: true */ './pages/LandingPages/CustomHtmlCreateEdit'
    )
);
const MySubscriptions = lazy(
  () => import(/* webpackChunkName: "MySubscriptions", webpackPrefetch: true */ './pages/Subscriptions/MySubscriptions')
);
const MyEmails = lazy(
  () => import(/* webpackChunkName: "MyEmails", webpackPrefetch: true */ './pages/Emails/MyEmails')
);
const EmailDashboard = lazy(
  () => import(/* webpackChunkName: "EmailDashboard", webpackPrefetch: true */ './pages/Dashboards/EmailPerformance')
);
const EmailTemplates = lazy(
  () => import(/* webpackChunkName: "EmailTemplates", webpackPrefetch: true */ './pages/Emails/EmailTemplates')
);
const EmailsCreateEdit = lazy(
  () => import(/* webpackChunkName: "EmailsCreateEdit", webpackPrefetch: true */ './pages/Emails/CreateEdit')
);
const SendConversationEmail = lazy(
  () =>
    import(
      /* webpackChunkName: "SendConversationEmail", webpackPrefetch: true */ './pages/Emails/SendConversationEmail'
    )
);
const MyLeadScoringRules = lazy(
  () =>
    import(
      /* webpackChunkName: "MyLeadScoringRules", webpackPrefetch: true */ './pages/LeadScoringRules/MyLeadScoringRules'
    )
);
const MyReports = lazy(
  () => import(/* webpackChunkName: "MyReports", webpackPrefetch: true */ './pages/Reports/MyReports')
);
const CreateEditReport = lazy(
  () => import(/* webpackChunkName: "CreateEditReport", webpackPrefetch: true */ './pages/Reports/CreateEdit')
);
const MyUploadHistory = lazy(
  () =>
    import(/* webpackChunkName: "MyUploadHistory", webpackPrefetch: true */ 'goldilocks/pages/Contacts/UploadHistory')
);
const DownloadCenter = lazy(
  () => import(/* webpackChunkName: "DownloadCenter", webpackPrefetch: true */ './pages/Reports/DownloadCenter')
);
const DynamicsSettings = lazy(
  () => import(/* webpackPrefetch: true */ '@/components/Settings/Account/Integrations/Dynamics')
);
const SalesforceSettings = lazy(
  () => import(/* webpackPrefetch: true */ '@/components/Settings/Account/Integrations/Salesforce')
);
const IfsSettings = lazy(() => import(/* webpackPrefetch: true */ '@/components/Settings/Account/Integrations/CRM'));
const SugarCrmSettings = lazy(
  () => import(/* webpackPrefetch: true */ '@/components/Settings/Account/Integrations/SugarCrm')
);
const Contact = lazy(
  () => import(/* webpackChunkName: "Contact", webpackPrefetch: true */ 'goldilocks/pages/Contacts/ContactDetails')
);
const LenovoContact = lazy(
  () =>
    import(
      /* webpackChunkName: "LenovoContact", webpackPrefetch: true */ 'goldilocks/pages/Dashboards/Lenovo/components/ContactDetailsLenovo'
    )
);
const AuthLayout = lazy(
  () => import(/* webpackChunkName: "AuthLayout", webpackPrefetch: true */ './layouts/AuthLayout')
);
const ProductRoadmap = lazy(
  () => import(/* webpackChunkName: "ProductRoadmap", webpackPrefetch: true */ './pages/ProductRoadmap')
);
const Notifications = lazy(
  () => import(/* webpackChunkName: "Notifications", webpackPrefetch: true */ './pages/Notifications')
);
const Backdoor = lazy(
  () => import(/* webpackChunkName: "Backdoor", webpackPrefetch: true */ '@/components/Auth/Backdoor')
);
const SingleSignOn = lazy(
  () => import(/* webpackChunkName: "SingleSignOn", webpackPrefetch: true */ '@/components/Auth/SingleSignOn')
);
const BillingStatement = lazy(
  () =>
    import(
      /* webpackChunkName: "BillingStatement", webpackPrefetch: true */ '@/components/Settings/Account/Billing/BillingStatement'
    )
);
const MyContacts = lazy(
  () => import(/* webpackChunkName: "MyContacts", webpackPrefetch: true */ './pages/Contacts/MyContacts')
);
const UploadContacts = lazy(
  () => import(/* webpackChunkName: "UploadContacts", webpackPrefetch: true */ 'goldilocks/pages/Contacts/Upload')
);
const BouncedContacts = lazy(
  () =>
    import(/* webpackChunkName: "BouncedContacts", webpackPrefetch: true */ 'goldilocks/pages/Contacts/BouncedContacts')
);

const SunsetContacts = lazy(
  () =>
    import(
      /* webpackChunkName: "SunsetContacts", webpackPrefetch: true */ 'goldilocks/pages/Contacts/SunsetContacts/SunsetContactsTable'
    )
);

const UnsubscribedContacts = lazy(
  () =>
    import(
      /* webpackChunkName: "UnsubscribedContacts", webpackPrefetch: true */ 'goldilocks/pages/Contacts/UnsubscribedContacts'
    )
);
const ReactFlowExamples = lazy(
  () => import(/* webpackChunkName: "ReactFlowExamples", webpackPrefetch: true */ './pages/Examples/ReactFlow')
);
const ChartExamples = lazy(
  () => import(/* webpackChunkName: "ChartExamples", webpackPrefetch: true */ './pages/Examples/charts')
);
const Workflow = lazy(() => import(/* webpackChunkName: "Workflow", webpackPrefetch: true */ './pages/Automations'));
const Flow = lazy(() => import(/* webpackChunkName: "Workflow", webpackPrefetch: true */ './pages/Flow'));
const DndKitGrid = lazy(
  () => import(/* webpackChunkName: "DndKitGrid", webpackPrefetch: true */ '@/components/Examples/DndKitGrid')
);
const ConversionsIframeDashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "ConversionsIframeDashboard", webpackPrefetch: true */ './pages/Dashboards/ConversionsIframe'
    )
);
const FormSubmissionsDashboard = lazy(
  () =>
    import(/* webpackChunkName: "ConversionsDashboard", webpackPrefetch: true */ './pages/Dashboards/FormSubmissions')
);
const ReactHookForm = lazy(
  () => import(/* webpackChunkName: "ReactHookForm", webpackPrefetch: true */ './pages/Examples/ReactHookForm')
);
const LexicalExample = lazy(
  () => import(/* webpackChunkName: "LexicalExample", webpackPrefetch: true */ './pages/Examples/Lexical')
);
const AccountSwap = lazy(
  () => import(/* webpackChunkName: "AccountSwap", webpackPrefetch: true */ './pages/Examples/AccountSwap')
);
const DataGridExample = lazy(
  () => import(/* webpackChunkName: "DataGridExample", webpackPrefetch: true */ './pages/Examples/DataGrid')
);
const CreateEditGlobalSegment = lazy(
  () =>
    import(
      /* webpackChunkName: "CreateEditGlobalSegment", webpackPrefetch: true */ 'goldilocks/pages/GlobalSegments/CreateEditGlobalSegment'
    )
);
const OauthCreateEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "OauthCreateEdit", webpackPrefetch: true */ '@/components/Settings/Account/OAuthApps/CreateEdit'
    )
);
const ElFinderFileManager = lazy(
  () => import(/* webpackChunkName: "ElFinderFileManager", webpackPrefetch: true */ './pages/FileManagerElFinder')
);
const FileManager = lazy(
  () => import(/* webpackChunkName: "FileManager", webpackPrefetch: true */ './pages/FileManager')
);
const PricePlans = lazy(() => import(/* webpackChunkName: "PricePlans", webpackPrefetch: true */ './pages/PricePlans'));
const DataEnrichmentIntegrations = lazy(
  () => import(/* webpackChunkName: "PricePlans", webpackPrefetch: true */ './pages/DataEnrichment/Integrations')
);
const DataEnrichmentRevisions = lazy(
  () => import(/* webpackChunkName: "PricePlans", webpackPrefetch: true */ './pages/DataEnrichment/Revisions')
);
const DataEnrichmentHistory = lazy(
  () => import(/* webpackChunkName: "PricePlans", webpackPrefetch: true */ './pages/DataEnrichment/History')
);

const CreateBrandKit = lazy(
  () =>
    import(
      /* webpackChunkName: "CreateBrandKit", webpackPrefetch: true */ '@/components/Settings/Account/BrandKit/EditBrandKit'
    )
);
const MyLeads = lazy(() => import(/* webpackChunkName: "MyLeads", webpackPrefetch: true */ './pages/Leads/MyLeads'));
const MyOpportunities = lazy(
  () => import(/* webpackChunkName: "MyOpportunities", webpackPrefetch: true */ './pages/Opportunities/MyOpportunities')
);
const MyPersonAccounts = lazy(
  () =>
    import(/* webpackChunkName: "MyPersonAccounts", webpackPrefetch: true */ './pages/PersonAccounts/MyPersonAccounts')
);
const PersonAccount = lazy(
  () =>
    import(
      /* webpackChunkName: "Contact", webpackPrefetch: true */ 'goldilocks/pages/PersonAccounts/components/PersonAccount'
    )
);
const MyActions = lazy(
  () => import(/* webpackChunkName: "MyActions", webpackPrefetch: true */ './pages/Actions/MyActions')
);
const SunsetContactsAutomation = lazy(
  () =>
    import(
      /* webpackChunkName: "SunsetContactsAutomation", webpackPrefetch: true */ './pages/Automations/SunsetContacts'
    )
);

const routes = [
  {
    path: '/',
    exact: true,
    redirect: <Redirect to="/home" />,
  },
  {
    path: '/login',
    exact: true,
    component: AuthLayout,
  },
  {
    path: '/accounts',
    component: MyAccounts,
  },
  {
    path: '/account',
    component: Account,
  },
  {
    path: ['/contacts', '/contacts/createEdit', '/contacts/view/createEdit'],
    exact: true,
    component: MyContacts,
  },
  {
    path: '/contacts/import',
    component: UploadContacts,
  },
  {
    path: '/contacts/bounced',
    component: BouncedContacts,
  },
  {
    path: '/contacts/sunset',
    component: SunsetContacts,
  },
  {
    path: '/contacts/unsubscribed',
    component: UnsubscribedContacts,
  },
  {
    path: '/contact',
    component: Contact,
  },
  {
    path: '/sales/tasks',
    component: SalesTasks,
  },
  {
    path: '/sales/opportunities',
    component: Opportunities,
  },
  {
    path: '/settings/account/brand-kit/edit',
    component: CreateBrandKit,
  },
  {
    path: '/marketing-center/calendar',
    exact: true,
    component: Calendar,
  },
  {
    path: '/marketing-center/url-builder',
    exact: true,
    component: UrlBuilder,
  },
  {
    path: '/custom-objects',
    exact: true,
    component: MyCustomObjects,
  },
  {
    path: '/custom-object',
    exact: true,
    component: ManageCustomObject,
  },
  {
    path: '/custom-object/datagrid',
    component: MyCustomObjectX,
  },
  {
    path: '/contact-imports',
    exact: true,
    component: MyUploadHistory,
  },
  {
    path: '/marketing-center/lead-scoring',
    exact: true,
    component: MyLeadScoringRules,
  },
  {
    path: '/marketing-center/scoring-ruleset',
    component: ScoringRuleset,
  },
  {
    path: '/marketing-center/lists',
    component: MyLists,
  },
  {
    path: '/marketing-center/subscriptions',
    component: MySubscriptions,
  },
  {
    path: '/marketing-center/landing-pages/customHtml/createEdit',
    component: CustomHtmlLandingPageCreateEdit,
  },
  {
    path: '/marketing-center/landing-pages',
    component: MyLandingPages,
  },
  {
    path: '/reporting/dashboards',
    exact: true,
    component: DashboardHome,
  },
  {
    path: '/marketing-center/actions',
    exact: true,
    component: Actions,
  },
  {
    path: '/marketing-center/actions/create-tasks',
    component: CreateTasks,
  },
  {
    path: '/marketing-center/automations/createEdit',
    exact: true,
    component: WorkFlowsCreateEdit,
  },
  {
    path: '/marketing-center/automations/sunsetContacts/edit',
    exact: true,
    component: SunsetContactsAutomation,
  },
  {
    path: '/marketing-center/automation',
    exact: true,
    component: Workflow,
  },
  {
    path: '/marketing-center/automations/approvals',
    exact: true,
    component: Approvals,
  },
  {
    path: '/marketing-center/automations',
    component: MyCampaigns,
  },
  {
    path: '/marketing-center/drip-series/createEdit',
    component: DripSeriesCreateEdit,
  },
  {
    path: '/marketing-center/marketing-campaigns',
    component: MarketingCampaigns,
  },
  {
    path: '/marketing-center/hot-job-alerts',
    component: MyHotJobAlerts,
  },
  {
    path: '/marketing-center/recurring-job-summaries',
    component: MyRecurringJobSummaries,
  },
  {
    path: '/marketing-center/my-actions',
    component: MyActions,
  },
  {
    path: '/marketing-list',
    component: ListDashboard,
  },
  {
    path: '/marketing-center/webinars',
    component: MyWebinars,
  },
  {
    path: '/reporting/dashboards/webinar',
    component: WebinarDashboard,
  },
  {
    path: '/lenovo/contact',
    component: LenovoContact,
  },
  {
    path: '/lenovo/reporting/dashboard',
    component: LenovoDashboard,
  },
  {
    path: '/reporting/dashboards/automation-overview',
    component: AutomationOverviewDashboard,
  },
  {
    path: '/reporting/dashboards/social-page-overview',
    component: SocialPageOverviewDashboard,
  },
  {
    path: '/subscription-list',
    component: DashboardSubscription,
  },
  {
    path: '/reporting/dashboards/analytics',
    exact: true,
    component: DashboardAnalytics,
  },
  {
    path: '/reporting/dashboards/attribution',
    exact: true,
    component: DashboardAttribution,
  },
  {
    path: '/reporting/dashboards/automation-comparison',
    exact: true,
    component: DashboardAutomationComparison,
  },
  {
    path: '/reporting/dashboards/automation-performance',
    exact: true,
    component: AutomationPerformanceDashboard,
  },
  {
    path: '/reporting/dashboards/one-off-emails',
    exact: true,
    component: DashboardsConversations,
  },
  {
    path: '/reporting/dashboards/conversionsiframe',
    exact: true,
    component: ConversionsIframeDashboard,
  },
  {
    path: '/reporting/dashboards/form-submissions',
    component: FormSubmissionsDashboard,
  },
  {
    path: '/reporting/dashboards/new-downloads',
    component: DashboardDownloads,
  },
  {
    path: '/reporting/dashboards/downloads',
    component: OldDashboardDownloads,
  },
  {
    path: '/marketing-center/email-templates',
    exact: true,
    component: EmailTemplates,
  },
  {
    path: '/marketing-center/emails/createEdit',
    exact: true,
    component: EmailsCreateEdit,
  },
  {
    path: '/marketing-center/emails/send',
    exact: true,
    component: SendConversationEmail,
  },
  {
    path: '/marketing-center/emails/dashboard',
    component: EmailDashboard,
  },
  {
    path: '/marketing-center/emails',
    component: MyEmails,
  },
  {
    path: '/marketing-center/websitePersonalization/edit',
    exact: true,
    component: WebsitePersonalizationBuilder,
  },
  {
    path: '/marketing-center/next-gen-emails/edit',
    exact: true,
    component: EmailBuilder,
  },
  {
    path: '/marketing-center/next-gen-emails',
    component: MyNextGenEmails,
  },
  {
    path: '/marketing-center/forms/edit',
    exact: true,
    component: FormBuilder,
  },
  {
    path: '/marketing-center/forms',
    component: MyForms,
  },
  {
    path: '/marketing-center/form-submissions',
    component: FormSubmissions,
  },
  {
    path: '/marketing-center/classic-forms/createEdit',
    exact: true,
    component: FormsCreateEdit,
  },
  {
    path: '/marketing-center/classic-forms',
    component: ClassicForms,
  },
  {
    path: '/reporting/reports/alerts',
    component: MyAlerts,
  },
  {
    path: '/marketing-center/mapped-forms',
    exact: true,
    component: MyMappedForms,
  },
  {
    path: '/marketing-center/mapped-forms/createEdit',
    component: CreateEditMappedForm,
  },
  {
    path: '/marketing-center/mapped-forms/conversions',
    exact: true,
    component: MappedFormConversions,
  },
  {
    path: '/reporting/reports/scheduled-reports',
    exact: true,
    component: MyReports,
  },
  {
    path: '/reporting/reports/createEdit',
    exact: true,
    component: CreateEditReport,
  },
  {
    path: '/reporting/reports/download-center',
    exact: true,
    component: DownloadCenter,
  },
  {
    path: '/reporting/dashboards/contacts',
    component: ContactsDashboard,
  },
  {
    path: '/reporting/dashboards/email-clients',
    exact: true,
    component: EmailClientsDashboard,
  },
  {
    path: '/reporting/dashboards/bounce-reasons',
    exact: true,
    component: BounceReasonsDashboard,
  },
  {
    path: '/reporting/dashboards/marketing-contribution',
    exact: true,
    component: MarketingContributionDashboard,
  },
  {
    path: '/reporting/dashboards/email-engagement',
    exact: true,
    component: EmailEngagementDashboard,
  },
  {
    path: '/reporting/dashboards/traffic-sources',
    exact: true,
    component: TrafficSourcesDashboard,
  },
  {
    path: '/home',
    exact: true,
    component: Home,
  },
  {
    path: '/examples',
    exact: true,
    component: Examples,
  },
  {
    path: '/examples/flow',
    exact: true,
    component: ReactFlowExamples,
  },
  {
    path: '/examples/flow-new',
    exact: true,
    component: Flow,
  },
  {
    path: '/examples/charts',
    exact: true,
    component: ChartExamples,
  },
  {
    path: '/examples/dndkitgrid',
    exact: true,
    component: DndKitGrid,
  },
  {
    path: '/examples/joyride',
    exact: true,
    component: JoyRideExample,
  },
  {
    path: '/examples/dashlets',
    exact: true,
    component: DashletExamples,
  },
  {
    path: '/examples/hookform',
    exact: true,
    component: ReactHookForm,
  },
  {
    path: '/examples/lexical',
    exact: true,
    component: LexicalExample,
  },
  {
    path: '/examples/swap',
    exact: true,
    component: AccountSwap,
  },
  {
    path: '/examples/datagrid',
    exact: true,
    component: DataGridExample,
  },
  // {
  //   path: '/reporting/dashboards/my-leads',
  //   exact: true,
  //   component: MyLeads,
  // },
  {
    path: '/marketing-center/segments',
    exact: true,
    component: Segments,
  },
  {
    path: '/marketing-center/segments/createEdit',
    exact: true,
    component: CreateEditGlobalSegment,
  },
  {
    path: '/marketing-center/assets',
    exact: true,
    component: ElFinderFileManager,
  },
  {
    path: '/marketing-center/file-manager',
    component: FileManager,
  },
  {
    path: '/marketing-center/sms',
    component: MySms,
  },
  {
    path: '/marketing-center/social/accounts',
    component: SocialAccounts,
    exact: true,
  },
  {
    path: [
      '/marketing-center/social/posts/create',
      '/marketing-center/social/post/edit',
      '/marketing-center/social/post/clone',
    ],
    component: SocialPosts,
  },
  {
    path: '/marketing-center/social/calendar',
    component: SocialPostsCalendarView,
    exact: true,
  },
  {
    path: '/marketing-center/social/list-view',
    component: SocialPostsListView,
    exact: true,
  },
  {
    path: '/reporting/dashboards/website-visitors',
    component: WebsiteVisitorsDashboard,
  },
  {
    path: '/reporting/dashboards/pages',
    exact: true,
    component: PopularWebPages,
  },
  {
    path: '/reporting/dashboards/traffic-sources',
    exact: true,
    component: TrafficSources,
  },
  {
    path: '/settings/account/oauth-apps/createEdit',
    component: OauthCreateEdit,
  },
  {
    path: '/settings/',
    component: Settings,
  },
  {
    path: '/wizard/landing-page-domain',
    exact: true,
    component: LandingPageDomainWizard,
  },
  {
    path: '/billingStatement/',
    component: BillingStatement,
  },
  {
    path: '/setup/account/integrations/dynamics',
    component: DynamicsSettings,
  },
  {
    path: '/setup/account/integrations/salesforce',
    component: SalesforceSettings,
  },
  {
    path: '/setup/account/integrations/ifs',
    component: IfsSettings,
  },
  {
    path: '/setup/account/integrations/sugarcrm',
    component: SugarCrmSettings,
  },
  {
    path: '/notifications',
    component: Notifications,
  },
  {
    path: '/plans',
    component: PricePlans,
  },
  {
    path: '/backdoor',
    exact: true,
    component: Backdoor,
  },
  {
    path: '/sso',
    exact: true,
    component: SingleSignOn,
  },
  {
    path: '/roadmap',
    exact: true,
    component: ProductRoadmap,
  },
  {
    path: '/data-enrichment/integrations',
    component: DataEnrichmentIntegrations,
  },
  {
    path: '/data-enrichment/revisions',
    component: DataEnrichmentRevisions,
  },

  {
    path: '/data-enrichment/history',
    component: DataEnrichmentHistory,
  },
  {
    path: '/leads',
    exact: true,
    component: MyLeads,
  },
  {
    path: '/opportunities',
    exact: true,
    component: MyOpportunities,
  },
  {
    path: '/person-accounts',
    exact: true,
    component: MyPersonAccounts,
  },
  {
    path: '/person-account',
    component: PersonAccount,
  },
  {
    path: '/:NotFound',
    component: NoMatch,
  },
];

const Routes = () => {
  const { updateSW, oldCorePath, setOldCorePath } = useServiceWorkerContext();
  const location = useLocation();
  const { data, error } = useQuery(CurrentVersionQuery, {
    pollInterval: import.meta.env.VITE_APP_ENVIRONMENT === 'production' ? 30000 : 0,
  });
  const corePath = location.pathname.split('/')[1];
  let updating = false;

  if (error) {
    Sentry.captureException(error);
  }

  try {
    // Only check/perform updates on major route changes.
    if (oldCorePath !== corePath) {
      // if (updateAvailable) {
      //   // If an update is currently ready, update.
      //   // This will render the screen as <div />, perform the update, then render the new page.
      //   updating = true;
      //   updateSW(`${location.pathname}${location.search}`);
      // } else {
      //   // If no update is ready, check for an update.
      //   navigator.serviceWorker.getRegistrations().then((regs) => regs.forEach((reg) => reg.update()));
      // }
      if (data && data.currentVersion?.version) {
        const localVersion = localStorage.getItem('currentVersion');
        if (localVersion !== data.currentVersion?.version) {
          localStorage.setItem('currentVersion', data.currentVersion?.version);
          updating = true;
          updateSW(`${location.pathname}${location.search}`);
        }
      }
    }
  } catch (updateError) {
    // eslint-disable-next-line no-console
    Sentry.captureException(updateError);
  }

  useEffect(() => {
    if (!updating) {
      // Only set the path when we aren't updating.
      // This will prevent state changing, updating becoming false, and then the non updated route rendering for a split second.
      // Instead, <div /> will be rendered until the update has completed.
      setOldCorePath(corePath);
    }
  }, [corePath, setOldCorePath, updating]);

  if (updating) {
    // Only return div when we are currently updating the app.
    // This prevents the component from rendering for a split second only to rerender later.
    return <div />;
  }

  return (
    <Suspense fallback={<div />}>
      <Switch location={location}>
        {routes.map((route) => (
          <MakeRouteWithSubRoutes key={route.path} {...route} />
        ))}
      </Switch>
    </Suspense>
  );
};

export default Routes;
