import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

// this doesn't spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const NewInput = memo(
  ({
    autoComplete,
    autoFocus,
    color,
    defaultValue,
    disabled,
    endAdornment,
    forceError,
    formatAsCurrency,
    fullWidth,
    helperText,
    hidden,
    id,
    label,
    maskField,
    max,
    min,
    multiline,
    name,
    onChange,
    placeholder,
    required,
    rows,
    startAdornment,
    sx,
    type,
    variant,
  }) => {
    const inputProps = () => {
      const shouldMask = maskField ? { 'data-sentry-mask': true } : {};
      const sxProps = {
        'aria-labelledby': name,
        min,
        max,
        ...shouldMask,
      };

      if (sx.color) {
        // If a color was passed in sx, we want to assign the text color that color.
        return {
          style: { color: sx.color },
          ...sxProps,
        };
      }
      return sxProps;
    };

    return (
      <Controller
        name={name}
        render={({ field: { ref, ...field }, fieldState: { invalid, error }, formState: { isSubmitting } }) => {
          return (
            <TextField
              variant={variant}
              size="small"
              type={type}
              error={forceError || invalid}
              helperText={error ? error.message : helperText}
              hidden={hidden}
              fullWidth={fullWidth}
              label={label}
              // Only display placeholder if different from label.
              placeholder={placeholder !== label ? placeholder : ''}
              id={id}
              defaultValue={defaultValue}
              required={required}
              autoFocus={autoFocus}
              inputProps={inputProps()}
              /* Disabling no-duplicate-props as our linter is apparently case-insensitive and sees inputProps and InputProps as the same. They are not: https://material-ui.com/api/text-field/ */
              /* eslint-disable-next-line react/jsx-no-duplicate-props */
              InputProps={{ autoComplete, endAdornment, startAdornment }}
              // If we've passed a placeholder that's different from label, always display the label at the top of the field.
              InputLabelProps={placeholder && placeholder !== label ? { id: name, shrink: true } : { id: name }}
              multiline={multiline}
              rows={rows}
              sx={sx}
              // spread the field object from react hook form, this comes last so nothing overrides it
              {...field}
              disabled={disabled || isSubmitting}
              onChange={(event) => {
                field.onChange(type === 'number' ? Number(event.target.value) : event.target.value);
                onChange(type === 'number' ? Number(event.target.value) : event.target.value, field.value);
              }}
              onBlur={() => {
                if (formatAsCurrency) {
                  const value = parseFloat(field.value).toFixed(2);
                  field.onChange(value);
                  onChange(value);
                }
              }}
              inputRef={ref}
              focused={color || undefined}
              color={color}
            />
          );
        }}
      />
    );
  }
);

NewInput.propTypes = {
  autoComplete: PropTypes.oneOf(['on', 'off']),
  autoFocus: PropTypes.bool,
  color: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  forceError: PropTypes.bool,
  formatAsCurrency: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  hidden: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  maskField: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  multiline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  startAdornment: PropTypes.node,
  sx: PropTypes.object,
  type: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']).isRequired,
};

NewInput.defaultProps = {
  autoComplete: 'off',
  autoFocus: false,
  color: null,
  defaultValue: '',
  disabled: false,
  endAdornment: null,
  forceError: false,
  formatAsCurrency: false,
  fullWidth: true,
  helperText: ' ',
  hidden: false,
  id: null,
  maskField: false,
  max: null,
  min: null,
  multiline: false,
  placeholder: '',
  required: false,
  rows: null,
  startAdornment: null,
  sx: {},
};

export default NewInput;
