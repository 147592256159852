import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HookFormTextField } from '@/components/HookFormFields';
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Fade,
  Link as MuiLink,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import { useCurrentUser } from '@/context/UserContext';
import { getLocalStorageWithExpiry } from '@/utilities/localstorage';
import NrButton from '@/components/NrMaterialOverrides/NrButton';

const MfaConfiguration = ({ productLevelForcedConfigure, userJwt, onSetupComplete }) => {
  const [showQrCode, setShowQrCode] = useState(false);
  const [showRecoveryCodes, setShowRecoveryCodes] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [qrCode, setQrCode] = useState(null);
  const [mfaEnablingLoading, setMfaEnablingLoading] = useState(false);
  const [mfaDisablingLoading, setMfaDisablingLoading] = useState(false);
  const [generatingRecoveryCodesLoading, setGeneratingRecoveryCodesLoading] = useState(false);
  const { currentUser, handleUserUpdate } = useCurrentUser();
  const [mfaEnabled, setMfaEnabled] = useState(productLevelForcedConfigure ? false : currentUser.mfa_enabled);
  const [mfaConfiguring, setMfaConfiguring] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const jwt = userJwt || getLocalStorageWithExpiry(import.meta.env.VITE_APP_LOCALSTORAGE_AUTH_KEY);
  const allowDisable = currentUser?.product?.mfa_enabled_at === null;

  const displaySnackbar = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const twoFactorFormValidationSchema = Yup.object().shape({
    mfaCode: Yup.string().required('Please enter your secure 2FA code').typeError('Please enter your secure 2FA code'),
  });

  const twoFactorFormMethods = useForm({
    mode: 'onTouched',
    defaultValues: {
      mfaCode: '',
    },
    resolver: yupResolver(twoFactorFormValidationSchema),
  });

  const {
    formState: { isSubmitting: twoFactorFormIsSubmitting },
    setError: twoFactorFormSetError,
  } = twoFactorFormMethods;

  const enableMfa = async () => {
    try {
      setMfaEnablingLoading(true);
      const enableUrl = `${import.meta.env.VITE_APP_LARAVEL_ENDPOINT}/user/two-factor-authentication`;
      const enableResponse = await fetch(enableUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      });
      const enableStatusCode = enableResponse.status;
      if (enableStatusCode !== 200) {
        throw new Error('Invalid Status Code From 2FA Enable');
      }

      const qrCodeUrl = `${import.meta.env.VITE_APP_LARAVEL_ENDPOINT}/user/two-factor-qr-code`;
      const qrCodeResponse = await fetch(qrCodeUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      });
      const responseJson = await qrCodeResponse.json();
      setQrCode(responseJson.svg);
      setMfaConfiguring(true);
      setShowQrCode(true);
      setMfaEnablingLoading(false);
    } catch (error) {
      setShowQrCode(false);
      setMfaEnablingLoading(false);
      displaySnackbar('There was a problem enabling 2FA. Please try again.', 'error');
      Sentry.captureException(error);
    }
  };

  const disableMfa = async () => {
    try {
      setMfaDisablingLoading(true);
      const disableUrl = `${import.meta.env.VITE_APP_LARAVEL_ENDPOINT}/user/two-factor-authentication`;
      const disableResponse = await fetch(disableUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      });
      const disableStatusCode = disableResponse.status;
      if (disableStatusCode !== 200) {
        throw new Error('Invalid Status Code From 2FA Disable');
      }
      setMfaDisablingLoading(false);
      handleUserUpdate('mfa_enabled', false);
      setMfaEnabled(false);
      displaySnackbar('2FA disabled.', 'success');
    } catch (error) {
      setMfaDisablingLoading(false);
      displaySnackbar('There was a problem disabling 2FA. Please try again.', 'error');
      Sentry.captureException(error);
    }
  };

  const getRecoverCodes = async () => {
    try {
      const url = `${import.meta.env.VITE_APP_LARAVEL_ENDPOINT}/user/two-factor-recovery-codes`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      });
      const responseJson = await response.json();
      setRecoveryCodes(responseJson);
      setShowRecoveryCodes(true);
    } catch (error) {
      displaySnackbar('There was a problem completing your request', 'error');
      Sentry.captureException(error);
    }
  };

  const confirmMfa = async (data) => {
    try {
      const url = `${import.meta.env.VITE_APP_LARAVEL_ENDPOINT}/user/confirmed-two-factor-authentication`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ code: data.mfaCode }),
      });

      const statusCode = response.status;
      if (statusCode === 200 || statusCode === 302) {
        if (!productLevelForcedConfigure) {
          handleUserUpdate('mfa_enabled', true);
        }
        await getRecoverCodes();
        setMfaEnabled(true);
        displaySnackbar('2FA enabled successfully.', 'success');
      } else if (statusCode >= 400 && statusCode < 500) {
        twoFactorFormSetError('mfaCode', { message: 'There was a problem validating this code. Please Try again.' });
      } else {
        displaySnackbar('There was a problem completing your request', 'error');
        Sentry.captureException(response);
      }
    } catch (error) {
      displaySnackbar('There was a problem completing your request', 'error');
      Sentry.captureException(error);
    }
  };

  const regnerateRecoveryCodes = async () => {
    try {
      setGeneratingRecoveryCodesLoading(true);
      const url = `${import.meta.env.VITE_APP_LARAVEL_ENDPOINT}/user/two-factor-recovery-codes`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      });
      const statusCode = response.status;
      if (statusCode !== 200) {
        throw new Error('Invalid Status Code From 2FA Enable');
      }
      await getRecoverCodes();
      setGeneratingRecoveryCodesLoading(false);
    } catch (error) {
      displaySnackbar('There was a problem completing your request', 'error');
      setGeneratingRecoveryCodesLoading(false);
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ flexGrow: 1 }}>
        Two Factor Authentication
      </Typography>

      {/* SPLASH SCREEN */}
      {!mfaConfiguring && !mfaEnabled ? (
        <Stack direction="column" spacing={2} mt={2}>
          <Typography>
            Two Factor Authentication (2FA) is an extra layer of security designed to make sure that you&apos;re the
            only one who can access your account — even if someone else knows your password.
          </Typography>
          <Typography>
            With Two Factor Authentication in place, you&apos;ll be asked to enter a code generated by a secure app
            whenever you log in.
          </Typography>
          <Box pt={1}>
            <NrButton isLoading={mfaEnablingLoading} fullWidth onClick={enableMfa}>
              Setup Two Factor Auth
            </NrButton>
          </Box>
        </Stack>
      ) : null}

      {mfaEnabled && !showRecoveryCodes ? (
        <Stack spacing={2}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            py={1}
            px={2}
            my={2}
            sx={{ border: '1px solid', borderColor: (theme) => theme.palette.success.light, borderRadius: 4 }}
          >
            <Avatar sx={{ backgroundColor: (theme) => theme.palette.success.main }}>
              <Check />
            </Avatar>
            <Typography variant="h6">Two Factor Authentication is Enabled!</Typography>
          </Stack>
          <NrButton
            isLoading={generatingRecoveryCodesLoading}
            fullWidth
            type="secondary"
            onClick={regnerateRecoveryCodes}
          >
            Regenerate Recovery Codes
          </NrButton>
          {allowDisable ? (
            <NrButton
              isLoading={mfaDisablingLoading}
              fullWidth
              sx={{ backgroundColor: (theme) => theme.palette.error.dark }}
              onClick={disableMfa}
            >
              Disable Two Factor Authentication
            </NrButton>
          ) : null}
        </Stack>
      ) : null}

      {/* INITIAL SETUP */}
      {showQrCode && !showRecoveryCodes ? (
        <Fade in appear timeout={950}>
          <Stack spacing={3}>
            <Stack direction="column" spacing={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar sx={{ backgroundColor: (theme) => theme.palette.brand.techBlue }}>
                  <strong>1</strong>
                </Avatar>
                <Typography variant="h5">Install &amp; Setup Authenticator App</Typography>
              </Stack>
              <Typography>
                Use a phone app like{' '}
                <MuiLink
                  href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>Google Authenticator</strong>
                </MuiLink>
                ,{' '}
                <MuiLink href="https://authy.com/download/" target="_blank" rel="noreferrer">
                  <strong>Authy</strong>
                </MuiLink>
                ,{' '}
                <MuiLink
                  href="https://apps.apple.com/us/app/lastpass-authenticator/id1079110004"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>LastPass Authenticator</strong>
                </MuiLink>
                , or{' '}
                <MuiLink href="https://support.1password.com/get-the-apps/" target="_blank" rel="noreferrer">
                  <strong>1Password</strong>
                </MuiLink>{' '}
                to get 2FA codes when prompted during sign-in.
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar sx={{ backgroundColor: (theme) => theme.palette.brand.techBlue }}>
                  <strong>2</strong>
                </Avatar>
                <Typography variant="h5">Scan the QR code</Typography>
              </Stack>
              <Typography>Use the authenticator app on your phone to scan this QR code:</Typography>
              <Box dangerouslySetInnerHTML={{ __html: qrCode }} />
            </Stack>

            <Stack direction="column" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar sx={{ backgroundColor: (theme) => theme.palette.brand.techBlue }}>
                  <strong>3</strong>
                </Avatar>
                <Typography variant="h5">Confirm Your Setup</Typography>
              </Stack>

              <Typography>
                Your authenticator app will generate a new secure code every few seconds. Please enter that code below
                to verify your 2FA setup.
              </Typography>

              <Typography>
                Use this app to obtain a secure code whenever you login to Goldilocks in the future.
              </Typography>
              <FormProvider {...twoFactorFormMethods}>
                <form onSubmit={twoFactorFormMethods.handleSubmit(confirmMfa)}>
                  <Stack spacing={2} alignItems="center">
                    <HookFormTextField
                      name="mfaCode"
                      label="Secure 2FA Code from the App"
                      type="text"
                      fullWidth
                      autoFocus
                      required
                    />
                    <NrButton
                      dataTestId="twoFactorButton"
                      disabled={twoFactorFormIsSubmitting}
                      buttonType="submit"
                      isLoading={twoFactorFormIsSubmitting}
                    >
                      Confirm Two Factor Auth Setup
                    </NrButton>
                  </Stack>
                </form>
              </FormProvider>
            </Stack>
          </Stack>
        </Fade>
      ) : null}
      {showRecoveryCodes ? (
        <Stack spacing={2} mt={2}>
          <Alert severity="warning">
            <AlertTitle>
              <Typography variant="h5">Recovery Codes</Typography>
            </AlertTitle>
            <Typography>
              Recovery codes can be used to access your account in the event you lose access to your device and cannot
              receive two-factor authentication codes. Save these in a safe location.
            </Typography>
            <List dense>
              {recoveryCodes.map((recoveryCode) => {
                return (
                  <ListItem key={`recoveryCode-${recoveryCode}`}>
                    <ListItemText primary={recoveryCode} />
                  </ListItem>
                );
              })}
            </List>
          </Alert>
          {productLevelForcedConfigure ? (
            <NrButton fullWidth type="primary" onClick={onSetupComplete}>
              Login
            </NrButton>
          ) : null}
        </Stack>
      ) : null}
    </>
  );
};

MfaConfiguration.propTypes = {
  productLevelForcedConfigure: PropTypes.bool,
  userJwt: PropTypes.string,
  onSetupComplete: PropTypes.func,
};

MfaConfiguration.defaultProps = {
  productLevelForcedConfigure: false,
  userJwt: null,
  onSetupComplete: () => {},
};

export default MfaConfiguration;
