import React from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Badge } from '@mui/material';
import { Notifications } from '@mui/icons-material';
import { UnreadNotificationCenterCountQuery } from 'goldilocks/graphql/notifications/queries.graphql';

const NotificationBadge = () => {
  const { data, loading, error } = useQuery(UnreadNotificationCenterCountQuery, {
    pollInterval: import.meta.env.VITE_APP_ENVIRONMENT === 'production' ? 30000 : 0,
  });

  if (loading || error) return <Notifications />;

  return (
    <Link to="/notifications">
      <Badge badgeContent={data.notifications.paginatorInfo.total} color="secondary" max={99}>
        <Notifications />
      </Badge>
    </Link>
  );
};

export default NotificationBadge;
