import React, { createContext, useContext, useLayoutEffect, useState } from 'react';
import { useAsync } from 'react-async';
import { bootstrapAppData } from '@/utilities/bootstrapApp';
import DataLoading from '@/components/DataLoading';

const AuthContext = createContext();

const AuthProvider = (props) => {
  const [firstAttemptFinished, setFirstAttemptFinished] = useState(false);
  const {
    data = null,
    error,
    isRejected,
    isPending,
    isSettled,
    reload,
  } = useAsync({
    promiseFn: bootstrapAppData,
  });

  // Normally your provider components render the context provider with a value.
  // But we post-pone rendering any of the children until after we've determined
  // whether or not we have a non-expired JWT in local storage. Render a spinner
  // while we go check local storage.
  useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  // currently the bootstrap is just pulling from the cookie so it doesnt really load
  // but in the future it could easily be hitting apis for additional data so show a loader
  if (!firstAttemptFinished) {
    if (isPending) {
      return <DataLoading />;
    }
    // TODO: have a better error component
    if (isRejected) {
      return (
        <div>
          <p>Uh oh... There is a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      );
    }
  }

  // note, I'm not bothering to optimize this `value` with React.useMemo here
  // because this is the top-most component rendered in our app and it will very
  // rarely re-render/cause a performance problem.
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <AuthContext.Provider value={{ data, reload }} {...props} />;
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
