import React from 'react';
import { Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { GenericErrorFallback } from '@/components/ErrorFallback';

const MakeRouteWithSubRoutes = (route) => {
  const errorFallback = <GenericErrorFallback />;
  let redirectComponent;
  if (route.redirect) {
    redirectComponent = route.redirect;
  }
  return (
    <Sentry.ErrorBoundary fallback={errorFallback}>
      <Route path={route.path} render={(props) => <route.component {...props} routes={route.routes} />}>
        {redirectComponent}
      </Route>
    </Sentry.ErrorBoundary>
  );
};

export default MakeRouteWithSubRoutes;
