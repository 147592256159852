import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormControl, FormControlLabel, FormHelperText, Stack, Switch } from '@mui/material';

// This doesn't spread a ...rest prop on purpose.
// If you are missing a mui prop, add it.
// If you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormSwitch = ({ helperText, infoPopover, label, name, onChange, required, sx, values }) => {
  return (
    <Controller
      render={({ field, formState: { isSubmitting }, fieldState: { error } }) => {
        return (
          <FormControl component="fieldset">
            <Stack direction="row" alignItems="center">
              <FormControlLabel
                sx={{ mr: 1 }}
                control={
                  <Switch
                    inputProps={{ 'aria-label': label }}
                    sx={sx}
                    checked={JSON.stringify(field.value) === JSON.stringify(values.true)}
                    {...field}
                    disabled={isSubmitting}
                    // onChange must come below ...field cause it overrides field.onChange
                    onChange={(e) => {
                      onChange(values[e.target.checked]);
                      field.onChange(values[e.target.checked]);
                    }}
                  />
                }
                label={label}
                required={required}
              />
              {infoPopover}
            </Stack>
            {error || helperText ? (
              <FormHelperText error={!!error}>{error ? error.message : helperText}</FormHelperText>
            ) : null}
          </FormControl>
        );
      }}
      name={name}
    />
  );
};

HookFormSwitch.propTypes = {
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.string.isRequired,
  infoPopover: PropTypes.node,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  sx: PropTypes.object,
  values: PropTypes.any,
};

HookFormSwitch.defaultProps = {
  helperText: null,
  infoPopover: null,
  onChange: () => {},
  required: false,
  sx: {},
  values: { true: true, false: false },
};

export default HookFormSwitch;
