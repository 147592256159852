import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const DrawerRightFooter = ({ children }) => {
  const [width, setWidth] = useState(400);
  const ref = useRef(null);

  /* Trickery to get this `fixed` position `Box` to maintain the same width as its parent.

  Our DrawerRight "wants to have" a header, body, and footer (that contains our save/cancel buttons) like this...

  <Box sx={{ minWidth: '400px', maxWidth: '500px' }}>
    <DrawerRightHeader heading={`${operationType} Alert`} onClose={props.handleBackdropClick} />
    <DrawerRightContent>{renderChild()}</DrawerRightContent>
    <DrawerRightFooter>{...save and cancel buttons here}</DrawerRightFooter>
  </Box>
  ...but we can't do it that way because our save/cancel buttons need all the goodies from HookForm,
  so we cannot have a DrawerRightFooter up at this level.

  So we have to put the footer down in the Form. When we're down in the form, we are nested in the wrong
  parent altogether (we're nested inside <DrawerRightContent>), so we have to get tricky with CSS.

  CSS `sticky` is totally dependent on its parent. Sticky is the "right" way to do this, but we cannot use it
  because of how we must nest in the "wrong" location.

  So we use CSS `fixed` which goes full viewport width of you say "width: 100%" which is a no-go. If you don't
  go 100% width it's too narrow - being "fixed" it cannot "see" parent containers.
  So we end up with the trickery in this file to get the width right.
   */

  useEffect(() => {
    setWidth(ref.current.parentElement.parentElement.offsetWidth);
  }, [ref?.current?.parentElement?.parentElement?.offsetWidth]);

  return (
    <Box
      ref={ref}
      p={2}
      sx={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        width,
        zIndex: (theme) => theme.zIndex.drawer - 1,
        backgroundColor: (theme) => theme.palette.background.default,
        borderTop: '1px solid',
        borderTopColor: (theme) => theme.palette.borderColor,
      }}
    >
      {children}
    </Box>
  );
};

DrawerRightFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DrawerRightFooter;
