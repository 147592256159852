import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext, getIn } from 'formik';
import { CircularProgress, TextField } from '@mui/material';
import { Autocomplete } from 'formik-mui';

const FormikAutocomplete = ({
  name,
  options,
  label,
  required,
  multiple,
  autoFocus,
  helperText,
  loading,
  requiresSearchInput,
  ...rest
}) => {
  const { touched, errors } = useFormikContext();
  // https://formik.org/docs/api/fieldarray#fieldarray-validation-gotchas
  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  const noOptionsText = requiresSearchInput ? 'Search to find matching accounts' : 'No options were found';

  let finalHelperText = helperText;

  if (touch && error) {
    finalHelperText = error;
  }

  return (
    <Field
      id={name}
      name={name}
      autoComplete
      size="small"
      component={Autocomplete}
      multiple={multiple}
      options={options}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      groupBy={(option) => option.group || null}
      noOptionsText={loading ? 'Loading...' : noOptionsText}
      renderInput={(params) => (
        <TextField
          {...params}
          error={touch && !!error}
          helperText={finalHelperText}
          label={label}
          variant="outlined"
          required={required}
          autoFocus={autoFocus}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} sx={{ mr: 3 }} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...rest}
    />
  );
};

FormikAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  disableClearable: PropTypes.bool,
  autoFocus: PropTypes.bool,
  helperText: PropTypes.string,
  loading: PropTypes.bool,
  requiresSearchInput: PropTypes.bool,
};

FormikAutocomplete.defaultProps = {
  required: false,
  multiple: false,
  disableClearable: false,
  autoFocus: false,
  helperText: null,
  loading: false,
  requiresSearchInput: false,
};

export default FormikAutocomplete;
