/**
 * arrayIntersection
 * Filters 2 arrays of objects returning the filtered result set.
 * This is a utility function used by 3 functions in this file: presentInBoth(), presentInFirstArrayOnly(), and presentInSecondArrayOnly().
 *
 * @param arr1
 * @param arr2
 * @param property
 * @param isUnion
 */
const arrayIntersection = (arr1, arr2, property, isUnion = false) =>
  arr1.filter((a) => isUnion === arr2.some((b) => a[property] === b[property]));

const index = {
  getCustomFieldType: (customFieldEnum) => {
    let customFieldType = '';
    switch (customFieldEnum) {
      case 'TEXT':
        customFieldType = 'Text';
        break;
      case 'NUMBER':
        customFieldType = 'Number';
        break;
      case 'DATE':
        customFieldType = 'Date';
        break;
      case 'DATETIME':
        customFieldType = 'Date Time';
        break;
      case 'WEBSITE':
        customFieldType = 'Website';
        break;
      case 'PHONE':
        customFieldType = 'Phone';
        break;
      case 'EMAIL':
        customFieldType = 'Email';
        break;
      case 'BOOLEAN':
        customFieldType = 'Boolean';
        break;
      case 'SELECT':
        customFieldType = 'Drop Down';
        break;
      case 'LOOKUP':
        customFieldType = 'Lookup';
        break;
      case 'RELATIONSHIP':
        customFieldType = 'Relationship';
        break;
      case 'MULTISELECT':
        customFieldType = 'Drop Down Multi-Select';
        break;
      case 'DECIMAL':
        customFieldType = 'Decimal';
        break;
      case 'TEXTAREA':
        customFieldType = 'Text Area';
        break;
      default:
        break;
    }

    return customFieldType;
  },

  // Filters 2 arrays of objects and returns all objects where ("property" = property) in both arr1 and arr2.
  presentInBoth: (arr1, arr2, property) => arrayIntersection(arr1, arr2, property, true),

  // Filters 2 arrays of objects and returns all objects where ("property" = property) that are present in arr1 and not present in arr2.
  presentInFirstArrayOnly: (arr1, arr2, property) => arrayIntersection(arr1, arr2, property, false),

  // Filters 2 arrays of objects and returns all objects where ("property" = property) that are present in arr2 and not present in arr1.
  presentInSecondArrayOnly: (arr1, arr2, property) => arrayIntersection(arr2, arr1, property, false),

  // This function copied from MUI docs https://mui.com/material-ui/react-avatar/#letter-avatars
  stringToColor: (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  },

  customFieldTypes: [
    { value: 'BOOLEAN', label: 'Boolean' },
    { value: 'DATE', label: 'Date' },
    { value: 'DATETIME', label: 'Date and Time' },
    { value: 'DECIMAL', label: 'Decimal' },
    { value: 'SELECT', label: 'Drop Down' },
    { value: 'MULTISELECT', label: 'Drop Down Multi-Select' },
    { value: 'LOOKUP', label: 'Lookup' },
    { value: 'NUMBER', label: 'Number' },
    { value: 'TEXT', label: 'Text' },
    { value: 'TEXTAREA', label: 'Text Area' },
    { value: 'WEBSITE', label: 'Website' },
  ],
};

export default index;
