import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { useLocation } from 'react-router';
import { lazyWithRetry as lazy } from '@/utilities/lazyWithRetry';
import MakeRouteWithSubRoutes from './makeRouteWithSubRoutes';

const AccountPanel = lazy(
  () => import(/* webpackChunkName: "AccountPanel", webpackPrefetch: true */ 'goldilocks/pages/CrmPanels/Account')
);

const ContactPanel = lazy(
  () => import(/* webpackChunkName: "ContactPanel", webpackPrefetch: true */ 'goldilocks/pages/CrmPanels/Contact')
);

const NoMatch = lazy(() => import(/* webpackChunkName: "NoMatch", webpackPrefetch: true */ './pages/NoMatch'));

const routes = [
  {
    path: '/crm/panel/account',
    exact: true,
    component: AccountPanel,
  },
  {
    path: '/crm/panel/contact',
    exact: true,
    component: ContactPanel,
  },
  {
    path: '/:NotFound',
    component: NoMatch,
  },
];

const CrmPanelRoutes = () => {
  const location = useLocation();

  return (
    <Suspense fallback={<div />}>
      <Switch location={location}>
        {routes.map((route) => (
          <MakeRouteWithSubRoutes key={route.path} {...route} />
        ))}
      </Switch>
    </Suspense>
  );
};

export default CrmPanelRoutes;
