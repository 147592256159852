import React from 'react';
import { useCurrentUser } from '@/context/UserContext';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { hilcoPids } from '@/utilities/CustomerFeatures/hilco';
import { newFormBuilderProductIds } from '@/utilities/CustomerFeatures/newFormBuilderProductIds';
import TopNavItem from '@/components/Navigation/TopNav/TopNavItem';
import PidLock from '@/components/PidLock';
import { iconMappings } from '../../constants';
import NrMenuItem from '../NrMenuItem';
import CascadingMenu from '../../CascadingMenu';
import CascadingSubmenu from '../../CascadingSubmenu';

const NavMarketingCenter = () => {
  const { currentUser } = useCurrentUser();
  const popupState = usePopupState({ variant: 'popover', popupId: 'navMarketingCenter' });

  return (
    <>
      <TopNavItem label="Marketing Center" popupState={popupState} />

      <CascadingMenu
        popupState={popupState}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {/* {[5, 3113].includes(Number(currentUser.product.id)) ? ( */}
        {/*  <CascadingSubmenu */}
        {/*    popupId="actions" */}
        {/*    title="Actions" */}
        {/*    icon={iconMappings.directionsRun} */}
        {/*    menuItemUrl="/marketing-center/actions" */}
        {/*    isLabFeature */}
        {/*  > */}
        {/*    <NrMenuItem */}
        {/*      menuItemText="Add to Marketing List" */}
        {/*      menuItemIcon={iconMappings.deviceHub} */}
        {/*      menuItemUrl="/marketing-center/actions?actionTypeId=2" */}
        {/*      createNewUrl="/marketing-center/actions/createEdit?actionTypeId=2" */}
        {/*    /> */}
        {/*    <NrMenuItem */}
        {/*      menuItemText="Add to Subscription List" */}
        {/*      menuItemIcon={iconMappings.deviceHub} */}
        {/*      menuItemUrl="/marketing-center/actions?actionTypeId=3" */}
        {/*      createNewUrl="/marketing-center/actions/createEdit?actionTypeId=3" */}
        {/*    /> */}
        {/*    <NrMenuItem */}
        {/*      menuItemText="Create CRM Tasks" */}
        {/*      menuItemIcon={iconMappings.deviceHub} */}
        {/*      menuItemUrl="/marketing-center/actions/create-tasks" */}
        {/*      createNewUrl="/marketing-center/actions/create-tasks/createEdit" */}
        {/*    /> */}
        {/*    <NrMenuItem */}
        {/*      menuItemText="Remove from Marketing List" */}
        {/*      menuItemIcon={iconMappings.deviceHub} */}
        {/*      menuItemUrl="/marketing-center/actions?actionTypeId=8" */}
        {/*      createNewUrl="/marketing-center/actions/createEdit?actionTypeId=8" */}
        {/*    /> */}
        {/*    <NrMenuItem */}
        {/*      menuItemText="Remove from Subscription List" */}
        {/*      menuItemIcon={iconMappings.deviceHub} */}
        {/*      menuItemUrl="/marketing-center/actions?actionTypeId=9" */}
        {/*      createNewUrl="/marketing-center/actions/createEdit?actionTypeId=9" */}
        {/*    /> */}
        {/*    <NrMenuItem */}
        {/*      menuItemText="Send Webhook" */}
        {/*      menuItemIcon={iconMappings.deviceHub} */}
        {/*      menuItemUrl="/marketing-center/actions?actionTypeId=13" */}
        {/*      createNewUrl="/marketing-center/actions/createEdit?actionTypeId=13" */}
        {/*    /> */}
        {/*    <NrMenuItem */}
        {/*      menuItemText="Send Zapier Zap" */}
        {/*      menuItemIcon={iconMappings.deviceHub} */}
        {/*      menuItemUrl="/marketing-center/actions?actionTypeId=22" */}
        {/*      createNewUrl="/marketing-center/actions/createEdit?actionTypeId=22" */}
        {/*    /> */}
        {/*    <NrMenuItem */}
        {/*      menuItemText="Set Field Values" */}
        {/*      menuItemIcon={iconMappings.deviceHub} */}
        {/*      menuItemUrl="/marketing-center/actions?actionTypeId=14" */}
        {/*      createNewUrl="/marketing-center/actions/createEdit?actionTypeId=14" */}
        {/*    /> */}
        {/*    <NrMenuItem */}
        {/*      menuItemText="Set Lead Stage" */}
        {/*      menuItemIcon={iconMappings.deviceHub} */}
        {/*      menuItemUrl="/marketing-center/actions?actionTypeId=15" */}
        {/*      createNewUrl="/marketing-center/actions/createEdit?actionTypeId=15" */}
        {/*    /> */}
        {/*    <NrMenuItem */}
        {/*      menuItemText="Set Lead Owner" */}
        {/*      menuItemIcon={iconMappings.deviceHub} */}
        {/*      menuItemUrl="/marketing-center/actions?actionTypeId=16" */}
        {/*      createNewUrl="/marketing-center/actions/createEdit?actionTypeId=16" */}
        {/*    /> */}
        {/*  </CascadingSubmenu> */}
        {/* ) : null} */}
        <PidLock>
          <NrMenuItem
            menuItemText="Actions"
            menuItemIcon={iconMappings.actions}
            menuItemUrl="/marketing-center/my-actions"
          />
        </PidLock>
        <CascadingSubmenu
          popupId="automations"
          title="Automations"
          icon={iconMappings.deviceHub}
          menuItemUrl="/marketing-center/automations"
        >
          <NrMenuItem
            menuItemText="Workflows"
            menuItemIcon={iconMappings.deviceHub}
            menuItemUrl="/marketing-center/automations?type=1"
            createNewUrl="/marketing-center/automations/createEdit?campaignConfiguration=1"
            featureSlug="Workflows"
          />
          <NrMenuItem
            menuItemText="Drip Series"
            menuItemIcon={iconMappings.deviceHub}
            menuItemUrl="/marketing-center/automations?type=2"
            createNewUrl="/marketing-center/drip-series/createEdit"
            featureSlug="DripSeries"
          />
          <NrMenuItem
            menuItemText="A/B Email Tests"
            menuItemIcon={iconMappings.deviceHub}
            menuItemUrl="/marketing-center/automations?type=3"
            createNewUrl="/marketing-center/automations/createEdit?campaignConfiguration=3"
            featureSlug="ABEmailTesting"
          />
          <NrMenuItem
            menuItemText="Lead Scoring Thresholds"
            menuItemIcon={iconMappings.deviceHub}
            menuItemUrl="/marketing-center/automations?type=9"
            createNewUrl="/marketing-center/automations/threshold/createEdit"
            featureSlug="LeadScoringThresholds"
          />
          <NrMenuItem
            menuItemText="Lead Stage Change Actions"
            menuItemIcon={iconMappings.deviceHub}
            menuItemUrl="/marketing-center/automations?type=10"
            createNewUrl="/marketing-center/automations/leadStageChangeAction/createEdit"
            featureSlug="LeadStageChangeActions"
          />
          <PidLock>
            <NrMenuItem
              menuItemText="Opportunity Stage Change Actions"
              menuItemIcon={iconMappings.deviceHub}
              menuItemUrl="/marketing-center/automations?type=11"
              createNewUrl="/marketing-center/automations/opportunityStageChangeAction/createEdit"
              featureSlug="OpportunityStageChangeActions"
            />
          </PidLock>
          <NrMenuItem
            menuItemText="Sunset Contacts"
            menuItemIcon={iconMappings.deviceHub}
            menuItemUrl="/marketing-center/automations?type=12"
            createNewUrl="/marketing-center/automations/sunsetContacts/create"
          />
          {/* product_id: 25307 = The MFO Group/fusioncell.com. https://intranet.net-results.com/content/channel/main_customers/18961 */}
          {currentUser.product.id === '25307' ? (
            <>
              <NrMenuItem
                menuItemText="Hot Job Alerts"
                menuItemIcon={iconMappings.deviceHub}
                menuItemUrl="/marketing-center/hot-job-alerts"
                createNewUrl="/marketing-center/hot-job-alerts/createEdit"
              />
              <NrMenuItem
                menuItemText="Recurring Job Summaries"
                menuItemIcon={iconMappings.deviceHub}
                menuItemUrl="/marketing-center/recurring-job-summaries"
                createNewUrl="/marketing-center/recurring-job-summaries/createEdit"
              />
            </>
          ) : null}
          {Object.keys(hilcoPids).includes(String(currentUser.product.id)) && currentUser.campaign_approver ? (
            <NrMenuItem
              menuItemText="Approvals"
              menuItemIcon={iconMappings.deviceHub}
              menuItemUrl="/marketing-center/automations/approvals"
            />
          ) : null}
        </CascadingSubmenu>

        <NrMenuItem
          menuItemText="Calendar"
          menuItemIcon={iconMappings.calendar}
          menuItemUrl="/marketing-center/calendar"
        />
        <CascadingSubmenu
          popupId="emails"
          title="Emails"
          icon={iconMappings.email}
          menuItemUrl="/marketing-center/emails"
        >
          <NrMenuItem
            menuItemText="All Emails"
            menuItemIcon={iconMappings.email}
            menuItemUrl="/marketing-center/emails"
            createNewUrl="/marketing-center/emails/createEdit"
          />
          <NrMenuItem
            menuItemText="Email Performance Dashboard"
            menuItemIcon={iconMappings.equalizer}
            menuItemUrl="/marketing-center/emails/dashboard"
          />
        </CascadingSubmenu>

        <PidLock>
          <NrMenuItem
            menuItemText="Next Gen Emails"
            menuItemIcon={iconMappings.email}
            menuItemUrl="/marketing-center/next-gen-emails"
          />
        </PidLock>

        <NrMenuItem
          menuItemText="File Manager"
          menuItemIcon={iconMappings.permMedia}
          menuItemUrl="/marketing-center/file-manager"
        />

        <CascadingSubmenu popupId="forms" title="Forms" icon={iconMappings.dns} menuItemUrl="/marketing-center/forms">
          <NrMenuItem
            menuItemText="All Forms"
            menuItemIcon={iconMappings.dns}
            menuItemUrl="/marketing-center/forms"
            createNewUrl={
              newFormBuilderProductIds.includes(Number(currentUser.product.id))
                ? '/marketing-center/forms/create'
                : '/marketing-center/classic-forms/createEdit'
            }
          />
          <NrMenuItem
            menuItemText="Form Submissions"
            menuItemIcon={iconMappings.assignment}
            menuItemUrl="/marketing-center/form-submissions"
          />
        </CascadingSubmenu>
        <NrMenuItem
          menuItemText="Global Segments"
          menuItemIcon={iconMappings.pieChart}
          menuItemUrl="/marketing-center/segments"
          createNewUrl="/marketing-center/segments/createEdit"
        />
        <NrMenuItem
          menuItemText="Landing Pages"
          menuItemIcon={iconMappings.web}
          menuItemUrl="/marketing-center/landing-pages"
          createNewUrl="/marketing-center/landing-pages/createEdit"
          featureSlug="LandingPageBuilder"
        />
        <NrMenuItem
          menuItemText="Lead Scoring"
          menuItemIcon={iconMappings.score}
          menuItemUrl="/marketing-center/lead-scoring"
          createNewUrl="/marketing-center/scoring-ruleset/settings"
          featureSlug="LeadScoring"
        />
        <NrMenuItem
          menuItemText="Mapped Forms"
          menuItemIcon={iconMappings.dns}
          menuItemUrl="/marketing-center/mapped-forms"
          createNewUrl="/marketing-center/mapped-forms/createEdit"
          featureSlug="MappedForms"
        />
        <PidLock>
          <NrMenuItem
            menuItemText="Marketing Campaigns"
            menuItemIcon={iconMappings.campaign}
            menuItemUrl="/marketing-center/marketing-campaigns"
            createNewUrl="/marketing-center/marketing-campaigns/createEdit"
          />
        </PidLock>

        <CascadingSubmenu
          popupId="marketingLists"
          title="Marketing Lists"
          icon={iconMappings.listIcon}
          menuItemUrl="/marketing-center/lists"
        >
          <NrMenuItem
            menuItemText="All Marketing Lists"
            menuItemIcon={iconMappings.listIcon}
            menuItemUrl="/marketing-center/lists"
            createNewUrl="/marketing-center/lists/createEdit"
          />
          <NrMenuItem
            menuItemText="Combine Marketing Lists"
            menuItemIcon={iconMappings.deviceHub}
            menuItemUrl="/marketing-center/lists/combineLists"
          />
          <NrMenuItem
            menuItemText="Marketing List Dashboard"
            menuItemIcon={iconMappings.equalizer}
            menuItemUrl="/marketing-list/dashboard"
          />
        </CascadingSubmenu>

        <NrMenuItem
          menuItemText="Social Posts"
          menuItemIcon={iconMappings.share}
          menuItemUrl="/marketing-center/social/posts/create"
          createNewUrl="/marketing-center/social/posts/create"
          featureSlug="SocialPostMultiplePlatforms"
        />
        <NrMenuItem
          menuItemText="Subscription Lists"
          menuItemIcon={iconMappings.playlistAddCheck}
          menuItemUrl="/marketing-center/subscriptions"
          createNewUrl="/marketing-center/subscriptions/createEdit"
          featureSlug="SubscriptionListManagement"
        />
        <NrMenuItem
          menuItemText="Text Messages"
          menuItemIcon={iconMappings.sms}
          menuItemUrl="/marketing-center/sms"
          createNewUrl="/marketing-center/sms/createEdit"
        />
        <NrMenuItem
          menuItemText="URL Builder & Shortener"
          menuItemIcon={iconMappings.language}
          menuItemUrl="/marketing-center/url-builder"
        />
        <NrMenuItem menuItemText="Webinars" menuItemIcon={iconMappings.cast} menuItemUrl="/marketing-center/webinars" />
      </CascadingMenu>
    </>
  );
};

export default NavMarketingCenter;
