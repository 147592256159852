import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
import '../moment-timezone-with-data-1970-2030';

const dateTime = {
  formatUtcDate: (strDateUTC, strFormat, strTimeZone) => {
    /**
     * https://momentjs.com/docs/#/displaying/
     * .format('L')      // 06/09/2014
     * .format('l')      // 6/9/2014
     * .format('LL')     // June 9 2014
     * .format('ll')     // Jun 9 2014
     * .format('LLL')    // June 9 2014 9:32 PM
     * .format('lll')    // Jun 9 2014 9:32 PM
     * .format('LLLL')   // Monday, June 9 2014 9:32 PM
     * .format('llll')   // Mon, Jun 9 2014 9:32 PM
     *
     * LT = 08:30 PM
     * LTS = 08:30:00 PM
     */
    return moment
      .utc(strDateUTC)
      .tz(strTimeZone || 'America/Denver')
      .format(strFormat || 'lll');
  },

  getMinutesFromSeconds: (intSeconds) => {
    if (intSeconds === 0) {
      return 'n/a';
    }
    momentDurationFormatSetup(moment);
    const strDuration = moment.duration(intSeconds, 'seconds').format('h:mm:ss');
    if (intSeconds < 10) {
      return `0:0${strDuration}`;
    }
    if (intSeconds < 60) {
      return `0:${strDuration}`;
    }
    return strDuration;
  },
  convertDateTimeToUTC: (dateTimeString) => {
    return moment(dateTimeString).utc().format('YYYY-MM-DD HH:mm:ss');
  },
  convertToUnixTimestamp: (strDate, strCurrentTimezone) => {
    // todo: We'll get clarity on what we need here when we implement the date picker. We can then get proper tests in place for this
    const objMoment = moment.tz(strDate, strCurrentTimezone || 'America/Denver');
    // const blah = objMoment.tz('UTC');
    return objMoment.unix();
  },
  isValidDate: (date) => {
    return (
      /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(date) || // Our database standard date time (2000-01-01 01:00:00)
      /^\d{4}-\d{2}-\d{2}$/.test(date) || // Our database standard date (2000-01-01)
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{4}$/.test(date) // facebook API's standard date time (2000-01-01T01:00:00+0000)
    );
  },
};
export default dateTime;
