import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Controller } from 'react-hook-form';
import { Box, FormHelperText, GlobalStyles } from '@mui/material';
import { debounce } from 'lodash';

// IMPORTANT - if this is being rendered in a Drawer, the link functionality will not work correctly.
// You must add the `ModalProps={{ disableEnforceFocus: true }}` prop to the Drawer that this component is in to ensure correct functionality.
// https://github.com/ckeditor/ckeditor5/issues/7328#issuecomment-1247240725
const HookFormTextEditor = memo(
  ({ disabled, disabledToolbarItems, headerOptions, height, helperText, name, onVariantChange, required }) => {
    const handleUserInput = (setFieldValue, userInput) => {
      setFieldValue(userInput);
    };

    const debouncedHandleUserInput = debounce(handleUserInput, 15, { trailing: true });

    // Takes in html and will return the variant
    // ie <h1>Test</h1> will return h1.
    function getVariant(html) {
      if (!html) {
        return null;
      }
      const sanitizedHtml = html.replace('<blockquote>', '');
      return sanitizedHtml.slice(1, sanitizedHtml.indexOf('>'));
    }

    // Remove any disabled items from the toolbar.
    const compiledEditor = ClassicEditor;
    compiledEditor.defaultConfig = {
      ...ClassicEditor.defaultConfig,
      toolbar: {
        items: ClassicEditor.defaultConfig.toolbar.items.filter(
          (toolbarItem) => !disabledToolbarItems.includes(toolbarItem)
        ),
      },
    };

    const config = {
      link: {
        // Make links open in new tabs.
        decorators: {
          isExternal: {
            mode: 'automatic',
            callback: (url) => url,
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer',
            },
          },
        },
      },
    };

    return (
      <Controller
        name={name}
        render={({ field: { ref, ...field }, fieldState: { error }, formState: { isSubmitting } }) => {
          return (
            <Box
              sx={{
                color: '#000',
              }}
            >
              <GlobalStyles
                styles={{
                  // So that the add link dialog displays properly.
                  '.ck.ck-balloon-panel': {
                    zIndex: `1400 !important`,
                  },
                  h1: {
                    fontSize: '32px',
                  },
                  h2: {
                    fontSize: '28px',
                  },
                  h3: {
                    fontSize: '26px',
                  },
                  h4: {
                    fontSize: '24px',
                  },
                  h5: {
                    fontSize: '22px',
                  },
                  h6: {
                    fontSize: '20px',
                  },
                  body2: {
                    fontSize: '18px',
                  },
                }}
              />
              <CKEditor
                editor={compiledEditor}
                data={field.value}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle('height', height, editor.editing.view.document.getRoot());
                  });
                  onVariantChange(getVariant(editor.getData()));
                }}
                onChange={(event, editor) => {
                  // Trigger onVariantChange if variant changes.
                  const oldVariant = getVariant(field.value);
                  const newVariant = getVariant(editor.getData());
                  if (oldVariant !== newVariant) {
                    onVariantChange(newVariant);
                  }

                  debouncedHandleUserInput(field.onChange, editor.getData());
                }}
                inputRef={ref}
                required={required}
                disabled={disabled || isSubmitting}
                config={
                  headerOptions
                    ? {
                        ...config,
                        heading: {
                          options: headerOptions,
                        },
                      }
                    : config
                }
              />
              <FormHelperText error={error}>{error ? error.message : helperText}</FormHelperText>
            </Box>
          );
        }}
      />
    );
  }
);

HookFormTextEditor.propTypes = {
  disabled: PropTypes.bool,
  disabledToolbarItems: PropTypes.arrayOf(
    PropTypes.oneOf([
      'undo',
      'redo',
      'heading',
      'bold',
      'italic',
      'link',
      'uploadImage',
      'insertTable',
      'blockQuote',
      'mediaEmbed',
      'bulletedList',
      'numberedList',
      'outdent',
      'indent',
    ])
  ),
  headerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      model: PropTypes.string.isRequired,
      view: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      class: PropTypes.string.isRequired,
    })
  ),
  helperText: PropTypes.string,
  height: PropTypes.string,
  name: PropTypes.string.isRequired,
  onVariantChange: PropTypes.func,
  required: PropTypes.bool,
};

HookFormTextEditor.defaultProps = {
  disabled: false,
  disabledToolbarItems: [],
  headerOptions: null,
  height: 'calc(100vh - 400px)',
  helperText: null,
  onVariantChange: () => {},
  required: false,
};

export default HookFormTextEditor;
