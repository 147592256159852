import moment from 'moment';
import { DateRange, SortingType } from '@/components/Dashboarding/ChartJS/types';
import dateTime from '../DateTime';

const arrayUtilities = {
  moveArrayElement: (arr: any, fromIndex: number, toIndex: number) => {
    if (toIndex >= arr.length) {
      let k: number = toIndex - arr.length + 1;
      while ((k -= 1)) {
        arr.push(undefined);
      }
    }
    arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
    return arr;
  },

  // todo: @travis - I got this `groupBy` function from Stack Overflow.
  //    It's not very TypeScript friendly in this implementation, and its output needs sorted.
  //    I wonder if a re-implementation of this would be better for us.
  //    It uses a "sequence" that I don't understand and don't know how to refactor away.
  // Got this function here: https://stackoverflow.com/a/64489535
  // eslint-disable-next-line no-sequences,no-return-assign,no-param-reassign
  groupBy: (x: any, f: any) => x.reduce((a: any, b: any, i: any) => ((a[f(b, i, x)] ||= []).push(b), a), {}),

  // todo: @travis - this is a partial re-write of the function above with more understandable variable names.
  //  It's a step toward refactoring but I'm stopping here for now.
  groupBy2: (originalData: any, groupingFunction: any) => {
    const data = [...originalData];
    return data.reduce(
      // eslint-disable-next-line no-return-assign
      (accumulator: any, currentValue: any, currentIndex: any) =>
        (
          // eslint-disable-next-line no-sequences
          (accumulator[groupingFunction(currentValue, currentIndex, data)] ||= []).push(currentValue), accumulator
        ),
      {} // reducer's default value (I think)
    );
  },

  // todo: this is my hacky way of getting the grouped results sorted. It's likely possible to reduce/sort in a better way,
  //  but I'm not smart/motivated enough to figure it out right now. This gets me what I need for the UX.
  // When sorting by DATE_RANGE, optionally can pass in allDatesBetweenDateRange.
  // This will set all missing dates to have a count of zero.
  sortGroupedResult: (
    groupedResult: any,
    sortingType: SortingType = 'COUNT',
    allDatesBetweenDateRange: string[] = [],
    cumulateData: boolean = false
  ) => {
    const unsortedResults = Object.keys(groupedResult).map((propertyName: string) => {
      // Returning `rows` here enables easy drill-down on click.
      return { label: propertyName, count: groupedResult[propertyName].length, rows: groupedResult[propertyName] };
    });

    if (sortingType === 'PRESORTED') {
      return unsortedResults;
    }

    if (sortingType === 'COUNT') {
      return unsortedResults.sort((a: any, b: any) => b.count - a.count);
    }

    // Sorting By DATE_RANGE
    if (allDatesBetweenDateRange) {
      // If we have a dateRange passed to us, fill in the gaps with counts of 0.
      allDatesBetweenDateRange.forEach((possibleDate) => {
        if (!unsortedResults.some((existingDate) => existingDate.label === possibleDate)) {
          unsortedResults.push({ label: possibleDate, count: 0, rows: [] });
        }
      });

      // Sort the results. Important for the data to be in the correct order before cumulating.
      let sortedResults = unsortedResults.sort(
        (a: any, b: any) => moment(a.label).valueOf() - moment(b.label).valueOf()
      );

      if (cumulateData) {
        // Cumulate the data
        sortedResults = sortedResults.reduce((result: [{ label: string; count: number; rows: any[] }], item: any) => {
          // @ts-ignore
          const currentCount = result.length > 0 ? result[result.length - 1].count : 0;
          // @ts-ignore
          const currentRows = result.length > 0 ? result[result.length - 1].rows.slice() : [];

          const updatedCount = currentCount + item.count;
          const updatedRows = currentRows.concat(item.rows);

          result.push({
            label: item.label,
            count: updatedCount,
            rows: updatedRows,
          });

          return result;
        }, [] as any);
      }

      // Filter out any results not within the Date Range.
      return sortedResults.filter((item) => allDatesBetweenDateRange.some((date) => date === item.label));
    }

    return unsortedResults.sort((a: any, b: any) => moment(a.label).valueOf() - moment(b.label).valueOf());
  },

  // Will return all dates between a dateRange.
  // Useful for function above, sortGroupedResult.
  getAllDatesBetweenDateRange: (
    dateRange: DateRange,
    currentUser: { date_format: string; time_zone: string }
  ): string[] => {
    return Array.from({ length: dateRange.end_date.diff(dateRange.start_date, 'days') + 1 }, (_, index) =>
      dateTime.formatUtcDate(
        dateRange.start_date.clone().add(index, 'days'),
        currentUser.date_format,
        currentUser.time_zone
      )
    );
  },

  // Will take all nested data and compile a list of headers.
  // We need to do this for every row as the first row may be missing data.
  // ie visit may have sub selections such as UTM information but if visit = null in data[0], that column will never be made.
  // EX: [id, created_at, form.name, contact.id, contact.account.name, ...]
  getColumnsFromData: (data: any[]): string[] => {
    const uniqueKeysSet: Set<string> = new Set();

    // Gets the keys from each row. Will join all of these together with no duplicates.
    const getRowKeys = (obj: any, parentKeys: string[] = []) => {
      Object.keys(obj).forEach((key) => {
        const currentKeys = [...parentKeys, key];
        const value = obj[key];

        if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
          getRowKeys(value, currentKeys);
        } else if (value !== null) {
          uniqueKeysSet.add(currentKeys.join('.'));
        }
      });
    };

    data.forEach((row) => getRowKeys(row));

    // Sort the data based on its path.
    // EX: id -> created_at -> contact.email -> contact.account.name -> form.name
    const customSort = (a: any, b: any) => {
      const partsA = a.split('.');
      const partsB = b.split('.');

      for (let i = 0; i < Math.min(partsA.length, partsB.length); i += 1) {
        if (partsA[i] !== partsB[i]) {
          // ID should always appear first in its path.
          if (partsA[i] === 'id') return -1;
          if (partsB[i] === 'id') return 1;

          if (i === partsA.length - 1) return -1; // a is a prefix of b
          if (i === partsB.length - 1) return 1; // b is a prefix of a

          return partsA[i].localeCompare(partsB[i]);
        }
      }

      return partsA.length - partsB.length;
    };

    // Sort the returned, unique columns by path.
    return [...uniqueKeysSet].sort(customSort);
  },
};

export default arrayUtilities;
