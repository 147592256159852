import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { useLocation } from 'react-router';
import { lazyWithRetry as lazy } from '@/utilities/lazyWithRetry';
import MakeRouteWithSubRoutes from './makeRouteWithSubRoutes';

const IcpCustomerSurvey = lazy(
  () =>
    import(/* webpackChunkName: "AccountPanel", webpackPrefetch: true */ 'goldilocks/pages/Surveys/IcpCustomerSurvey')
);

const routes = [
  {
    path: '/survey/icp',
    exact: true,
    component: IcpCustomerSurvey,
  },
];

const CustomerSurveyRoute = () => {
  const location = useLocation();

  return (
    <Suspense fallback={<div />}>
      <Switch location={location}>
        {routes.map((route) => (
          <MakeRouteWithSubRoutes key={route.path} {...route} />
        ))}
      </Switch>
    </Suspense>
  );
};

export default CustomerSurveyRoute;
