import React from 'react';
import { Box, FormControl, FormHelperText, FormLabel, Stack, TextField } from '@mui/material';
import Slider from '@mui/material/Slider';
import InputAdornment from '@mui/material/InputAdornment';
import { Controller } from 'react-hook-form';
// import { AddIcon, RemoveIcon } from '@mui/icons-material';

// This increases the width of the input field.
// based on the length of the current value and the length
// of the suffix, as well as if step is a decimal value.
const determineInputWidth = (max: any, suffix: any, step: any) => {
  const lengthOfMax = String(max).length;
  const lengthOfSuffix = String(suffix).length;
  const isStepADecimal = Boolean(step - Math.floor(step) !== 0);

  // Slider starting width values and increment values.
  const baseInputWidth = 3.8;
  const inputWidthIncrement = 0.4;
  let calculatedInputWidth;

  // If the length of the input value is more than 0 then start
  // incrementing for each number.
  if (lengthOfMax > 0) {
    // Dynamic input width that uses
    // the baseWidth plus the increment width for each
    // numbers place in the input value.
    const additionalInputWidthBasedOnMax = lengthOfMax * inputWidthIncrement;
    calculatedInputWidth = baseInputWidth + additionalInputWidthBasedOnMax;

    // If the length of the suffix is more than 2 then add additional width
    if (lengthOfSuffix > 2) {
      const additionalInputWidthBasedOnSuffix = (lengthOfSuffix - 2) * inputWidthIncrement;
      calculatedInputWidth += additionalInputWidthBasedOnSuffix;
    }

    // Check to see if step is a decimal number and if so increase the width by one increment.
    if (isStepADecimal) {
      calculatedInputWidth += inputWidthIncrement;
    }
    return `${calculatedInputWidth}rem`;
  }
  // Return the base width if the input values numbers place is less than 1
  return `${baseInputWidth}rem`;
};

const HookFormSlider = ({
  suffix,
  step,
  min,
  max,
  icon,
  disabled,
  helperText,
  label,
  name,
  onChange,
  size,
  sx,
  defaultValue,
}: {
  suffix: string;
  step: number;
  min: number;
  max: number;
  icon?: JSX.Element;
  disabled?: boolean;
  helperText?: string;
  label?: string;
  name: string;
  onChange?: any;
  size?: 'small' | 'medium';
  sx?: object;
  defaultValue?: any;
}) => {
  function getValue(fieldValue: any) {
    if (fieldValue) {
      return typeof fieldValue === 'number' ? fieldValue : Number(fieldValue) || 0;
    }
    if (defaultValue) {
      return defaultValue;
    }
    return 0;
  }

  return (
    <Controller
      name={name}
      render={({ field, formState: { isSubmitting } }) => (
        <FormControl fullWidth>
          <FormLabel id="input-slider">{label}</FormLabel>
          <Box sx={{ width: '100%' }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box>{icon}</Box>
              <Box sx={{ width: '100%' }}>
                <Slider
                  {...field}
                  min={min}
                  max={max}
                  step={step}
                  size={size}
                  sx={{
                    ...sx,
                    '& .MuiSlider-thumb': {
                      color: defaultValue && getValue(field.value) === defaultValue ? 'grey' : 'primary',
                    },
                    '& .MuiSlider-track': {
                      color: defaultValue && getValue(field.value) === defaultValue ? 'grey' : 'primary',
                    },
                  }}
                  // @ts-ignore
                  value={getValue(field.value)}
                  disabled={isSubmitting || disabled}
                  onChange={(e, value) => {
                    field.onChange(value);
                    onChange(value);
                  }}
                  aria-labelledby="input-slider"
                />
              </Box>
              <Box>
                <TextField
                  {...field}
                  variant="outlined"
                  size={size}
                  type="number"
                  // @ts-ignore
                  value={getValue(field.value)}
                  disabled={isSubmitting || disabled}
                  onChange={(e: any) => {
                    const numberValue = Number(e.target.value);
                    field.onChange(isNaN(numberValue) ? '' : numberValue);
                    onChange(e);

                    if (numberValue > max) {
                      field.onChange(max);
                    }

                    if (numberValue < min) {
                      field.onChange(min);
                    }
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
                    inputProps: {
                      step,
                      min,
                      max,
                      'aria-labelledby': 'input-slider',
                      style: { textAlign: 'right' },
                    },
                  }}
                  sx={{
                    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
                      {
                        WebkitAppearance: 'none',
                        margin: 0,
                      },
                    '& input[type="number"]': {
                      MozAppearance: 'textfield',
                    },
                    width: determineInputWidth(max, suffix, step),
                  }}
                />
              </Box>
            </Stack>
          </Box>
          {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </FormControl>
      )}
    />
  );
};

HookFormSlider.defaultProps = {
  disabled: false,
  helperText: '',
  icon: null,
  label: '',
  onChange: () => {},
  size: 'small',
  sx: {},
  defaultValue: null,
};

export default HookFormSlider;
