import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '@/utilities/localstorage';
import PropTypes from 'prop-types';
import { Alert, Slide, Snackbar } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
// import * as serviceWorker from 'goldilocks/serviceWorker';

const ServiceWorkerContext = createContext();

const ServiceWorkerProvider = ({ children }) => {
  const [displaySnackbar, setDisplaySnackbar] = React.useState(false);
  // const [waitingWorker] = React.useState(null);
  const [updateAvailable] = React.useState(false);
  const [oldCorePath, setOldCorePath] = React.useState(null);
  const { hostname } = new URL(window.location.href);

  let appName = 'Paminga';
  if (hostname === 'app.net-results.com') {
    appName = 'Goldilocks';
  }

  // Update was found by service worker.
  // Set updateAvailable to true and the update will process next route change.
  // const onUpdateFound = (registration) => {
  //   setUpdateAvailable(true);
  //   setWaitingWorker(registration.waiting);
  // };

  // Runs once on startup.
  useEffect(() => {
    // serviceWorker.register({ onUpdate: onUpdateFound });

    setDisplaySnackbar(getLocalStorageWithExpiry('JustUpdated') || false);

    setLocalStorageWithExpiry('JustUpdated', false, 7 * 60 * 60 * 1000);
  }, []);

  const contextValue = useMemo(() => {
    function updateSW(pathname) {
      // Force reload the page to fully update.
      setLocalStorageWithExpiry('JustUpdated', true, 7 * 60 * 60 * 1000);
      // waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
      window.location.replace(`${window.location.origin}${pathname}`);
    }

    return { updateAvailable, updateSW, oldCorePath, setOldCorePath };
  }, [oldCorePath, updateAvailable]);

  return (
    <ServiceWorkerContext.Provider value={contextValue}>
      <>
        <Snackbar
          open={displaySnackbar}
          autoHideDuration={6000}
          onClose={() => {
            setDisplaySnackbar(false);
          }}
        >
          <Slide direction="right" in={displaySnackbar} mountOnEnter unmountOnExit>
            <Alert icon={<CheckCircle />} variant="filled" severity="info">
              {appName} has been updated!
            </Alert>
          </Slide>
        </Snackbar>
        {children}
      </>
    </ServiceWorkerContext.Provider>
  );
};

ServiceWorkerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useServiceWorkerContext = () => {
  const context = useContext(ServiceWorkerContext);
  if (context === undefined) {
    throw new Error('useServiceWorkerContext must be used within a ServiceWorkerProvider');
  }
  return context;
};

export { ServiceWorkerProvider, useServiceWorkerContext };

// TODO: how do i configure it so that its network first instead of cache first
