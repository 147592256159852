import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { Box, Stack, Popover, Tooltip, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';
import Heading from '@/components/core/Heading';

const InfoPopover = ({
  anchorSide,
  buttonColor,
  content,
  disabled,
  icon,
  iconLabel,
  iconFontSize,
  iconSx,
  titleSx,
  titleBoxSx,
  subTitle,
  title,
  toolTipTitle,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? uuidv4() : undefined;

  const styledIcon = React.cloneElement(icon, {
    sx: {
      fontSize: iconFontSize,
      color: disabled ? 'action.disabled' : buttonColor,
      cursor: disabled ? '' : 'pointer',
      ...iconSx,
    },
    onClick: (event) => {
      if (!disabled) {
        handleClick(event);
      }
    },
  });

  return (
    <>
      <Tooltip title={toolTipTitle} placement="top" arrow enterDelay={700}>
        {iconLabel ? (
          <Stack alignItems="center" direction="row" gap={1}>
            <Typography sx={{ color: 'text.secondary' }}>{iconLabel}</Typography>
            {styledIcon}
          </Stack>
        ) : (
          <Box display="flex" alignItems="center">
            {styledIcon}
          </Box>
        )}
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: anchorSide,
        }}
      >
        <Box sx={{ ...titleBoxSx }}>
          <Stack direction="column" spacing={2}>
            {title ? (
              <Heading label={title} subHeading={subTitle} variant="h5" paddingBottom={0} sx={{ ...titleSx }} />
            ) : null}
            {content}
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

InfoPopover.defaultProps = {
  anchorSide: 'right',
  buttonColor: (theme) => theme.palette.brand.techBlue,
  disabled: false,
  icon: <Info />,
  iconLabel: null,
  iconFontSize: 24,
  iconSx: {},
  subTitle: null,
  title: null,
  titleSx: {},
  titleBoxSx: {
    maxWidth: 460,
    padding: 3,
  },
  toolTipTitle: '',
};

InfoPopover.propTypes = {
  anchorSide: PropTypes.string,
  buttonColor: PropTypes.func,
  content: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  iconLabel: PropTypes.string,
  iconFontSize: PropTypes.number,
  iconSx: PropTypes.object,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  titleSx: PropTypes.object,
  titleBoxSx: PropTypes.object,
  toolTipTitle: PropTypes.string,
};

export default InfoPopover;
