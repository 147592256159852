import { getLocalStorageWithExpiry } from './localstorage';

const jwtStorageKey = import.meta.env.VITE_APP_LOCALSTORAGE_AUTH_KEY;

function getJWTFromStorage() {
  const token = getToken();
  if (!token) {
    return Promise.resolve(null);
  }
  return token;
}

const logout = async () => {
  window.localStorage.removeItem(jwtStorageKey);
  window.localStorage.removeItem(import.meta.env.VITE_APP_LOCALSTORAGE_USER_KEY);
  // try and logout legacy
  await fetch(`${import.meta.env.VITE_APP_APPS_ENDPOINT}/logout.php`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
  });

  window.location.reload();
  return Promise.resolve();
};

function getToken() {
  return getLocalStorageWithExpiry(jwtStorageKey);
}

export { logout, getToken, getJWTFromStorage };
