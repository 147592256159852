import React, { useState, useEffect, useRef, useContext, useCallback, forwardRef, createContext } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Popper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import {
  ArrowDownward,
  ArrowUpward,
  CheckBox as CheckedIcon,
  CheckBoxOutlineBlank as CheckBoxIcon,
  ImportExport,
} from '@mui/icons-material';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import { bindMenu, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '@/utilities/localstorage';
import stringUtilities from '@/utilities/String';
import dateTime from '@/utilities/DateTime';
import { useCurrentUser } from '@/context/UserContext';
import formatters from '@/utilities/Format';

const LISTBOX_PADDING = 8; // px

const renderRow = (props) => {
  const { data, index, style } = props;
  const { label, extraLabel, value } = data[index][1];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (extraLabel) {
    // We have extra information we need to display next to the name.
    return (
      <Typography component="li" {...data[index][0]} style={inlineStyle} key={value}>
        <Stack direction="row" alignItems="center">
          <Checkbox icon={<CheckBoxIcon />} checkedIcon={<CheckedIcon />} checked={data[index][0]['aria-selected']} />
          <Chip
            size="small"
            label={extraLabel.value}
            sx={extraLabel.type === 'date' ? { minWidth: 85 } : null} // 85 is the smallest width to fit any formatted DateTime
          />
          <Typography sx={{ paddingLeft: 1 }}>{label}</Typography>
        </Stack>
      </Typography>
    );
  }

  return (
    <Typography component="li" {...data[index][0]} style={inlineStyle} key={value}>
      <Checkbox
        icon={<CheckBoxIcon />}
        checkedIcon={<CheckedIcon />}
        style={{ marginRight: 8 }}
        checked={data[index][0]['aria-selected']}
      />
      {label}
    </Typography>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { currentUser } = useCurrentUser();
  const { children, ...other } = props;

  function formatExtraLabel(extraLabel) {
    if (extraLabel.type === 'date' && extraLabel.value) {
      // return the formatted date
      return dateTime.formatUtcDate(
        extraLabel.value,
        currentUser?.date_format || 'MM/DD/YYYY',
        currentUser?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Denver'
      );
    }
    if (extraLabel.type === 'number' && extraLabel.value) {
      return formatters.formatNumber(extraLabel.value);
    }
    if (extraLabel.value !== null && extraLabel.value !== false) {
      return extraLabel.value;
    }
    return 'None';
  }

  const itemData = [];
  children.forEach((item) => {
    if (item[1].extraLabel) {
      // If this item has an ExtraLabel, format it according to formatExtraLabel.
      itemData.push([
        item[0],
        {
          ...item[1],
          extraLabel: { value: formatExtraLabel(item[1].extraLabel), type: item[1].extraLabel.type },
        },
      ]);
    } else {
      itemData.push(item);
    }
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.array.isRequired,
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

// this doesn't spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormAutocompleteSortableChips = ({
  autoFocus,
  disabled,
  disableCloseOnSelect,
  freeSolo,
  helperText,
  label,
  loading,
  name,
  onOpenClose,
  options: initialOptions,
  required,
  localStorageSortPreferenceType,
  sx,
  testId,
  width,
}) => {
  const sortPreferencePopupState = usePopupState({ variant: 'popover' });
  const [autocompletePopupOpen, setAutocompletePopupOpen] = useState(false);
  const [value, setValue] = useState([]);
  const [sortByOptions, setSortByOptions] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const formatDateColumns = ['created_at', 'updated_at', 'launch_date', 'starts_at']; // Add any new DateTime columns here.

  // Will convert the Enum Type to a LocalStorage name
  // ie MARKETING_LISTS -> MarketingListsAutocompleteSortPreference
  const localStorageSortPreferenceName = localStorageSortPreferenceType
    ? `${stringUtilities
        .capitalizeWords(localStorageSortPreferenceType.replaceAll('_', ' '), true)
        .replaceAll(' ', '')}AutocompleteSortPreference`
    : null;

  useEffect(() => {
    if (loading) {
      return;
    }
    if (initialOptions.length === 0) {
      // If no options were passed, this tells us to not sort but also not to render that the autocomplete is loading.
      setSortBy({ key: 'skip' });
      return;
    }

    // Will create an array of options for the Sort Preference Autocomplete from every column for the parent Autocomplete.
    // ie if the data for the parent Autocomplete is of the structure { name: x, updated_at: x, value: id }
    // sortByOptions will equal [ {label: Name, value: name }, { label: Updated At, value: updated_at }]
    if (!sortByOptions) {
      setSortByOptions([
        ...Object.keys(initialOptions[0])
          .filter((key) => key !== 'value')
          .map((option) => {
            return {
              label: stringUtilities.capitalizeWords(option.replaceAll('_', ' ')),
              type: formatDateColumns.includes(option) ? 'date' : typeof initialOptions[0][`${option}`],
              value: option,
            };
          }),
      ]);
    }

    if (sortByOptions) {
      // Get Sort Preference from Local Storage
      // If Local Storage value exists and is a valid Sort By Preference, set it.
      // Otherwise, set sortBy to the first option ascending.
      setSortBy(
        getLocalStorageWithExpiry(localStorageSortPreferenceName) &&
          sortByOptions.some((option) => option.value === getLocalStorageWithExpiry(localStorageSortPreferenceName).key)
          ? getLocalStorageWithExpiry(localStorageSortPreferenceName)
          : { key: sortByOptions[0].value, sortByAsc: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOptions, loading, localStorageSortPreferenceName, sortByOptions]);

  // freeSolo means our user may enter any arbitrary value they like by typing: we do not have a pre-defined array of "options".
  // For example, our user is able to type in any email address(es) they like as "recipients" of an Alert.
  // If freeSolo, we have to pass this "renderTags" prop to <Field/> below.
  const renderTags = useCallback(
    (tagValue, getTagProps) =>
      tagValue.map((option, index) => {
        if (typeof option === 'object') {
          return <Chip key={option.value} variant="outlined" label={option.label} {...getTagProps({ index })} />;
        }
        return <Chip key={option} variant="outlined" label={option} {...getTagProps({ index })} />;
      }),
    []
  );

  if (loading || !sortBy) {
    return (
      <FormControl variant="outlined" size="small" fullWidth={!width}>
        <InputLabel>{label}</InputLabel>
        <OutlinedInput
          label={label}
          endAdornment={<CircularProgress size={20} />}
          disabled
          sx={{ backgroundColor: 'divider', ...sx, width }}
        />
      </FormControl>
    );
  }

  // Sort the Autocomplete options based on the Sort Preferences
  function sortAutocompleteUsingSelectedSortPreference(a, b) {
    const { key, sortByAsc } = sortBy;

    let aValue = a[key];
    let bValue = b[key];

    if (typeof aValue === 'number') {
      // Compare as numbers
      if (sortByAsc) {
        return aValue - bValue;
      }
      return bValue - aValue;
    }

    // Compare as strings
    aValue = aValue ? aValue.toString().toLowerCase() : '';
    bValue = bValue ? bValue.toString().toLowerCase() : '';

    if (sortByAsc) {
      return aValue.localeCompare(bValue);
    }
    return bValue.localeCompare(aValue);
  }

  initialOptions.sort(sortAutocompleteUsingSelectedSortPreference);

  // Compile our parent Autocomplete options.
  const options = initialOptions.map((option) => {
    if (!sortByOptions) {
      return [];
    }

    let type = sortBy.key !== sortByOptions[0].value ? typeof option[sortBy.key] : null;
    if (type === 'string') {
      type = formatDateColumns.includes(sortBy.key) ? 'date' : 'string';
    }

    return {
      label: option[sortByOptions[0].value],
      // If we are sorting on something other than the name, we will also want to display this next to the name in the Autocomplete.
      // ie (Automation Name) when sorting on name
      // ie (08/26/2023 - Automation Name) when sorting on created_at
      extraLabel: sortBy.key !== sortByOptions[0].value ? { value: option[sortBy.key], type } : null,
      value: option.value,
    };
  });

  const isOptionEqualToValue = (option, selectedValue) => {
    return String(option.value) === String(selectedValue.value);
  };

  const getCurrentInternalValue = (fieldValue) => {
    let currentValue = [];
    if (value.length === 0) {
      currentValue = fieldValue.map((el) => {
        const valueForDisplay = options.find((element) => String(element.value) === String(el));
        return valueForDisplay || el;
      });
    } else {
      currentValue = value;
    }
    if (JSON.stringify(value) !== JSON.stringify(fieldValue.value)) {
      currentValue = fieldValue.map((el) => {
        const valueForDisplay = options.find((element) => String(element.value) === String(el));
        return valueForDisplay || el;
      });
    }
    return currentValue;
  };

  const handleSortByClick = (event, index) => {
    let newSortPreference;
    if (sortBy.key === sortByOptions[index].value) {
      // If the user selects the already selected option, switch the sort order.
      newSortPreference = { key: sortBy.key, sortByAsc: !sortBy.sortByAsc };
    } else {
      // If the user selected a new option, set it.
      newSortPreference = {
        key: sortByOptions[index].value,
        sortByAsc: sortByOptions[index].type === 'string', // if it's a String, set to ASC, if it's a number or date, set to ASC
      };
    }
    setSortBy(newSortPreference);
    if (localStorageSortPreferenceName) {
      setLocalStorageWithExpiry(localStorageSortPreferenceName, newSortPreference, 7 * 60 * 60 * 1000);
    }
  };

  function buildSortByMenuOption(option) {
    if (option.value === sortBy.key) {
      // This is the selected option.
      // We need to display the Sort Order next to it.
      return (
        <>
          <ListItemIcon style={{ minWidth: 0, marginRight: 8 }}>
            {sortBy.sortByAsc ? (
              <ArrowUpward style={{ fontSize: '16px' }} />
            ) : (
              <ArrowDownward style={{ fontSize: '16px' }} />
            )}
          </ListItemIcon>
          <ListItemText>{option.label}</ListItemText>
        </>
      );
    }
    // This is not the selected option.
    // Don't display the Sort Order next to it.
    return (
      <ListItemText inset style={{ marginLeft: -12 }}>
        {option.label}
      </ListItemText>
    );
  }

  const SortPreferencesMenu = (
    <>
      <IconButton
        size="small"
        sx={{ marginRight: freeSolo ? '0px' : '-8px' }}
        disabled={disabled || !sortByOptions}
        {...bindToggle(sortPreferencePopupState)}
        onClick={(eventAnchorOrEl) => {
          setAutocompletePopupOpen(true);
          onOpenClose(true);
          sortPreferencePopupState.toggle(eventAnchorOrEl);
        }}
      >
        <ImportExport id={`${name}.sortPreferencesIcon`} />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          my: 0,
          py: 0,
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
        {...bindMenu(sortPreferencePopupState)}
      >
        <Box paddingLeft={2} paddingBottom={1}>
          <Typography>Sort By</Typography>
        </Box>
        {sortByOptions
          ? sortByOptions.map((option, index) => (
              <MenuItem
                key={option.value}
                selected={option.value === sortBy.key}
                onClick={(event) => handleSortByClick(event, index)}
                id={`${name}.sortPreferenceMenu`} // Allows us to interact with the SortBy menu without closing the parent Autocomplete menu.
              >
                {buildSortByMenuOption(option)}
              </MenuItem>
            ))
          : null}
      </Menu>
    </>
  );

  return (
    <Controller
      render={({ field, fieldState: { invalid, error }, formState: { isSubmitting } }) => {
        // 'error' must be handled dynamically in order to extract the correct error message.
        // 'error' is null when no errors.
        // When there are errors, the format of 'error' depends on the validation rule.
        // Error will be an object {...error} if we are validating the Autocomplete as a whole (ie .min() rule)
        // Error will be an array of objects [null, {...error}] if we are validating each element of the autocomplete (ie checking if each is .email()).
        // This code block will extract the correct message dependent on the structure of error.
        let errorMessage = error && error?.message ? error.message : null;
        if (error && !errorMessage) {
          const [firstError] = Object.values(error).filter((errorItem) => errorItem !== null && 'message' in errorItem);

          if (firstError) {
            errorMessage = firstError.message;
          }
        }

        return (
          <Autocomplete
            value={getCurrentInternalValue(field.value)}
            isOptionEqualToValue={!freeSolo ? isOptionEqualToValue : undefined}
            onChange={(_, newValue) => {
              // set the internal value in state
              setValue(newValue);

              // this sets the hook form field value to either the selected option value (from passed in options)
              // or it allows a freesolo typed in value
              // you still end up with a hook form field value that is an array like [option.value, 'enteredString', anotherSelectedOption.value]
              field.onChange(newValue.map((el) => (typeof el === 'object' ? el.value : el)));
            }}
            onBlur={field.onBlur}
            freeSolo={freeSolo}
            renderTags={freeSolo ? renderTags : undefined}
            options={options}
            multiple
            disableClearable={false}
            disableCloseOnSelect={disableCloseOnSelect}
            disabled={disabled || isSubmitting}
            noOptionsText="No options were found"
            size="small"
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            renderOption={(props, option) => [props, option]}
            open={autocompletePopupOpen}
            onOpen={() => {
              setAutocompletePopupOpen(true);
              onOpenClose(true);
            }}
            onClose={(event) => {
              // Close the Autocomplete unless we are interacting with the Sort Preference menu.
              if (event?.relatedTarget?.id !== `${name}.sortPreferenceMenu`) {
                setAutocompletePopupOpen(false);
                sortPreferencePopupState.close();
                onOpenClose(false);
              }
            }}
            fullWidth={!width}
            sx={{ ...sx, width }}
            renderInput={(params) => {
              // This will add the Sort Options button to the Autocomplete.
              const appendedParams = sortByOptions
                ? {
                    ...params,
                    InputProps: {
                      ...params?.InputProps,
                      endAdornment: params?.InputProps?.endAdornment ? (
                        {
                          ...params?.InputProps?.endAdornment,
                          props: {
                            ...params?.InputProps?.endAdornment?.props,
                            children: [SortPreferencesMenu, params?.InputProps?.endAdornment?.props?.children[1]],
                          },
                        }
                      ) : (
                        // When the Autocomplete is freeSolo and has no selected options, there will be no endAdornment to overwrite.
                        // When this happens, just set the endAdornment to this InputAdornment.
                        <InputAdornment position="end">{SortPreferencesMenu}</InputAdornment>
                      ),
                    },
                  }
                : { ...params };

              return (
                <TextField
                  {...appendedParams}
                  error={invalid}
                  helperText={errorMessage || helperText}
                  label={label}
                  required={required}
                  autoFocus={autoFocus}
                  variant="outlined"
                  data-testid={testId}
                />
              );
            }}
          />
        );
      }}
      name={name}
    />
  );
};

HookFormAutocompleteSortableChips.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  freeSolo: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onOpenClose: PropTypes.func,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  localStorageSortPreferenceType: PropTypes.oneOf([
    'AUTOMATIONS',
    'EMAILS',
    'FORMS',
    'FORM_MAPPINGS',
    'LANDING_PAGES',
    'MARKETING_LISTS',
    'SEGMENTS',
    'SMS_MESSAGES',
    'SUBSCRIPTION_LISTS',
    'TAGS',
    'VIDEOS',
    'WEBINARS',
  ]),
  sx: PropTypes.object,
  testId: PropTypes.string,
  width: PropTypes.number,
};

HookFormAutocompleteSortableChips.defaultProps = {
  autoFocus: false,
  disabled: false,
  disableCloseOnSelect: true,
  freeSolo: false,
  helperText: null,
  loading: false,
  onOpenClose: () => {},
  required: false,
  localStorageSortPreferenceType: null,
  sx: {},
  testId: null,
  width: null,
};

export default HookFormAutocompleteSortableChips;
