import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

function useGraphQlLooper() {
  const client = useApolloClient();
  const graphQlLooper = useCallback(
    async (gqlAst, incomingVariables, queryName) => {
      const variables = incomingVariables;
      let returnResults = [];
      let data = await client.query({
        query: gqlAst,
        variables,
      });
      returnResults = returnResults.concat(data.data[queryName].data);

      if (data.data[queryName].paginatorInfo.hasMorePages) {
        do {
          variables.page = data.data[queryName].paginatorInfo.currentPage + 1;

          // eslint-disable-next-line no-await-in-loop
          data = await client.query({
            query: gqlAst,
            variables,
          });
          returnResults = returnResults.concat(data.data[queryName].data);
        } while (data.data[queryName].paginatorInfo.hasMorePages);
      }

      return returnResults;
    },
    [client]
  );

  return {
    graphQlLooper,
  };
}

export default useGraphQlLooper;
