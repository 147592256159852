import React from 'react';
import PropTypes from 'prop-types';
import { Box, Fade, Tooltip } from '@mui/material';
import { Download } from '@mui/icons-material';
import NrButton from '@/components/NrMaterialOverrides/NrButton';
import jsonToCsvDownload, { jsonToExcelDownload } from '@/utilities/jsonDownloadFormatter';
import { useSnackbar } from 'notistack';

const DownloadButton = ({ data, fileName, label, hideLabel, exportType }) => {
  const { enqueueSnackbar } = useSnackbar();
  const displaySnackbar = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const handleError = () => {
    displaySnackbar('There was an issue with downloading this data', 'error');
  };

  return (
    <Fade in appear timeout={950}>
      <Box>
        {hideLabel ? (
          <Tooltip
            title={`Download this data as ${exportType === 'CSV' ? 'CSV' : 'Excel'}`}
            placement="left"
            arrow
            enterDelay={900}
          >
            <span>
              <NrButton
                endIcon={<Download />}
                onClick={() => {
                  exportType === 'CSV'
                    ? jsonToCsvDownload(data, `${fileName}.csv`)
                    : jsonToExcelDownload(data, `${fileName}.xlsx`, handleError);
                }}
                sx={{ paddingLeft: 0, paddingRight: '10px', minWidth: '35px' }}
              />
            </span>
          </Tooltip>
        ) : (
          <Tooltip
            title={`Download this data as ${exportType === 'CSV' ? 'CSV' : 'Excel'}`}
            placement="top"
            arrow
            enterDelay={900}
          >
            <span>
              <NrButton
                endIcon={<Download />}
                onClick={() => {
                  exportType === 'CSV'
                    ? jsonToCsvDownload(data, `${fileName}.csv`)
                    : jsonToExcelDownload(data, `${fileName}.xlsx`, handleError);
                }}
              >
                {!hideLabel ? label : null}
              </NrButton>
            </span>
          </Tooltip>
        )}
      </Box>
    </Fade>
  );
};

DownloadButton.defaultProps = {
  hideLabel: false,
  label: 'Download',
  exportType: 'CSV',
};

DownloadButton.propTypes = {
  data: PropTypes.array.isRequired,
  fileName: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  label: PropTypes.string,
  exportType: PropTypes.string,
};

export default DownloadButton;
