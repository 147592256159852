import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { Box } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themeLight from 'goldilocks/themes/themeLightGoldilocks';

const UnAuthedLayout = (props) => {
  const currentTheme = themeLight;

  const snackbarStyles = makeStyles({
    success: {
      backgroundColor: currentTheme.palette.success.main,
      color: currentTheme.palette.success.contrastText,
      fontSize: '0.875rem',
    },
    warning: {
      backgroundColor: currentTheme.palette.warning.main,
      color: currentTheme.palette.warning.contrastText,
      fontSize: '0.875rem',
    },
    error: {
      backgroundColor: currentTheme.palette.error.main,
      color: currentTheme.palette.error.contrastText,
      fontSize: '0.875rem',
    },
    info: {
      backgroundColor: currentTheme.palette.info.main,
      color: currentTheme.palette.info.contrastText,
      fontSize: '0.875rem',
    },
  });
  const snackbarClasses = snackbarStyles();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <SnackbarProvider
          variant="success"
          maxSnack={4}
          preventDuplicate
          classes={{
            variantSuccess: snackbarClasses.success,
            variantError: snackbarClasses.error,
            variantWarning: snackbarClasses.warning,
            variantInfo: snackbarClasses.info,
          }}
        >
          <Box>{props.children}</Box>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

UnAuthedLayout.propTypes = {
  children: PropTypes.node,
};

UnAuthedLayout.defaultProps = {
  children: '',
};

export default UnAuthedLayout;
