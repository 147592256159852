import { createContext, useContext } from 'react';

const UserContext = createContext();

const useCurrentUser = () => {
  const { currentUser, setCurrentUser, handleUserUpdate, handleProductUpdate } = useContext(UserContext);

  if (useContext(UserContext) === undefined) {
    throw new Error(`useCurrentUser must be used within a UserProvider`);
  }
  return {
    currentUser,
    setCurrentUser,
    handleUserUpdate,
    handleProductUpdate,
  };
};

export { UserContext, useCurrentUser };
