import React, { useContext, useCallback, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material';
import { AddCircle, ChevronRight, ChevronLeft, Key } from '@mui/icons-material';
import { usePopupState, bindHover, bindFocus } from 'material-ui-popup-state/hooks';
import { useCurrentUser } from '@/context/UserContext';
import { useAppContext } from '@/context/AppContext';
import CascadingMenu from '../CascadingMenu';
import CascadingContext from '../CascadingContext';

const useCascadingMenuStyles = makeStyles((theme) => ({
  submenu: {
    marginTop: theme.spacing(-1),
  },
  title: {
    flexGrow: 1,
  },
  moreArrowRight: {
    marginRight: theme.spacing(-1),
  },
  moreArrowLeft: {
    marginLeft: theme.spacing(-1),
  },
}));

const CascadingSubmenu = ({
  arrowDirection,
  createNewUrl,
  createNewLinkIsExternal,
  featureSlug,
  icon,
  menuItemUrl,
  popupId,
  title,
  ...props
}) => {
  const [menuItemHovered, setMenuItemHovered] = useState(false);
  const { currentUser } = useCurrentUser();
  const { appName } = useAppContext();
  const history = useHistory();
  const classes = useCascadingMenuStyles();
  const { parentPopupState } = useContext(CascadingContext);
  const ref = useRef(null);
  const popupState = usePopupState({
    popupId,
    variant: 'popover',
    parentPopupState,
  });

  useEffect(() => {
    if (ref.current != null) {
      // set the anchorElement to the parent menuitem and not the chevron
      popupState.setAnchorEl(ref.current);
    }
  }, [ref, popupState]);

  const popupStateMouseLeave = bindHover(popupState).onMouseLeave;

  const handleClick = useCallback(
    (event) => {
      parentPopupState.close(event);
      popupState.close(event);
    },
    [parentPopupState, popupState]
  );

  const handleIconClick = (event) => {
    event.stopPropagation(); // We don't want a click on a "Create New" to propagate to the underlying <MenuItem/>.
    event.preventDefault();
    parentPopupState.close(event);
    popupState.close(event);
    createNewLinkIsExternal ? window.open(createNewUrl, '_blank') : history.push(createNewUrl);
  };

  const handleMouseEnter = () => {
    setMenuItemHovered(!menuItemHovered);
  };

  const handleMouseLeave = (event) => {
    setMenuItemHovered(!menuItemHovered);
    // close the submenu when mouse leaves the menu item
    // otherwise it will stay open unless you do a slick mouse leave of the chevron
    popupStateMouseLeave(event);
  };

  const featureInclusionType = currentUser.product?.package?.features.find((feature) => feature.slug === featureSlug)
    ?.pivot?.inclusion_type;

  return (
    <Box {...bindHover(popupState)} {...bindFocus(popupState)}>
      <MenuItem
        ref={ref}
        component={Link}
        to={menuItemUrl}
        sx={{
          minWidth: '170px',
          ':visited': {
            color: (theme) => theme.palette.text.primary,
          },
        }}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {icon && <ListItemIcon sx={{ color: (theme) => theme.palette.text.primary, mr: -1 }}>{icon}</ListItemIcon>}
        {arrowDirection === 'left' && <ChevronLeft className={classes.moreArrowLeft} />}

        <ListItemText primary={title} className={classes.title} />

        {/* Show "Key" icon if this feature is not included in this PID's package. */}
        {appName === 'Paminga' && featureInclusionType === 'NOT_INCLUDED' ? (
          <Key sx={{ ml: 1, color: 'text.disabled', transform: 'rotate(-30deg)' }} />
        ) : null}

        {createNewUrl ? (
          <Box
            ml={2}
            mr={1}
            sx={{ opacity: menuItemHovered ? 1 : 0, lineHeight: 0.5 }}
            data-testid={`menuItemCreateNew-${title.replace(/\s/g, '-').toLowerCase()}`}
          >
            {featureInclusionType !== 'NOT_INCLUDED' ? (
              <Tooltip title={`Create New ${title}`} arrow>
                <AddCircle fontSize="medium" onClick={handleIconClick} />
              </Tooltip>
            ) : null}
          </Box>
        ) : null}
        {arrowDirection === 'right' && <ChevronRight className={classes.moreArrowRight} />}
      </MenuItem>
      <CascadingMenu
        {...props}
        classes={{ ...props.classes, paper: classes.submenu }}
        anchorOrigin={{ vertical: 'top', horizontal: arrowDirection === 'left' ? 'left' : 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: arrowDirection === 'left' ? 'right' : 'left' }}
        popupState={popupState}
      />
    </Box>
  );
};

CascadingSubmenu.defaultProps = {
  arrowDirection: 'right',
  createNewLinkIsExternal: false,
  createNewUrl: null,
  featureSlug: null,
  classes: {},
  icon: null,
};

CascadingSubmenu.propTypes = {
  arrowDirection: PropTypes.string,
  classes: PropTypes.object,
  createNewUrl: PropTypes.string,
  createNewLinkIsExternal: PropTypes.bool,
  featureSlug: PropTypes.string,
  icon: PropTypes.node,
  menuItemUrl: PropTypes.string.isRequired,
  popupId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CascadingSubmenu;
