import React from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';

const NrButton = ({
  type,
  variant,
  size,
  onClick,
  disabled,
  startIcon,
  endIcon,
  fullWidth,
  disableFocusRipple,
  disableElevation,
  dataTestId,
  children,
  sx,
  buttonType,
  isLoading,
  ...rest
}) => {
  let disableElevationDefault = disableElevation;
  if (type === 'secondary') {
    disableElevationDefault = true;
  }

  let color;
  let backgroundColor;
  let backgroundHoverColor;

  switch (type) {
    case 'primary':
      color = 'buttons.primary.color';
      backgroundColor = 'buttons.primary.backgroundColor';
      backgroundHoverColor = 'buttons.primary.backgroundHoverColor';
      break;
    case 'secondary':
      color = 'buttons.secondary.color';
      backgroundColor = 'buttons.secondary.backgroundColor';
      backgroundHoverColor = 'buttons.secondary.backgroundHoverColor';
      break;
    case 'tangerine':
      color = '#fff';
      backgroundColor = 'buttons.tangerine.backgroundColor';
      backgroundHoverColor = 'buttons.tangerine.backgroundHoverColor';
      break;
    // no default
  }

  return (
    <LoadingButton
      data-testid={dataTestId}
      loading={isLoading}
      sx={{
        color,
        borderRadius: '20px',
        backgroundColor,
        fontSize: { xs: '0.7rem', sm: '0.775rem' },
        fontWeight: 700,
        letterSpacing: { xs: '0.1rem', sm: '0.12rem' },
        py: { xs: '6px', sm: 1 },
        paddingLeft: { xs: '14px', sm: 3 },
        paddingRight: { xs: '14px', sm: 3 },
        ':hover': {
          backgroundColor: sx?.backgroundHoverColor ?? backgroundHoverColor,
          borderColor: sx?.borderHoverColor ?? 'inherit',
          transform: 'scale(1.02)',
        },
        width: fullWidth ? '100%' : 'fit-content',
        transition: 'all 200ms ease-in-out',
        borderStyle: 'solid',
        ...sx,
      }}
      variant={variant}
      size={size}
      type={buttonType}
      onClick={onClick}
      disabled={disabled}
      disableFocusRipple={disableFocusRipple}
      disableElevation={disableElevationDefault}
      startIcon={startIcon}
      endIcon={endIcon}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};

NrButton.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'tangerine']),
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  disableFocusRipple: PropTypes.bool,
  disableElevation: PropTypes.bool,
  fullWidth: PropTypes.bool,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  dataTestId: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object,
  buttonType: PropTypes.string,
  isLoading: PropTypes.bool,
};

NrButton.defaultProps = {
  onClick: () => {},
  size: 'small',
  type: 'primary',
  variant: 'contained',
  disabled: false,
  fullWidth: false,
  disableFocusRipple: false,
  disableElevation: false,
  startIcon: null,
  endIcon: null,
  dataTestId: null,
  children: '',
  sx: {},
  buttonType: 'button',
  isLoading: false,
};

export default NrButton;
