import gql from 'graphql-tag';

export const QueryPersonalProfile = gql`
  query QueryPersonalProfile($id: ID!) {
    user(id: $id) {
      id
      first_name
      last_name
      email
      mobile_phone
      work_phone
      title
    }
  }
`;

export const MutationUpdatePersonalProfile = gql`
  mutation MutationUpdatePersonalProfile($id: ID!, $input: PatchableCurrentUserFields!) {
    updateCurrentUser(input: { id: $id, patch: $input }) {
      id
      first_name
      last_name
      email
      mobile_phone
      work_phone
      title
    }
  }
`;

export const SetSidebarCollapsedStatePreferenceMutation = gql`
  mutation SetSidebarCollapsedStatePreferenceMutation($user_id: ID!, $sidebar_collapsed_state: Boolean) {
    updateCurrentUser(input: { id: $user_id, patch: { sidebar_collapsed_state: $sidebar_collapsed_state } }) {
      id
      sidebar_collapsed_state
    }
  }
`;

export default QueryPersonalProfile;
