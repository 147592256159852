import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import NrButton from '../../NrMaterialOverrides/NrButton';
import { RequestPasswordResetMutation } from './queries.graphql';
import useHandleApolloErrors from '../../utilities/HandleApolloErrors';
import { FormikField } from '../../FormikFields';

const ForgotPassword = () => {
  const [requestPasswordReset] = useMutation(RequestPasswordResetMutation);
  const { enqueueSnackbar } = useSnackbar();
  const { handleApolloErrors } = useHandleApolloErrors();
  const [requestSuccessful, setRequestSuccessful] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter your email address')
      .typeError('Please enter your email address'),
  });

  const displaySnackbar = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    const variables = {
      variables: {
        email: values.email,
      },
    };

    try {
      const { data: resetRequestResponse } = await requestPasswordReset({
        ...variables,
      });

      if (resetRequestResponse.requestPasswordReset.success === true) {
        setSubmitting(false);
        setRequestSuccessful(true);
      } else {
        setFieldError('email', 'There was a problem with your request.');
      }
    } catch (error) {
      setSubmitting(false);
      handleApolloErrors(error, setFieldError, displaySnackbar);
    }
  };

  return (
    <>
      {requestSuccessful ? (
        <Box>Password reset request successful. We&apos;ve sent you an email with a link to reset your password.</Box>
      ) : (
        <Formik initialValues={{ email: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {(formik) => (
            <Form>
              <Stack direction="column" spacing={2} alignItems="center">
                <FormikField fieldType="text" fieldLabel="Email Address" fieldName="email" autoFocus />

                <NrButton
                  onClick={formik.submitForm}
                  disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                  isLoading={formik.isSubmitting}
                >
                  Request Password Reset
                </NrButton>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
      <Box mt={4} sx={{ cursor: 'pointer', color: (theme) => theme.palette.brand.techBlue }}>
        <Link to="/login">back to login</Link>
      </Box>
    </>
  );
};

export default ForgotPassword;
