import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { FormatDateTime } from '@/components/utilities/DateTime';

const DateTimeCell = ({ value }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end" height="100%">
      <Typography textAlign="right">
        {value === '-0001-11-30 00:00:00' ? 'N/A' : <FormatDateTime date={value} breakLines />}
      </Typography>
    </Box>
  );
};

DateTimeCell.propTypes = {
  value: PropTypes.node,
};

DateTimeCell.defaultProps = {
  value: '',
};

export default DateTimeCell;
