import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import convertStringToBoolean from '@/utilities/convertStringToBoolean';

// this doesn't spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormRadioButtons = ({
  color,
  disabled,
  helperText,
  isBoolean,
  items,
  label,
  name,
  onChange,
  required,
  row,
  sx,
}) => {
  return (
    <Controller
      render={({ field, fieldState: { invalid, error }, formState: { isSubmitting } }) => {
        if (isBoolean && (field.value === 'true' || field.value === 'false')) {
          field.onChange(convertStringToBoolean(field.value));
        }
        return (
          <FormControl error={invalid} color={color}>
            {label ? (
              <FormLabel
                id={name}
                sx={{
                  '&.Mui-focused': {
                    color: 'inherit',
                  },
                }}
                required={required}
              >
                {label}
              </FormLabel>
            ) : null}
            <RadioGroup
              aria-labelledby={name}
              row={row}
              {...field}
              disabled={disabled || isSubmitting}
              // onChange must come below ...field cause it overrides field.onChange
              onChange={(e) => {
                if (isBoolean) {
                  field.onChange(convertStringToBoolean(e.target.value));
                  onChange(convertStringToBoolean(e.target.value));
                } else {
                  field.onChange(e.target.value);
                  onChange(e.target.value);
                }
              }}
              sx={{ ...sx }}
            >
              {items.map((radioItem) => {
                return (
                  <FormControlLabel
                    key={`${radioItem.label}-${radioItem.value}`}
                    value={radioItem.value}
                    control={<Radio size="small" color={color} disabled={disabled || isSubmitting} />}
                    label={radioItem.label}
                    disabled={disabled || isSubmitting}
                  />
                );
              })}
            </RadioGroup>
            {error || helperText ? <FormHelperText>{error ? error.message : helperText}</FormHelperText> : null}
          </FormControl>
        );
      }}
      name={name}
    />
  );
};

HookFormRadioButtons.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isBoolean: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    })
  ).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  row: PropTypes.bool,
  sx: PropTypes.object,
};

HookFormRadioButtons.defaultProps = {
  color: 'secondary',
  disabled: false,
  helperText: null,
  isBoolean: false,
  label: null,
  onChange: () => {},
  required: false,
  row: false,
  sx: {},
};

export default HookFormRadioButtons;
