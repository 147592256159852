import * as Sentry from '@sentry/react';
import { getLocalStorageWithExpiry } from '@/utilities/localstorage';

function useHandleApolloErrors() {
  const currentUser = getLocalStorageWithExpiry(import.meta.env.VITE_APP_LOCALSTORAGE_USER_KEY);
  const handleApolloErrors = (
    error,
    setFieldError,
    displaySnackbar,
    displayErrorAsSnackbar = false,
    hookFormSetFieldError = () => {}
  ) => {
    // Prevent triggering of multiple Snackbars when multiple errors are present (unless there are multiple validation errors & displayErrorAsSnackbar = true).
    let snackbarMessage = null;

    // eslint-disable-next-line no-console
    console.log('error', error);
    const { graphQLErrors, networkError } = error;

    if (graphQLErrors) {
      graphQLErrors.forEach((gqlError) => {
        if (gqlError.extensions?.validation) {
          const failedFields = Object.keys(gqlError.extensions.validation);
          failedFields.forEach((field) => {
            // responses from gql include the full path (ie input.name, input.patch.name)
            // split on '.' and get the last item to match the form field name
            const formFieldName = field.split('.').pop();
            setFieldError(formFieldName, gqlError.extensions.validation[field]);
            hookFormSetFieldError(formFieldName, { message: gqlError.extensions.validation[field] });
            if (displayErrorAsSnackbar) {
              // Allow display of multiple snackbars if we have multiple validation
              gqlError.extensions.validation[field].forEach((validationMessage) => {
                displaySnackbar(validationMessage, 'error');
              });
            }
          });
        }

        /**
         * Errors with a "category" of "graphql" include, at least, attempting a query/mutation with a required param missing.
         * It seems that errors where category = 'graphql' are caught by GQL before attempting query resolution (malformed query, missing argument, likely incorrect arg type).
         *
         * Errors with a "category" of "internal" include, at least, "Internal Server Error".
         * These appear to be errors where GQL saw no problems with the query (its args, its types) but a failure occurred in the resolver/Laravel part of the operation.
         *
         * We've currently no reason to differentiate on the category, so simply setting Snackbar messages.
         */
        // if (gqlError.extensions?.category) {
        let sendToSentry = true;
        switch (gqlError.message) {
          case 'Internal server error':
            snackbarMessage = 'There was a problem completing your request';
            break;
          case 'Invalid email or password':
            snackbarMessage = gqlError.message;
            sendToSentry = false;
            break;
          case 'Invalid password reset attempt. Link may be expired or invalid.':
            snackbarMessage = gqlError.message;
            sendToSentry = false;
            break;
          case 'You are currently unauthenticated.':
            snackbarMessage = gqlError.message;
            sendToSentry = false;
            break;
          case 'This action is unauthorized.':
            snackbarMessage = 'You are not authorized to make this change. Please contact your administrator.';
            sendToSentry = false;
            break;
          case 'Invalid Authentication Source':
            snackbarMessage =
              "You've tried to login with a username & password, but that's not how your account was created.";
            sendToSentry = false;

            // Inform user which OAuth provider they should be using.
            switch (gqlError.extensions.code) {
              case 'OAUTH_GOOGLE':
                snackbarMessage = 'You used Google to create your account. Please sign in with Google.';
                break;
              // no default
            }
            break;
          case 'Failed to fetch':
            // eslint-disable-next-line no-param-reassign
            displayErrorAsSnackbar = false;
            sendToSentry = false;
            break;
          default:
            snackbarMessage = 'There was a problem completing your request';
        }
        if (/Please wait *.* before trying again./.test(gqlError.message)) {
          // account lockout from too many invalid logins
          snackbarMessage = gqlError.message;
        }
        // Send every non-validation gqlError to Sentry.
        if (sendToSentry && !gqlError.extensions?.validation) {
          Sentry.captureException(error, { extra: gqlError });
        }
      });
    }

    // This is if the server returns a 500 or other non 200 response.
    if (networkError && Number(currentUser?.product?.id) !== 26372) {
      Sentry.captureException(error, { extra: networkError });
      snackbarMessage = 'There was a problem completing your request';
    }

    // Do not displaySnackbar if we've already displayed it (due to a validation error above).
    if (snackbarMessage && !displayErrorAsSnackbar) {
      displaySnackbar(snackbarMessage, 'error');
    }
  };

  return {
    handleApolloErrors,
  };
}

export default useHandleApolloErrors;
