import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import LoginForm from './LoginForm';

const AccountLogin = () => {
  const { hostname } = new URL(window.location.href);
  const isGoldilocks = hostname === 'app.net-results.com';

  useEffect(() => {
    document.title = isGoldilocks ? `Net-Results Login` : `Paminga Login`;
  });

  return (
    <Stack direction="column" spacing={2} useFlexGap>
      <LoginForm />
      <Box sx={{ cursor: 'pointer', color: (theme) => theme.palette.brand.techBlue }}>
        <Link to="/forgot-password">forgot your password?</Link>
      </Box>

      <Typography py={2}>~ or ~</Typography>
      <Button
        href={`${import.meta.env.VITE_APP_LARAVEL_ENDPOINT}/api/login/google/oauth`}
        variant="outlined"
        size="large"
        sx={{
          py: 1,
          px: 4,
          borderRadius: 3,
          backgroundColor: '#f8f8f8',
          textTransform: 'none',
        }}
      >
        <img
          src="https://res.cloudinary.com/net-results/image/upload/q_auto,f_auto/v1719325121/Paminga%20WWW/Logos/Google_G_logo.svg"
          alt="Sign In with Google"
          style={{ width: 20 }}
        />{' '}
        &nbsp;&nbsp; Sign In with Google
      </Button>
    </Stack>
  );
};

export default AccountLogin;
