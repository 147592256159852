import React, { ReactNode } from 'react';
import { useAppContext } from '@/context/AppContext';
import { Box } from '@mui/material';

const MainContentWrapper = ({
  children,
  suppressNavTopMargin,
}: {
  children: ReactNode;
  suppressNavTopMargin: Boolean;
}) => {
  const { headerHeight } = useAppContext();

  return (
    <Box
      sx={{
        mt: suppressNavTopMargin ? 0 : `${headerHeight}px`,
        overflow: 'auto !important',
        maxHeight: `calc(100vh - ${headerHeight}px)`,
      }}
    >
      {children}
    </Box>
  );
};

export default MainContentWrapper;
