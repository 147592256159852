import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useCurrentUser } from '@/context/UserContext';
import '@/utilities/moment-timezone-with-data-1970-2030';

const FormatDate = ({ date }) => {
  const { currentUser } = useCurrentUser();
  if (date === null || date === '0000-00-00' || date === '') {
    return null;
  }

  let timeZone;
  if (currentUser?.time_zone) {
    timeZone = currentUser.time_zone;
  } else {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  return moment
    .utc(date)
    .tz(timeZone || 'America/Denver')
    .format(currentUser?.date_format || 'M/D/YYYY');
};
FormatDate.propTypes = {
  date: PropTypes.string.isRequired,
};

const FormatTime = ({ date }) => {
  const { currentUser } = useCurrentUser();

  let timeZone;
  if (currentUser?.time_zone) {
    timeZone = currentUser.time_zone;
  } else {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  return moment
    .utc(date)
    .tz(timeZone || 'America/Denver')
    .format(currentUser?.time_format || 'h:mm A');
};
FormatTime.propTypes = {
  date: PropTypes.string.isRequired,
};

/**
 * Accepts `date` as a string (not a Moment object).
 *
 * @param {string} date
 * @param {boolean} breakLines
 * @returns {JSX.Element|string}
 * @constructor
 */
const FormatDateTime = ({ date, breakLines }) => {
  if (date === null || date === '0000-00-00 00:00:00') {
    return null;
  }
  return (
    <>
      <FormatDate date={date} /> {breakLines ? <br /> : null} <FormatTime date={date} />
    </>
  );
};
FormatDateTime.propTypes = {
  date: PropTypes.string,
  breakLines: PropTypes.bool,
};
FormatDateTime.defaultProps = {
  breakLines: false,
  date: null,
};

/**
 * convertFromUTCToLocalAndFormatDateTime:
 *
 * @param date
 * @param currentUser
 * @returns {*}
 */
const convertDateTimeFromUTCToLocal = (date, currentUser) => {
  return moment.utc(date).tz(currentUser.time_zone || 'America/Denver');
};

export { FormatDate, FormatTime, FormatDateTime, convertDateTimeFromUTCToLocal };
