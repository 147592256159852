import { AbilityBuilder, Ability } from '@casl/ability';

export default function defineAbilityFor() {
  const { can, rules } = new AbilityBuilder(Ability);

  // const permissions = getLocalStorageWithExpiry('NRPermissions');

  // permissions.forEach((permission) => {
  //   const permissionName = permission.name;
  //   const myArr = permissionName.split(' ');
  //   const action = myArr[0];
  //   const model = myArr[1];
  //   can(action, model);
  // });

  // can manage all is a built in thing to allow everything.
  // for now we are not returning permissions from gql
  // so all users can do all things for the time being
  can('manage', 'all');

  return new Ability(rules);
}
