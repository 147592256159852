import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';

// this doesn't spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormSelect = ({ disabled, fullWidth, helperText, items, label, name, required, onChange, sx }) => {
  return (
    <Controller
      name={name}
      render={({ field, fieldState: { invalid, error }, formState: { isSubmitting } }) => (
        <TextField
          select
          size="small"
          variant="outlined"
          fullWidth={fullWidth}
          label={label}
          required={required}
          error={invalid}
          helperText={error ? error.message : helperText}
          sx={{ textAlign: 'left', ...sx }}
          {...field}
          disabled={disabled || isSubmitting}
          onChange={(e) => {
            field.onChange(e.target.value);
            onChange(e.target.value);
          }}
        >
          {items.map((option) => (
            <MenuItem key={`${option.label}-${option.value}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

HookFormSelect.propTypes = {
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  sx: PropTypes.object,
};

HookFormSelect.defaultProps = {
  disabled: false,
  fullWidth: false,
  helperText: null,
  required: false,
  onChange: () => {},
  sx: {},
};

export default HookFormSelect;
