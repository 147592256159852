import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { bindMenu } from 'material-ui-popup-state/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import CascadingContext from '../CascadingContext';

const CascadingMenu = ({ popupState, ...props }) => {
  const { rootPopupState } = useContext(CascadingContext);
  const context = useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState]
  );

  return (
    <CascadingContext.Provider value={context}>
      <HoverMenu {...props} {...bindMenu(popupState)} />
    </CascadingContext.Provider>
  );
};

CascadingMenu.propTypes = {
  popupState: PropTypes.object.isRequired,
};

export default CascadingMenu;
