import gql from 'graphql-tag';

export const CurrentVersionQuery = gql`
  query CurrentVersion {
    currentVersion {
      version
    }
  }
`;
export default CurrentVersionQuery;
