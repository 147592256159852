import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Stack } from '@mui/material';

// this doesn't spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormCheckbox = ({
  color,
  disabled,
  helperText,
  infoPopover,
  label,
  name,
  onClick,
  onChange,
  required,
  size,
  sx,
}) => {
  if (!label) {
    return (
      <Controller
        name={name}
        render={({ field, formState: { isSubmitting }, fieldState: { error } }) => (
          <>
            <Checkbox
              color={color}
              size={size}
              sx={sx}
              checked={field.value}
              {...field}
              disabled={isSubmitting || disabled}
              // onChange must come below ...field cause it overrides field.onChange
              onChange={(e, newValue) => {
                field.onChange(e.target.checked);
                onChange(newValue);
              }}
              onClick={(e) => onClick(e)}
            />
            {error || helperText ? (
              <FormHelperText error={!!error}>{error ? error.message : helperText}</FormHelperText>
            ) : null}
          </>
        )}
      />
    );
  }

  return (
    <Controller
      name={name}
      render={({ field, formState: { isSubmitting }, fieldState: { error } }) => (
        <FormControl>
          <Stack direction="row" alignItems="center">
            <FormControlLabel
              sx={{ mr: 1 }}
              control={
                <Checkbox
                  color={color}
                  size={size}
                  sx={sx}
                  {...field}
                  disabled={isSubmitting || disabled}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              }
              color={color}
              label={label}
              required={required}
            />
            {infoPopover}
          </Stack>
          {error || helperText ? (
            <FormHelperText error={!!error}>{error ? error.message : helperText}</FormHelperText>
          ) : null}
        </FormControl>
      )}
    />
  );
};

HookFormCheckbox.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  infoPopover: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  sx: PropTypes.object,
};

HookFormCheckbox.defaultProps = {
  color: 'secondary',
  disabled: false,
  helperText: null,
  infoPopover: null,
  label: null,
  onClick: () => {},
  onChange: () => {},
  required: false,
  size: 'small',
  sx: {},
};

export default HookFormCheckbox;
