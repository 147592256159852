import React from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useCurrentUser } from '@/context/UserContext';
import shouldSalesFeaturesBeDisplayed from '@/utilities/restrictSalesFeatures';
import TopNavItem from '@/components/Navigation/TopNav/TopNavItem';
import { iconMappings } from '../../constants';
import NrMenuItem from '../NrMenuItem';
import CascadingMenu from '../../CascadingMenu';

const NavSales = () => {
  const { currentUser } = useCurrentUser();
  const popupState = usePopupState({ variant: 'popover', popupId: 'navSales' });

  if (!shouldSalesFeaturesBeDisplayed(currentUser)) {
    return null;
  }

  let hasOpportunities = false;
  let hasLeads = false;
  if (currentUser.product?.standard_objects) {
    hasOpportunities = !!currentUser.product.standard_objects.find(
      (standardObject) => standardObject.name === 'opportunity'
    );
    hasLeads = !!currentUser.product.standard_objects.find((standardObject) => standardObject.name === 'lead');
  }

  return (
    <>
      <TopNavItem label="Sales" popupState={popupState} />

      <CascadingMenu
        popupState={popupState}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {hasLeads ? (
          <NrMenuItem menuItemText="Leads" menuItemIcon={iconMappings.lead} menuItemUrl="/sales/leads" isLabFeature />
        ) : null}
        {hasOpportunities ? (
          <NrMenuItem
            menuItemText="Opportunities"
            menuItemIcon={iconMappings.opportunities}
            menuItemUrl="/sales/opportunities"
            isLabFeature
          />
        ) : null}
        <NrMenuItem
          menuItemText="Tasks"
          menuItemIcon={iconMappings.taskAlt}
          menuItemUrl="/sales/tasks"
          createNewUrl="/sales/tasks/createEdit"
        />
      </CascadingMenu>
    </>
  );
};

export default NavSales;
