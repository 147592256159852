import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  LocalizationProvider,
  DateRangePicker as MuiDateRangePicker,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { InputAdornment, IconButton } from '@mui/material';
import { DateRange } from '@mui/icons-material';
import { useCurrentUser } from '@/context/UserContext';
import CustomRangeShortcuts from './DateRangeShortcutPanel';

const DateRangePicker = ({
  color,
  dateRange,
  disabled,
  disableFuture,
  error,
  field,
  fullWidth,
  helperText,
  initialDateRange,
  invalid,
  label,
  onChange,
  required,
  restrictToThirtyDays,
  sx,
}) => {
  const { currentUser } = useCurrentUser();
  let initialStartDate = null;
  let initialEndDate = null;
  if (initialDateRange) {
    initialStartDate = moment(initialDateRange.start_date);
    initialEndDate = moment(initialDateRange.end_date);
  }
  const [internalValue, setInternalValue] = useState([initialStartDate, initialEndDate]);
  const [errorMessage, setErrorMessage] = useState(error);

  useEffect(() => {
    if (dateRange) {
      setInternalValue([dateRange.start_date, dateRange.end_date]);
    }
  }, [dateRange]);

  useEffect(() => {
    if (internalValue[0] > internalValue[1]) {
      setErrorMessage({ message: 'Please select a valid date range.' });
    } else {
      // error will be null unless we have passed in an error as props
      setErrorMessage(error);
    }
  }, [error, internalValue]);

  const maximumDate = internalValue[0]?.clone().add(30, 'day') || undefined;

  // basic sanity check on the date
  const isValidDate = (date) => {
    let isValid = true;
    const clonedDate = date.clone();
    const year = clonedDate.year();
    const month = clonedDate.month();
    const day = clonedDate.date();
    // ill be dead by 2100 so not my problem :)
    if (year < 1900 || year > 2100) {
      isValid = false;
      return isValid;
    }
    if (month < 1 || month > 12) {
      isValid = false;
      return isValid;
    }
    if (day < 1 || day > 31) {
      isValid = false;
      return isValid;
    }
    return isValid;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDateRangePicker
        label={label}
        value={internalValue}
        maxDate={restrictToThirtyDays ? maximumDate : undefined}
        disabled={disabled}
        disableFuture={disableFuture}
        format={currentUser.date_format}
        onChange={(newValue) => {
          setInternalValue(newValue);
          if (newValue[0] && newValue[1]) {
            // only trigger the onChange if the dates are valid
            if (isValidDate(newValue[0]) && isValidDate(newValue[1]) && newValue[1].isAfter(newValue[0])) {
              const startDate = newValue[0]?.clone();
              const endDate = newValue[1]?.clone();
              onChange({
                start_date: startDate?.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                end_date: endDate?.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
              });
            }
          }
        }}
        slots={{
          field: SingleInputDateRangeField,
          shortcuts: CustomRangeShortcuts,
        }}
        slotProps={{
          field: {
            size: 'small',
            required,
            fullWidth,
            error: invalid || errorMessage, // invalid is passed from props, errorMessage set in this component
            helperText: errorMessage ? errorMessage.message : helperText,
            InputProps: {
              onBlur: field.onBlur,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton color={color} disabled={disabled} edge="end">
                    <DateRange sx={{ color: (theme) => theme.palette.secondary.main }} />
                  </IconButton>
                </InputAdornment>
              ),
            },
            inputRef: field.ref,
          },
          shortcuts: { restrictToThirtyDays, currentDateRange: internalValue },
        }}
        sx={sx}
      />
    </LocalizationProvider>
  );
};

DateRangePicker.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  dateRange: PropTypes.object,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  error: PropTypes.object,
  field: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  initialDateRange: PropTypes.object,
  invalid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  restrictToThirtyDays: PropTypes.bool,
  sx: PropTypes.object,
};

DateRangePicker.defaultProps = {
  color: 'secondary',
  dateRange: null,
  disabled: false,
  disableFuture: false,
  error: null,
  field: {},
  fullWidth: false,
  helperText: '',
  initialDateRange: null,
  invalid: false,
  onChange: () => {},
  required: false,
  restrictToThirtyDays: false,
  sx: {},
};

export default DateRangePicker;
