import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FormatTime } from '../../../utilities/DateTime';

const TimeCell = ({ date }) => {
  return (
    <Box sx={{ textAlign: 'right' }}>
      <FormatTime date={date} />
    </Box>
  );
};

TimeCell.propTypes = {
  date: PropTypes.node,
};

TimeCell.defaultProps = {
  date: '',
};

export default TimeCell;
