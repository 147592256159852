import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import DateRangePicker from '@/components/DateRangePicker';
import { useCurrentUser } from '@/context/UserContext';
import moment from 'moment';

// this doesn't spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormDateRange = ({ color, fullWidth, helperText, label, name, required, onChange, sx }) => {
  const { currentUser } = useCurrentUser();

  const convertInitialDateRangeFromUTC = (initialDateRange) => {
    if (!!initialDateRange?.start_date && !!initialDateRange?.end_date) {
      return {
        start_date: moment.utc(initialDateRange.start_date).tz(currentUser.time_zone).format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment.utc(initialDateRange.end_date).tz(currentUser.time_zone).format('YYYY-MM-DD HH:mm:ss'),
      };
    }
    return null;
  };

  return (
    <Controller
      render={({ field, fieldState: { invalid, error }, formState: { isSubmitting } }) => {
        return (
          <DateRangePicker
            label={label}
            onChange={(newValue) => {
              if (newValue?.start_date && newValue?.end_date) {
                const formattedValue = {
                  start_date: moment(newValue.start_date).utc(),
                  end_date: moment(newValue.end_date).utc(),
                };
                onChange(formattedValue);
                field.onChange(formattedValue);
              }
            }}
            isSubmitting={isSubmitting}
            initialDateRange={convertInitialDateRangeFromUTC(field.value)}
            required={required}
            invalid={invalid}
            error={error}
            field={field}
            color={color}
            helperText={helperText}
            fullWidth={fullWidth}
            sx={sx}
          />
        );
      }}
      name={name}
    />
  );
};

HookFormDateRange.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  sx: PropTypes.object,
};

HookFormDateRange.defaultProps = {
  color: 'secondary',
  fullWidth: false,
  helperText: null,
  required: false,
  onChange: () => {},
  sx: {},
};

export default HookFormDateRange;
