import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Cancel } from '@mui/icons-material';

const DrawerRightHeader = ({ heading, onClose }) => {
  return (
    <Box
      p={0}
      sx={{
        position: 'sticky',
        top: 0,
        right: 0,
        width: 'inherit',
        zIndex: (theme) => theme.zIndex.drawer - 1,
        backgroundColor: (theme) => theme.palette.background.paper,
        borderBottom: '1px solid',
        borderBottomColor: (theme) => theme.palette.borderColor,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" py={1} pl={2} pr={0}>
        <Typography variant="h4" m={0}>
          {heading}
        </Typography>
        <Tooltip title="Close" arrow enterDelay={700}>
          <IconButton size="large" onClick={onClose()}>
            <Cancel fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
};

DrawerRightHeader.defaultProps = {
  onClose: () => {},
};

DrawerRightHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default DrawerRightHeader;
