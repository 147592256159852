import React from 'react';
import { Box } from '@mui/material';

const NrLogo = () => {
  const { hostname } = new URL(window.location.href);

  let logo;

  switch (hostname) {
    case 'app.net-results.com':
      logo = <img src="/logo/blue_favicon.svg" alt="Net-Results Logo" width={38} />;
      break;
    case 'app.paminga.com':
      logo = (
        <img
          src="https://res.cloudinary.com/net-results/image/upload/v1707871107/Paminga%20WWW/paminga-logo-light.png"
          alt="Paminga Logo"
          width={268}
        />
      );
      break;
    default:
      logo = (
        <img
          src="https://res.cloudinary.com/net-results/image/upload/v1707871107/Paminga%20WWW/paminga-logo-light.png"
          alt="Paminga Logo"
          width={268}
        />
      );
  }

  return (
    <Box sx={{ justifyContent: 'center' }}>
      <Box>{logo}</Box>
      {hostname === 'app.net-results.com' ? (
        <Box>
          <img
            src="/logo/blue_wordmark.svg"
            alt="Net-Results Logo"
            style={{ marginLeft: '9px', paddingTop: '2px' }}
            width={140}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default NrLogo;
