import React from 'react';
import PropTypes from 'prop-types';
import { darken, lighten, useTheme } from '@mui/material/styles';
import { AlertTitle, Box, Stack } from '@mui/material';

const CalloutBox = ({ children, icon, padding, textAlign, title, type }) => {
  const nrTheme = useTheme();

  let borderColor;
  let backgroundColor;
  let iconColor;
  let textColor;
  switch (type) {
    case 'info':
      borderColor = nrTheme.palette.brand.techBlue;
      backgroundColor = lighten(nrTheme.palette.brand.techBlue, 0.88);
      iconColor = darken(nrTheme.palette.brand.techBlue, 0.4);
      textColor = darken(nrTheme.palette.brand.techBlue, 0.4);
      break;
    case 'success':
      borderColor = nrTheme.palette.brand.green;
      backgroundColor = lighten(nrTheme.palette.brand.green, 0.88);
      iconColor = darken(nrTheme.palette.brand.green, 0.5);
      textColor = darken(nrTheme.palette.brand.green, 0.5);
      break;
    case 'warning':
      borderColor = nrTheme.palette.warning.main;
      backgroundColor = lighten(nrTheme.palette.warning.main, 0.88);
      iconColor = darken(nrTheme.palette.warning.main, 0.2);
      textColor = darken(nrTheme.palette.warning.main, 0.2);
      break;
    case 'error':
      borderColor = darken(nrTheme.palette.error.main, 0.2);
      backgroundColor = lighten(nrTheme.palette.error.main, 0.91);
      iconColor = darken(nrTheme.palette.error.main, 0.2);
      textColor = darken(nrTheme.palette.error.main, 0.2);
      break;
    default:
      break;
  }

  return (
    <Box
      p={padding}
      sx={{
        textAlign,
        border: '1px solid',
        borderRadius: 2,
        borderColor,
        backgroundColor,
        '& .MuiAlert-icon': {
          color: iconColor,
        },
      }}
    >
      {icon || title ? (
        <Stack direction="row" spacing={1} alignItems="center" pb={1} sx={{ color: textColor }}>
          {icon}
          {title ? <AlertTitle sx={{ fontSize: '16px' }}>{title}</AlertTitle> : null}
        </Stack>
      ) : null}
      <Box sx={{ color: textColor }}>{children}</Box>
    </Box>
  );
};

CalloutBox.defaultProps = {
  icon: null,
  padding: 2,
  textAlign: 'center',
  title: null,
};

CalloutBox.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  padding: PropTypes.number,
  textAlign: PropTypes.oneOf(['center', 'left', 'right']),
  title: PropTypes.string,
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
};

export default CalloutBox;
