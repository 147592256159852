import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, TableCell } from '@mui/material';
import ColumnSortLabel from '../../ColumnSortLabel';

const HeaderCell = ({ column, columnIndex, rowSelectionCheckboxesDisabled }) => {
  let justifyContent;
  switch (column.headerTextAlign) {
    case 'right':
      justifyContent = 'flex-end';
      break;
    case 'center':
      justifyContent = 'center';
      break;
    default:
      justifyContent = 'flex-start';
      break;
  }

  const headerCursor = column.canSort ? 'pointer' : 'inherit';

  return (
    <TableCell
      key={column.id}
      {...(column.canSort
        ? column.getHeaderProps(column.getSortByToggleProps({ title: null }))
        : column.getHeaderProps())}
      sx={{
        padding: rowSelectionCheckboxesDisabled && columnIndex === 0 ? '6px 10px 6px 18px' : '6px 10px',
        fontSize: '0.875rem',
      }}
    >
      <Stack direction="row" spacing={1} justifyContent={justifyContent} alignItems="center">
        <Box sx={{ display: 'inline-block', whiteSpace: 'nowrap' }}>{column.render('Header')}</Box>
        {column.canSort ? (
          <ColumnSortLabel
            isSorted={column.isSorted}
            isSortedDesc={column.isSortedDesc}
            sx={{ cursor: headerCursor }}
          />
        ) : null}
      </Stack>
    </TableCell>
  );
};

HeaderCell.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
    canSort: PropTypes.bool.isRequired,
    isSorted: PropTypes.bool.isRequired,
    isSortedDesc: PropTypes.bool,
    render: PropTypes.func.isRequired,
    getHeaderProps: PropTypes.func.isRequired,
    getSortByToggleProps: PropTypes.func,
    headerTextAlign: PropTypes.string,
  }).isRequired,
  columnIndex: PropTypes.number.isRequired,
  rowSelectionCheckboxesDisabled: PropTypes.bool.isRequired,
};

export default HeaderCell;
