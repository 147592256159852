import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Divider, Stack } from '@mui/material';
import { OndemandVideo } from '@mui/icons-material';
import { LoadingErrorFallback } from '@/components/ErrorFallback';
import { Heading } from '@/components/core';
import { FormatDateTime } from '@/components/utilities/DateTime';
import DataLoading from '@/components/DataLoading';
import { TransitionFadeInScale } from '@/components/Transitions';
import { LoginPageSideBarNotificationsQuery } from 'goldilocks/graphql/notifications/queries.graphql';
import VideoDialog from '@/components/Help/VideoDialog';
import NrButton from '@/components/NrMaterialOverrides/NrButton';

const LoginPageMessages = () => {
  const { data, loading, error } = useQuery(LoginPageSideBarNotificationsQuery);

  if (loading) {
    return <DataLoading />;
  }

  if (error) {
    return <LoadingErrorFallback error={error} />;
  }

  const messages = data.goldilocksLoginPageSidebarNotifications.data.map((notification) => {
    const notificationContent = JSON.parse(notification.json);

    let videoEmbed;
    if (notificationContent?.videoTitle && notificationContent?.videoEmbedUrl) {
      videoEmbed = (
        <VideoDialog
          videoEmbedUrl={notificationContent.videoEmbedUrl}
          title={notificationContent.videoTitle}
          trigger={
            <NrButton size="large" startIcon={<OndemandVideo />}>
              {notificationContent.videoTitle}
            </NrButton>
          }
        />
      );
    }

    return (
      <Box key={notification.id}>
        <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="space-between">
          <Heading label={notificationContent.headline} paddingBottom={0} paddingLeft={0} />
          <Box sx={{ textAlign: 'right' }}>
            <FormatDateTime date={notification.publish_at} breakLines />
          </Box>
        </Stack>
        <Box sx={{ fontSize: '1.1em' }} dangerouslySetInnerHTML={{ __html: notificationContent.message }} />
        {videoEmbed}
      </Box>
    );
  });

  return (
    <TransitionFadeInScale>
      <Stack
        direction="column"
        spacing={2}
        py={3}
        px={4}
        divider={<Divider flexItem sx={{ backgroundColor: (theme) => theme.palette.background.default }} />}
        sx={{ height: '100vh', overflow: 'scroll', color: (theme) => theme.palette.background.paper }}
      >
        {messages}
      </Stack>
    </TransitionFadeInScale>
  );
};

export default LoginPageMessages;
