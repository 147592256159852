import React from 'react';
import moment from 'moment';
// @ts-ignore
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Typography,
} from '@mui/material';
import { CalloutBox } from '@/components/core';
import { InfoPopover } from '@/components/Help';
import DateRangeButton from '../DateRangeButton';

const CustomRangeShortcuts = ({
  currentDateRange,
  onChange,
  restrictToThirtyDays,
}: {
  currentDateRange: any;
  onChange: (value: any, changeImportance: string, item: any) => void;
  restrictToThirtyDays: boolean;
}) => {
  const { location } = window;

  const openCreateViewDrawer = () => {
    // Trickery here: We want to open the "Create View" drawer, but we need the DateRangePicker to close as well.
    // There does not seem to be a method to close the DateRangePicker. At least I can't find one.
    // But calling the DateRangePicker's onChange does close the picker.
    // So here we call that onChange, passing as args the current values of the DateRangePicker.
    // The DateRangePicker closes without changing any values ;)
    onChange([currentDateRange[0], currentDateRange[1]], 'accept', currentDateRange);
  };

  return (
    <Box
      sx={{
        gridColumn: 1,
        gridRowStart: 2,
        gridRowEnd: 2,
        pt: 3,
        // pl: 2,
      }}
    >
      <TableContainer
        sx={{
          overflowX: 'auto',
          width: '100%',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Table sx={{ borderCollapse: 'separate', borderSpacing: 0, marginTop: restrictToThirtyDays ? 0 : 0 }}>
          {restrictToThirtyDays ? (
            <TableBody>
              <TableRow>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange([moment().startOf('day'), moment().endOf('day')], 'accept', { label: 'Today' });
                    }}
                  >
                    Today
                  </DateRangeButton>
                </TableCell>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange(
                        [moment().subtract(1, 'week').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                        'accept',
                        { label: 'Last 7 Days' }
                      );
                    }}
                  >
                    Last 7 Days
                  </DateRangeButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange(
                        [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                        'accept',
                        { label: 'Yesterday' }
                      );
                    }}
                  >
                    Yesterday
                  </DateRangeButton>
                </TableCell>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange([moment().startOf('month'), moment().endOf('day')], 'accept', { label: 'This Month' });
                    }}
                  >
                    This Month
                  </DateRangeButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange([moment().startOf('week'), moment().endOf('day')], 'accept', { label: 'This Week' });
                    }}
                  >
                    This Week
                  </DateRangeButton>
                </TableCell>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange(
                        [
                          moment().subtract(1, 'months').startOf('month'),
                          moment().subtract(1, 'months').endOf('month'),
                        ],
                        'accept',
                        { label: 'Last Month' }
                      );
                    }}
                  >
                    Last Month
                  </DateRangeButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange(
                        [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                        'accept',
                        { label: 'Last Week' }
                      );
                    }}
                  >
                    Last Week
                  </DateRangeButton>
                </TableCell>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange(
                        [moment().subtract(30, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                        'accept',
                        { label: 'Last 30 Days' }
                      );
                    }}
                  >
                    Last 30 Days
                  </DateRangeButton>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange([moment().startOf('day'), moment().endOf('day')], 'accept', { label: 'Today' });
                    }}
                  >
                    Today
                  </DateRangeButton>
                </TableCell>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange(
                        [moment().subtract(30, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                        'accept',
                        { label: 'Last 30 Days' }
                      );
                    }}
                  >
                    Last 30 Days
                  </DateRangeButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange(
                        [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                        'accept',
                        { label: 'Yesterday' }
                      );
                    }}
                  >
                    Yesterday
                  </DateRangeButton>
                </TableCell>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange([moment().startOf('quarter'), moment().endOf('day')], 'accept', {
                        label: 'This Quarter',
                      });
                    }}
                  >
                    This Quarter
                  </DateRangeButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange([moment().startOf('week'), moment().endOf('day')], 'accept', { label: 'This Week' });
                    }}
                  >
                    This Week
                  </DateRangeButton>
                </TableCell>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange(
                        [
                          moment().subtract(1, 'quarter').startOf('quarter'),
                          moment().subtract(1, 'quarter').endOf('quarter'),
                        ],
                        'accept',
                        { label: 'Last Quarter' }
                      );
                    }}
                  >
                    Last Quarter
                  </DateRangeButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange(
                        [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                        'accept',
                        { label: 'Last Week' }
                      );
                    }}
                  >
                    Last Week
                  </DateRangeButton>
                </TableCell>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange(
                        [moment().subtract(90, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                        'accept',
                        { label: 'Last 90 Days' }
                      );
                    }}
                  >
                    Last 90 Days
                  </DateRangeButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange(
                        [moment().subtract(1, 'week').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                        'accept',
                        { label: 'Last 7 Days' }
                      );
                    }}
                  >
                    Last 7 Days
                  </DateRangeButton>
                </TableCell>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange([moment().startOf('year'), moment().endOf('day')], 'accept', { label: 'This Year' });
                    }}
                  >
                    This Year
                  </DateRangeButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange([moment().startOf('month'), moment().endOf('day')], 'accept', { label: 'This Month' });
                    }}
                  >
                    This Month
                  </DateRangeButton>
                </TableCell>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange(
                        [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
                        'accept',
                        { label: 'Last Year' }
                      );
                    }}
                  >
                    Last Year
                  </DateRangeButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange(
                        [
                          moment().subtract(1, 'months').startOf('month'),
                          moment().subtract(1, 'months').endOf('month'),
                        ],
                        'accept',
                        { label: 'Last Month' }
                      );
                    }}
                  >
                    Last Month
                  </DateRangeButton>
                </TableCell>
                <TableCell size="small" sx={{ fontSize: '0.875rem', borderBottom: 'none' }}>
                  <DateRangeButton
                    onClick={() => {
                      onChange(
                        [moment().subtract(365, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                        'accept',
                        { label: 'Last 365 Days' }
                      );
                    }}
                  >
                    Last 365 Days
                  </DateRangeButton>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {restrictToThirtyDays ? (
        <Box mt={1} px={2}>
          {/* @ts-ignore */}
          <Card
            variant="outlined"
            sx={{ position: 'relative', maxWidth: 242, backgroundColor: (theme) => theme.palette.background.default }}
          >
            <CardHeader
              title="Customize!"
              action={
                <InfoPopover
                  content={
                    <CalloutBox type="info" title="Customize This Dashboard" textAlign="left">
                      <Typography>
                        To ensure this dashboard loads quickly, its &quot;default view&quot; is restricted to show a
                        maximum of 31 days of data.
                        <br />
                        <br />
                        To use a longer date range, customize your dashboard by creating a View!
                      </Typography>
                      <Typography variant="h6" pt={2}>
                        Introducing Views
                      </Typography>
                      <Typography>
                        Views provide the customizable dashboard experience you&apos;ve been looking for!
                        <br />
                        <br />
                        Views allow you to:
                        <ul>
                          <li>
                            <strong>Choose the fields</strong> you&apos;d like included in your dashboard
                          </li>
                          <li>
                            <strong>Filter your dashboard</strong> with any conditions you like
                          </li>
                          <li>Elect to have your View&apos;s data updated automatically</li>
                        </ul>
                      </Typography>
                      <Link to={`${location.pathname}/view/createEdit`} onClick={openCreateViewDrawer}>
                        <strong>Create a View!</strong>
                      </Link>
                    </CalloutBox>
                  }
                />
              }
              sx={{ pt: 1, pb: 0 }}
            />
            <CardContent
              sx={{
                pt: 1,
                textAlign: 'left',
                ':last-child': {
                  pb: 2,
                },
              }}
            >
              Want to see more than 30 days of data? <br />
              <Link to={`${location.pathname}/view/createEdit`} onClick={openCreateViewDrawer}>
                <strong>Customize this Dashboard!</strong>
              </Link>
            </CardContent>
          </Card>
        </Box>
      ) : null}
    </Box>
  );
};

export default CustomRangeShortcuts;
