import React from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useCurrentUser } from '@/context/UserContext';
import TopNavItem from '@/components/Navigation/TopNav/TopNavItem';
import PidLock from '@/components/PidLock';
import { iconMappings } from '../../constants';
import NrMenuItem from '../NrMenuItem';
import CascadingMenu from '../../CascadingMenu';
import CascadingSubmenu from '../../CascadingSubmenu';

const NavReporting = () => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'navMarketingCenter' });
  const { currentUser } = useCurrentUser();

  return (
    <>
      <TopNavItem label="Reporting" popupState={popupState} />

      <CascadingMenu
        popupState={popupState}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <NrMenuItem
          menuItemText="Alerts"
          menuItemIcon={iconMappings.announcement}
          menuItemUrl="/reporting/reports/alerts"
          createNewUrl="/reporting/reports/alerts/createEdit"
        />
        <NrMenuItem
          menuItemText="Reports"
          menuItemIcon={iconMappings.subtitles}
          menuItemUrl="/reporting/reports/scheduled-reports"
          createNewUrl="/reporting/reports/createEdit"
        />
        <NrMenuItem
          menuItemText="Download Center"
          menuItemIcon={iconMappings.cloudDownload}
          menuItemUrl="/reporting/reports/download-center"
        />
        <CascadingSubmenu
          popupId="dashboards"
          title="Dashboards"
          icon={iconMappings.equalizer}
          menuItemUrl="/reporting/dashboards"
        >
          <PidLock>
            <NrMenuItem
              menuItemText="Analytics"
              menuItemIcon={iconMappings.analytics}
              menuItemUrl="/reporting/dashboards/analytics"
              isLabFeature
            />
          </PidLock>
          <NrMenuItem
            menuItemText="Attribution"
            menuItemIcon={iconMappings.attribution}
            menuItemUrl="/reporting/dashboards/attribution"
            isLabFeature
          />
          <NrMenuItem
            menuItemText="Automation Comparison"
            menuItemIcon={iconMappings.compare}
            menuItemUrl="/reporting/dashboards/automation-comparison"
          />
          <NrMenuItem
            menuItemText="Automation Overview"
            menuItemIcon={iconMappings.article}
            menuItemUrl="/reporting/dashboards/automation-overview"
          />
          <NrMenuItem
            menuItemText="Automation Performance"
            menuItemIcon={iconMappings.timeline}
            menuItemUrl="/reporting/dashboards/automation-performance"
          />
          <NrMenuItem
            menuItemText="Bounce Reasons"
            menuItemIcon={iconMappings.cancelScheduleSend}
            menuItemUrl="/reporting/dashboards/bounce-reasons"
          />
          <NrMenuItem
            menuItemText="Contacts"
            menuItemIcon={iconMappings.group}
            menuItemUrl="/reporting/dashboards/contacts"
          />
          <NrMenuItem
            menuItemText="Conversions"
            menuItemIcon={iconMappings.sync}
            menuItemUrl="/reporting/dashboards/conversionsiframe"
          />
          <NrMenuItem
            menuItemText="Downloads"
            menuItemIcon={iconMappings.cloudDownload}
            menuItemUrl="/reporting/dashboards/downloads"
          />
          {/* TODO Change all /reporting/dashboards/new-downloads to /downloads when replacing this with the old dashboard */}
          <PidLock additionalPIDs={[13864, 19312, 25910]}>
            <NrMenuItem
              menuItemText="Downloads"
              menuItemIcon={iconMappings.cloudDownload}
              menuItemUrl="/reporting/dashboards/new-downloads"
              isLabFeature
            />
          </PidLock>
          <NrMenuItem
            menuItemText="Email Clients"
            menuItemIcon={iconMappings.devicesOther}
            menuItemUrl="/reporting/dashboards/email-clients"
          />
          <NrMenuItem
            menuItemText="Email Engagement"
            menuItemIcon={iconMappings.email}
            menuItemUrl="/reporting/dashboards/email-engagement"
          />
          <NrMenuItem
            menuItemText="Email Performance"
            menuItemIcon={iconMappings.timeline}
            menuItemUrl="/marketing-center/emails/dashboard"
          />
          <PidLock additionalPIDs={[13864, 19312, 25910]}>
            <NrMenuItem
              menuItemText="Form Submissions"
              menuItemIcon={iconMappings.sync}
              menuItemUrl="/reporting/dashboards/form-submissions"
              isLabFeature
            />
          </PidLock>
          {/* Restrict to those with SF integration for now */}
          {Number(currentUser.product?.salesforce_integration?.id) ? (
            <NrMenuItem
              menuItemText="Marketing Contribution"
              menuItemIcon={iconMappings.campaign}
              menuItemUrl="/reporting/dashboards/marketing-contribution"
              isLabFeature
            />
          ) : null}
          <NrMenuItem
            menuItemText="Marketing Lists"
            menuItemIcon={iconMappings.listIcon}
            menuItemUrl="/marketing-list/dashboard"
          />
          <NrMenuItem
            menuItemText="One-Off Emails"
            menuItemIcon={iconMappings.contactMail}
            menuItemUrl="/reporting/dashboards/one-off-emails"
          />
          <NrMenuItem
            menuItemText="Social Page Overview"
            menuItemIcon={iconMappings.equalizer}
            menuItemUrl="/reporting/dashboards/social-page-overview"
            featureSlug="SocialPostMultiplePlatforms"
          />
          <NrMenuItem
            menuItemText="Subscription Lists"
            menuItemIcon={iconMappings.playlistAddCheck}
            menuItemUrl="/subscription-list/dashboard"
            featureSlug="SubscriptionListManagement"
          />
          <NrMenuItem menuItemText="The Pulse" menuItemIcon={iconMappings.home} menuItemUrl="/home" />
          <NrMenuItem
            menuItemText="Popular Web Pages"
            menuItemIcon={iconMappings.web}
            menuItemUrl="/reporting/dashboards/pages"
          />
          <NrMenuItem
            menuItemText="Traffic Sources"
            menuItemIcon={iconMappings.share}
            menuItemUrl="/reporting/dashboards/traffic-sources"
          />
          <NrMenuItem
            menuItemText="Webinars"
            menuItemIcon={iconMappings.cast}
            menuItemUrl="/reporting/dashboards/webinar"
          />
          <NrMenuItem
            menuItemText="Website Visitors"
            menuItemIcon={iconMappings.people}
            menuItemUrl="/reporting/dashboards/website-visitors"
          />
        </CascadingSubmenu>
      </CascadingMenu>
    </>
  );
};

export default NavReporting;
