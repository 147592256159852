import React, { useEffect, useCallback, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useAuth } from '@/context/AuthContext';
import { setLocalStorageWithExpiry } from '@/utilities/localstorage';
import { useCurrentUser } from '@/context/UserContext';
import { MeQuery } from 'goldilocks/graphql/user/queries.graphql';

const SingleSignOn = () => {
  const client = useApolloClient();
  const location = useLocation();
  const history = useHistory();
  const { reload } = useAuth();
  const { setCurrentUser } = useCurrentUser();
  const [hasNotRanQuery, setHasNotRanQuery] = useState(true);
  const queryParams = queryString.parse(location.search, {
    parseNumbers: true,
  });
  const { token } = queryParams;

  const getCurrentUser = useCallback(async () => {
    return client.query({
      query: MeQuery,
    });
  }, [client]);

  useEffect(() => {
    // set token in auth so we can fetch current user from gql
    const ttl = 2 * 60 * 60 * 1000;
    setLocalStorageWithExpiry(import.meta.env.VITE_APP_LOCALSTORAGE_AUTH_KEY, token, ttl);

    if (hasNotRanQuery) {
      setHasNotRanQuery(false);
      getCurrentUser().then((result) => {
        // here we reset the token with the product expiration
        const productSetTtl = result.data.me.product.session_duration * 60 * 60 * 1000;
        setLocalStorageWithExpiry(import.meta.env.VITE_APP_LOCALSTORAGE_AUTH_KEY, token, productSetTtl);
        // Store our currentUser in local storage. We'll load our currentUser from there if the app is refreshed.
        setLocalStorageWithExpiry(import.meta.env.VITE_APP_LOCALSTORAGE_USER_KEY, result.data.me, productSetTtl);
        // Store our loginUser as currentUser in UserContext.
        setCurrentUser(result.data.me);
        reload();
        history.push('/');
      });
    }
  }, [token, hasNotRanQuery, setHasNotRanQuery, getCurrentUser, history, reload, setCurrentUser]);

  return (
    <LinearProgress
      sx={{
        mt: '10%',
        width: '60%',
        mr: 'auto',
        ml: 'auto',
      }}
      data-testid="loadingProgress"
    />
  );
};

export default SingleSignOn;
