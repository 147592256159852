import gql from 'graphql-tag';

/**
 * A GraphQL query is simply a string of text (that the gql() function accepts and returns an "AST" which is what gets sent to the server).
 * Because each GQL query is just a string, we can build that string up programmatically through concatenation. That's what this function does for us.
 *
 * Do not include the ID field in either of arrays this function accepts. This function assumes we always need it and adds it for you.
 *
 * fieldsToUpdate must be an array of objects in this format: [{ fieldName: 'time_format', type: 'String' }]
 * fieldsToReturn must be an array of field names in this format: ['time_format', 'date_format'].
 * Todo: improve this so we can return complex types (nested objects)?
 * Todo: will we need WHERE constraints on here?
 *
 * @param {String} gqlMutationName
 * @param {Array.<Object>} fieldsToUpdate
 * @param {Array<String>} fieldsToReturn
 * @param {Boolean} mock
 */
export const dynamicMutation = (gqlMutationName, fieldsToUpdate, fieldsToReturn, mock = false) => {
  let columnsToReturn = 'id';
  let columnsToUpdate = '';
  let inputVariables = '';
  const mutationAlias = `DynamicMutation${gqlMutationName.charAt(0).toUpperCase() + gqlMutationName.slice(1)}`;

  fieldsToUpdate.forEach((fieldParameters) => {
    columnsToUpdate += `
        ${fieldParameters.fieldName}: $${fieldParameters.fieldName}`;

    inputVariables += `, $${fieldParameters.fieldName}: ${fieldParameters.type}`;
  });

  fieldsToReturn.forEach((column) => {
    columnsToReturn += `
      ${column}`;
  });

  const mutationBody = `mutation ${mutationAlias} ($id: ID!${inputVariables}) {
    ${gqlMutationName}(input: {
      id: $id,
      patch: {${columnsToUpdate}
      }
    }) {${columnsToReturn}
    }
  }`;

  // Used for testing purposes. This will return the concatenated text of the mutation without having compiled it to AST with gql().
  if (mock === true) {
    return mutationBody;
  }

  return gql`
    ${mutationBody}
  `;
};

/**
 * This "dynamicWhereConditions" function uses string concatenation to assemble the Where clause for a graphql query based on the arguments passed to it.
 * It is safe to call/use even if circumstances cause it to be called with null values for all arguments.
 *
 * This function starts with an empty "WHERE" clause and adds "AND" statements it for each non-null argument it receives.
 *
 * Refer to https://lighthouse-php.com/4.12/eloquent/complex-where-conditions.html#usage for proper structure for AND/OR if/when we want to expand this function.
 *
 * We may expand the number of arguments this function accepts to eventually accommodate all filtering conditions available across all data grids (i.e. Campaign Status).
 * It may make sense to have this function accept an argument and then do its work based on the props (to avoid having to pass null values for un-needed arguments in the function call).
 *
 * @param {string} searchColumn The name of the column in the "SearchByColumns" enum in the .graphql file
 * @param {string} searchString
 * @param {number} folderId
 * @param {string} archived
 * @param {number} campaignStatus
 * @returns {string} whereConditions
 */
export const dynamicWhereConditions = (searchColumn, searchString, folderId, archived, campaignStatus) => {
  // todo: change this function definition such that it accepts an object rather than the longish list of strings
  let whereConditions = 'null'; // Lighthouse docs show this as a safe way to pass an empty where clause: "Using null as argument value does not have any effect on the query."
  let ands = ''; // We'll concatenate onto this empty string if any non-null conditions were passed to this function.

  if (searchString) {
    ands += `{ column: ${searchColumn}, operator: LIKE, value: "${searchString}" }`;
  }

  if (folderId) {
    ands += `{ column: FOLDER_ID, operator: EQ, value: ${folderId} }`;
  }

  switch (archived) {
    case 'hideArchived':
      ands += `{ column: ARCHIVED, operator: EQ, value: false }`;
      break;
    case 'showArchived':
      ands += `{ column: ARCHIVED, operator: EQ, value: true }`;
      break;
    default:
      break;
  }

  switch (campaignStatus) {
    case 'draft':
      ands += `{ column: STATUS, operator: EQ, value: 1 }`;
      break;
    case 'launched':
      ands += `{ column: STATUS, operator: EQ, value: 2 }`;
      break;
    case 'retired':
      ands += `{ column: STATUS, operator: EQ, value: 3 }`;
      break;
    default:
      break;
  }

  // If non-null conditions were passed to the function, this will no longer be an empty string. Overwrite the initial whereConditions variable adding our ands in.
  if (ands !== '') {
    whereConditions = `{ AND: [${ands}]}`;
  }

  return whereConditions;
};

export const dynamicWhereConditionsObject = (
  searchColumn,
  searchString,
  folderId,
  archived,
  campaignStatus,
  campaignType
) => {
  let whereConditions = null;
  const ands = [];

  if (searchString && searchString !== '') {
    ands.push({
      column: searchColumn,
      operator: 'LIKE',
      value: `%${searchString}%`,
    });
  }

  // TODO: should this be folder?
  if (folderId) {
    ands.push({
      column: 'FOLDER_ID',
      operator: 'EQ',
      value: folderId,
    });
  }

  switch (archived) {
    case 'hideArchived':
      ands.push({
        column: 'ARCHIVED',
        operator: 'EQ',
        value: false,
      });
      break;
    case 'showArchived':
      ands.push({
        column: 'ARCHIVED',
        operator: 'EQ',
        value: true,
      });
      break;
    default:
      break;
  }

  switch (campaignStatus) {
    case 'not-draft':
      ands.push({
        column: 'STATUS',
        operator: 'NEQ',
        value: 1,
      });
      break;
    case 'draft':
      ands.push({
        column: 'STATUS',
        operator: 'EQ',
        value: 1,
      });
      break;
    case 'launched':
      ands.push({
        column: 'STATUS',
        operator: 'EQ',
        value: 2,
      });
      break;
    case 'retired':
      ands.push({
        column: 'STATUS',
        operator: 'EQ',
        value: 3,
      });
      break;
    case 'not-retired':
      ands.push({
        column: 'STATUS',
        operator: 'NEQ',
        value: 3,
      });
      break;
    default:
      break;
  }

  switch (campaignType) {
    case 'workflow':
      ands.push({
        column: 'TYPE',
        operator: 'EQ',
        value: 1,
      });
      break;
    case 'drip':
      ands.push({
        column: 'TYPE',
        operator: 'EQ',
        value: 2,
      });
      break;
    case 'ab_test':
      ands.push({
        column: 'TYPE',
        operator: 'EQ',
        value: 3,
      });
      break;
    default:
      break;
  }

  if (ands.length !== 0) {
    whereConditions = { AND: ands };
  } else {
    whereConditions = {};
  }

  return whereConditions;
};

export const dynamicWhereConditionsFromFilters = (searchColumn, searchString, filters = []) => {
  let whereConditions = null;
  const ands = [];

  if (searchString && searchString !== '') {
    ands.push({
      column: searchColumn,
      operator: 'LIKE',
      value: `%${searchString}%`,
    });
  }

  filters.forEach((filter) => {
    // null would be nice but autocompletes with a null value dont show a label then
    if (filter.value !== 'skip') {
      if (Array.isArray(filter.value)) {
        filter.value.forEach((filterFromArray) => {
          ands.push(filterFromArray);
        });
      } else if (typeof filter.value === 'object') {
        ands.push(filter.value);
      } else {
        ands.push({
          column: filter.id.toUpperCase(),
          operator: 'EQ',
          value: filter.value,
        });
      }
    }
  });

  if (ands.length !== 0) {
    whereConditions = { AND: ands };
  } else {
    whereConditions = {};
  }

  return whereConditions;
};

// TODO: handle number type
const getOperator = (filter) => {
  let operator = '';
  switch (filter.operator) {
    case 'equals':
      operator = 'EQ';
      break;
    case 'contains':
      operator = 'LIKE';
      break;
    case 'startsWith':
      operator = 'LIKE';
      break;
    case 'endsWith':
      operator = 'LIKE';
      break;
    case 'is':
    case 'isEmpty':
      operator = 'EQ';
      break;
    case 'isNotEmpty':
      operator = 'NEQ';
      break;
    default:
  }
  return operator;
};

const getValue = (filter) => {
  let { value } = filter;
  switch (filter.operator) {
    case 'contains':
      value = `%${filter.value}%`;
      break;
    case 'startsWith':
      value = `${filter.value}%`;
      break;
    case 'endsWith':
      value = `%${filter.value}`;
      break;
    case 'is':
      value = filter.value === 'true';
      break;
    case 'isEmpty':
    case 'isNotEmpty':
      value = '';
      break;
    default:
  }
  return value;
};

export const dynamicWhereConditionsFromFiltersDataGrid = (filters = [], quickFilterColumns = []) => {
  let whereConditions = null;
  const conditions = [];
  const quickFilterConditions = [];

  filters.items.forEach((filter) => {
    const undefinedValueIsAllowedOperators = ['isEmpty', 'isNotEmpty'];
    if ((filter.value || undefinedValueIsAllowedOperators.includes(filter?.operator)) && filter.value !== '') {
      conditions.push({
        column: filter.field.toUpperCase(),
        operator: getOperator(filter),
        value: getValue(filter),
      });
    }
  });

  filters.quickFilterValues?.forEach((quickFilter) => {
    quickFilterColumns.forEach((quickFilterColumn) => {
      quickFilterConditions.push({
        column: quickFilterColumn.toUpperCase(),
        operator: 'LIKE',
        value: `%${quickFilter}%`,
      });
    });
  });

  if (conditions.length !== 0) {
    if (filters.logicOperator === 'and') {
      whereConditions = { AND: conditions };
    } else {
      whereConditions = { OR: conditions };
    }
  } else {
    whereConditions = {};
  }

  if (quickFilterConditions.length !== 0) {
    if (whereConditions.AND) {
      whereConditions.AND.push({ OR: quickFilterConditions });
    }
    if (whereConditions.OR) {
      whereConditions.OR.push(...quickFilterConditions);
    }
    if (Object.keys(whereConditions).length === 0) {
      whereConditions = { AND: [{ OR: quickFilterConditions }] };
    }
  }

  return whereConditions;
};

export default dynamicMutation;
