import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Box, FormHelperText } from '@mui/material';

// this doesnt spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormFolderSelect = ({ folderButtonRender, name }) => {
  return (
    <Controller
      render={({ field: { onChange }, fieldState: { invalid, error } }) => {
        return (
          <Box>
            <div>{folderButtonRender({ onChange })}</div>
            {error && invalid ? (
              <FormHelperText error variant="outlined">
                {error.message}
              </FormHelperText>
            ) : null}
          </Box>
        );
      }}
      name={name}
    />
  );
};

HookFormFolderSelect.propTypes = {
  folderButtonRender: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default HookFormFolderSelect;
