const stringUtilities = {
  capitalizeFirstLetter: (string: string): string => {
    const lowerString: string = string.toLowerCase();
    return lowerString.charAt(0).toUpperCase() + lowerString.slice(1);
  },

  // Capitalizes first letters of words in string.
  capitalizeWords: (string: string, lower: boolean = false): string => {
    return (lower ? string.toLowerCase() : string).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
  },

  splitCamelCaseString: (string: string): string => {
    return string.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  },

  createCapWordsString: (unFormattedString: string): string => {
    return stringUtilities.capitalizeWords(
      stringUtilities.splitCamelCaseString(unFormattedString.replaceAll('-', ' ').replaceAll('_', ' '))
    );
  },

  formatEnumLabel: (unFormattedString: string): string => {
    return unFormattedString
      .split('_')
      .map((word) => stringUtilities.capitalizeFirstLetter(word))
      .join(' ');
  },
};

export default stringUtilities;
