import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { FormatDate } from '@/components/utilities/DateTime';

const DateCell = ({ date }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end" height="100%">
      <Typography>
        <FormatDate date={date} />
      </Typography>
    </Box>
  );
};
DateCell.propTypes = {
  date: PropTypes.node,
};

DateCell.defaultProps = {
  date: '',
};

export default DateCell;
