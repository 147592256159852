import React, { Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import { lazyWithRetry as lazy } from '@/utilities/lazyWithRetry';
import MakeRouteWithSubRoutes from './makeRouteWithSubRoutes';

const LenovoContact = lazy(
  () =>
    import(
      /* webpackChunkName: "LenovoContact", webpackPrefetch: true */ 'goldilocks/pages/Dashboards/Lenovo/components/ContactDetailsLenovo'
    )
);

const LenovoDashboard = lazy(
  () => import(/* webpackChunkName: "LenovoDashboard", webpackPrefetch: true */ './pages/Dashboards/Lenovo/index')
);

const NoMatch = lazy(() => import(/* webpackChunkName: "NoMatch", webpackPrefetch: true */ './pages/NoMatch'));

const routes = [
  {
    path: '/',
    exact: true,
    redirect: <Redirect to="/lenovo/reporting/dashboard" />,
  },
  {
    path: '/home',
    exact: true,
    redirect: <Redirect to="/lenovo/reporting/dashboard" />,
  },
  {
    path: '/lenovo/contact',
    component: LenovoContact,
  },
  {
    path: '/lenovo/reporting/dashboard',
    component: LenovoDashboard,
  },
  {
    path: '/:NotFound',
    component: NoMatch,
  },
];

const LenovoRoutes = () => {
  const location = useLocation();

  return (
    <Suspense fallback={<div />}>
      <Switch location={location}>
        {routes.map((route) => (
          <MakeRouteWithSubRoutes key={route.path} {...route} />
        ))}
      </Switch>
    </Suspense>
  );
};

export default LenovoRoutes;
