import gql from 'graphql-tag';

export const QueryCustomObjectDefinitions = gql`
  query QueryCustomObjectDefinitions(
    $limit: Int!
    $page: Int!
    $sortField: CustomObjectDefinitionOrderByColumns!
    $sortDirection: SortOrder!
    $dynamicConditions: QueryCustomObjectDefinitionsWhereWhereConditions!
  ) {
    customObjectDefinitions(
      orderBy: [{ column: $sortField, order: $sortDirection }]
      where: $dynamicConditions
      first: $limit
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        singular_label
        plural_label
        created_at
        updated_at
      }
    }
  }
`;

export const CustomObjectDefinitionsForAutocompleteQuery = gql`
  query CustomObjectDefinitionsForAutocompleteQuery(
    $limit: Int!
    $page: Int!
    $sortField: CustomObjectDefinitionOrderByColumns!
    $sortDirection: SortOrder!
  ) {
    customObjectDefinitions(orderBy: [{ column: $sortField, order: $sortDirection }], first: $limit, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        label: singular_label
        value: id
      }
    }
  }
`;

export const CustomObjectDefinitionQuery = gql`
  query CustomObjectDefinitionQuery($id: ID!) {
    customObjectDefinition(id: $id) {
      singular_label
      plural_label
      name
      fields {
        id
        name
        type
        label
        read_only
        is_custom
        is_required
        lookup_object_id
        relationship_id
        drop_down_id
        created_at
        updated_at
      }
    }
  }
`;

export const CustomObjectsForSegmentBuilderQuery = gql`
  query CustomObjectsForSegmentBuilderQuery {
    customObjectsForSegmentBuilder {
      definition_id
      name
      singular_label
      plural_label
      relationship_id
    }
  }
`;

export const CustomObjectListLayoutQuery = gql`
  query CustomObjectRecordLayoutQuery($id: ID!) {
    customObjectDefinition(id: $id) {
      json
    }
  }
`;

export const CustomObjectRecordLayoutQuery = gql`
  query CustomObjectRecordLayoutQuery($id: ID!) {
    customObjectDefinition(id: $id) {
      json
    }
  }
`;

export const MutationDeleteCustomObjectDefinition = gql`
  mutation MutationDeleteCustomObjectDefinition($id: ID!) {
    deleteCustomObjectDefinition(id: $id) {
      id
      name
      created_at
      updated_at
    }
  }
`;

export const CustomObjectFieldsQuery = gql`
  query QueryCustomObjectFields($id: ID!) {
    customObjectDefinition(id: $id) {
      fields {
        id
        name
        type
        label
        read_only
        is_custom
        is_required
        lookup_object_id
        relationship_id
        drop_down_id
      }
    }
  }
`;

export const CreateCustomObjectFieldMutation = gql`
  mutation CreateCustomObjectFieldMutation($input: CreateCustomObjectFieldInput!) {
    createCustomObjectField(input: $input) {
      id
    }
  }
`;

export const UpdateCustomObjectFieldMutation = gql`
  mutation UpdateCustomObjectFieldMutation($id: ID!, $patch: PatchableCustomObjectFields!) {
    updateCustomObjectField(input: { id: $id, patch: $patch }) {
      id
    }
  }
`;

export const DeleteCustomObjectFieldMutation = gql`
  mutation DeleteCustomObjectFieldMutation($id: ID!) {
    deleteCustomObjectField(id: $id) {
      id
    }
  }
`;

export default QueryCustomObjectDefinitions;
