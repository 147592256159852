import { createTheme, darken, lighten } from '@mui/material/styles';
import deepmerge from 'deepmerge';
import commonThemeParams from './themeCommonGoldilocks';

const themeLightParameters = {
  nrCodeTheme: 'prism',
  palette: {
    mode: 'light',
    primary: {
      main: commonThemeParams.palette.brand.indigo,
      light: '#ffb789',
      dark: '#5106dc',
      contrastText: '#f2f2f2',
    },
    secondary: {
      main: commonThemeParams.palette.brand.indigo,
      light: '#af76ff',
      dark: '#6d3ab2',
    },
    background: {
      default: '#ffffff',
      paper: '#F8F8F8',
      offset: '#EFEFEF',
      // offset: 'rgba(0, 0, 0, 0.05)',
      leftNav: '#ffffff',
    },
    borderColor: 'rgba(0, 0, 0, 0.13)',
    segments: {
      innerAnd: '#ffffff',
      middleOr: '#eff7fe',
      outerAnd: '#dae8f5',
    },
    action: {
      active: commonThemeParams.palette.brand.skyBlue,
      hover: commonThemeParams.palette.brand.skyBlue,
    },
    emailMetrics: {
      qualified: (theme) => theme.palette.text.primary,
      qualifiedBackground: (theme) => theme.palette.grey[200],
      sent: (theme) => theme.palette.text.primary,
      sentBackground: (theme) => theme.palette.grey[200],
      opens: '#328215',
      opensBackground: '#e5ffdb',
      clicks: '#61b201',
      clicksBackground: '#e5ffdb',
      visits: '#30a6f8',
      visitsBackground: '#f5fbff',
      bounces: '#ff9c00',
      bouncesBackground: '#fff9f0',
      unsubscribes: '#e00008',
      unsubscribesBackground: '#fff0f0',
      spamReports: '#e00008',
      spamReportsBackground: '#fff0f0',
    },
    error: {
      main: '#ff0033',
      badge: '#ff3b32',
      contrastText: '#fff',
    },
    fileManager: {
      file: '#666',
      pdf: commonThemeParams.palette.brand.coral,
      video: commonThemeParams.palette.brand.techBlue,
      videoFocused: '#ffffff',
      folderZip: commonThemeParams.palette.brand.green,
      code: commonThemeParams.palette.brand.darkBlue,
      audio: commonThemeParams.palette.brand.pink,
      folder: '#2f79de',
    },
    buttons: {
      primary: {
        color: '#ffffff',
        backgroundColor: commonThemeParams.palette.brand.indigo,
        backgroundHoverColor: darken(commonThemeParams.palette.brand.indigo, 0.25),
      },
      secondary: {
        color: commonThemeParams.palette.brand.darkBlue,
        backgroundColor: commonThemeParams.palette.darkBlue[50],
        backgroundHoverColor: commonThemeParams.palette.darkBlue[100],
      },
      inactive: '#ccc',
    },
  },
  navigation: {
    appBadge: '/logo/blue_favicon.svg',
    logoText: '/logo/blue_wordmark.svg',
    iconColor: commonThemeParams.palette.brand.skyBlue,
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#f7f7f7',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderTop: '1px solid rgb(224 224 224)',
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        content: {
          ':hover': {
            backgroundColor: commonThemeParams.palette.brand.techBlue,
            color: '#fff',
          },
          '&.Mui-focused': {
            backgroundColor: commonThemeParams.palette.brand.coral,
          },
          '&.Mui-selected': {
            backgroundColor: commonThemeParams.palette.brand.skyBlue,
            ':hover': {
              backgroundColor: commonThemeParams.palette.brand.skyBlue,
            },
          },
          '&.Mui-selected.Mui-focused': {
            backgroundColor: commonThemeParams.palette.brand.skyBlue,
          },
        },
        iconContainer: {
          color: '#2f79de',
          ':hover': {
            color: '#fff',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        groupLabel: {
          fontSize: '14px',
          fontWeight: 500,
          color: '#333',
          borderBottom: '1px solid',
          borderColor: '#bbb',
          background: '#ebebeb',
          letterSpacing: '.5px',
        },
      },
    },
  },
};

themeLightParameters.palette.tables = {
  actionIcons: {
    color: '#ffffff',
    actionIconBackGroundColor: commonThemeParams.palette.brand.indigo,
    actionIconBackGroundHoverColor: lighten(commonThemeParams.palette.brand.indigo, 0.15),
  },
  disabledActionIcons: {
    color: '#e0e0e0',
    actionIconBackGroundColor: '#bdbdbd',
    actionIconBackGroundHoverColor: '#bdbdbd',
  },
};

export default createTheme(deepmerge(commonThemeParams, themeLightParameters));
