import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormHelperText } from '@mui/material';
import { FastField, useFormikContext } from 'formik';

const FormikFolderSelect = (props) => {
  const { touched, errors, setFieldValue } = useFormikContext();
  return (
    <Box>
      <FastField name="folderId" type="hidden" required />
      <div>{props.folderButtonRender({ setFieldValue })}</div>
      {errors.folderId && touched.folderId ? (
        <FormHelperText error variant="outlined">
          {errors.folderId}
        </FormHelperText>
      ) : null}
    </Box>
  );
};

FormikFolderSelect.propTypes = {
  folderButtonRender: PropTypes.func.isRequired,
};

export default FormikFolderSelect;
