import React from 'react';
import PropTypes from 'prop-types';
import { darken, lighten, useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

const ColumnSortLabel = ({ isSorted, isSortedDesc }) => {
  const nrTheme = useTheme();
  let indicatorColor = '#ccc';
  let colorModifier = darken;
  if (nrTheme.palette.mode === 'dark') {
    indicatorColor = '#444';
    colorModifier = lighten;
  }
  const colorActiveSortIndicator = colorModifier(indicatorColor, 0.7);
  const colorInactiveSortIndicator = indicatorColor;

  let colorDescending = colorInactiveSortIndicator;
  let colorAscending = colorInactiveSortIndicator;

  if (isSorted && isSortedDesc) {
    colorDescending = colorActiveSortIndicator;
  } else if (isSorted) {
    colorAscending = colorActiveSortIndicator;
  }

  return (
    <Stack direction="column" spacing={0} alignItems="center">
      <ArrowDropUp
        sx={{ width: '14px', height: '14px', color: colorAscending, lineHeight: '.6rem' }}
        viewBox="4 2 14 14"
      />
      <ArrowDropDown
        sx={{ width: '14px', height: '14px', color: colorDescending, lineHeight: '.6rem' }}
        viewBox="4 8 14 14"
      />
    </Stack>
  );
};

ColumnSortLabel.propTypes = {
  isSorted: PropTypes.bool.isRequired,
  isSortedDesc: PropTypes.bool,
};

ColumnSortLabel.defaultProps = {
  isSortedDesc: null,
};

export default ColumnSortLabel;
