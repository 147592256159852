import React, { useState, useEffect, useRef, useContext, useCallback, forwardRef, createContext } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  ListSubheader,
  OutlinedInput,
  Popper,
  TextField,
  useMediaQuery,
} from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { CheckBox as CheckedIcon, CheckBoxOutlineBlank as CheckBoxIcon } from '@mui/icons-material';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';

// from here to HookFormAutocompleteChips is all copy pasted from mui docs
// https://mui.com/material-ui/react-autocomplete/#virtualization
// one difference is that their example uses options like ['thing 1', 'thing 2']
// and we use options like [{label: 'thing 1', value: 1}, {label: 'thing 2', value: 2}]
// so ListboxComponent has a different children prop type
// and renderRow is using the label {dataSet[1].label}
const LISTBOX_PADDING = 8; // px

const renderRow = (props) => {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (Object.prototype.hasOwnProperty.call(dataSet, 'group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <li key={dataSet[1].value} {...dataSet[0]} style={inlineStyle}>
      <Checkbox
        icon={<CheckBoxIcon />}
        checkedIcon={<CheckedIcon />}
        style={{ marginRight: 8 }}
        checked={dataSet[0]['aria-selected']}
      />
      {dataSet[1].label}
    </li>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (Object.prototype.hasOwnProperty.call(child, 'group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.array.isRequired,
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

// this doesn't spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormAutocompleteChips = ({
  autoFocus,
  disabled,
  disableClearable,
  disableCloseOnSelect,
  enableGrouping,
  freeSolo,
  helperText,
  label,
  loading,
  name,
  options,
  required,
  onChange,
  sx,
  testId,
}) => {
  const [value, setValue] = useState([]);

  // freeSolo means our user may enter any arbitrary value they like by typing: we do not have a pre-defined array of "options".
  // For example, our user is able to type in any email address(es) they like as "recipients" of an Alert.
  // If freeSolo, we have to pass this "renderTags" prop to <Field/> below.
  const renderTags = useCallback(
    (tagValue, getTagProps) =>
      tagValue.map((option, index) => {
        if (typeof option === 'object') {
          return <Chip key={option.value} variant="outlined" label={option.label} {...getTagProps({ index })} />;
        }
        return <Chip key={option} variant="outlined" label={option} {...getTagProps({ index })} />;
      }),
    []
  );

  // i had to make enableGrouping an extra prop
  // otherwise even when no group passed in options, you would end up with a blank group first in the dropdown
  let groupBy;
  if (enableGrouping) {
    groupBy = (option) => option.group;
  }

  const isOptionEqualToValue = (option, selectedValue) => {
    return String(option.value) === String(selectedValue.value);
  };

  // Rendering this during loading because:
  // if we have a defaultValue but have to wait for options to load, we get a "changing uncontrolled to controlled" warning when the values are loaded.
  if (loading) {
    return (
      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel>{label}</InputLabel>
        <OutlinedInput
          label={label}
          endAdornment={<CircularProgress size={20} />}
          disabled
          sx={{ backgroundColor: 'divider', ...sx }}
        />
      </FormControl>
    );
  }

  const getCurrentInternalValue = (fieldValue) => {
    let currentValue = [];
    if (value.length === 0) {
      currentValue = fieldValue.map((el) => {
        const valueForDisplay = options.find((element) => String(element.value) === String(el));
        return valueForDisplay || el;
      });
    } else {
      currentValue = value;
    }
    if (JSON.stringify(value) !== JSON.stringify(fieldValue.value)) {
      currentValue = fieldValue.map((el) => {
        const valueForDisplay = options.find((element) => String(element.value) === String(el));
        return valueForDisplay || el;
      });
    }
    return currentValue;
  };

  return (
    <Controller
      render={({ field, fieldState: { invalid, error }, formState: { isSubmitting } }) => {
        // 'error' must be handled dynamically in order to extract the correct error message.
        // 'error' is null when no errors.
        // When there are errors, the format of 'error' depends on the validation rule.
        // Error will be an object {...error} if we are validating the Autocomplete as a whole (ie .min() rule)
        // Error will be an array of objects [null, {...error}] if we are validating each element of the autocomplete (ie checking if each is .email()).
        // This code block will extract the correct message dependent on the structure of error.
        let errorMessage = error && error?.message ? error.message : null;
        if (error && !errorMessage) {
          const [firstError] = Object.values(error).filter((errorItem) => errorItem !== null && 'message' in errorItem);

          if (firstError) {
            errorMessage = firstError.message;
          }
        }

        return (
          <Autocomplete
            value={getCurrentInternalValue(field.value || [])}
            isOptionEqualToValue={!freeSolo ? isOptionEqualToValue : undefined}
            onChange={(_, newValue) => {
              // set the internal value in state
              setValue(newValue);
              onChange(newValue);

              // this sets the hook form field value to either the selected option value (from passed in options)
              // or it allows a freesolo typed in value
              // you still end up with a hook form field value that is an array like [option.value, 'enteredString', anotherSelectedOption.value]
              field.onChange(newValue.map((el) => (typeof el === 'object' ? el.value : el)));
            }}
            onBlur={field.onBlur}
            freeSolo={freeSolo}
            autoSelect={freeSolo ? true : undefined}
            renderTags={freeSolo ? renderTags : undefined}
            // renderOption={!freeSolo ? renderOption : undefined}
            options={options}
            multiple
            disableClearable={disableClearable}
            disableCloseOnSelect={disableCloseOnSelect}
            disabled={disabled || isSubmitting}
            noOptionsText="No options were found"
            size="small"
            sx={sx}
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            renderOption={(props, option) => [props, option]}
            renderGroup={(params) => params}
            groupBy={groupBy}
            slotProps={{
              popper: {
                id: 'hookFormAutocompletePopper',
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={invalid}
                helperText={errorMessage || helperText}
                label={label}
                required={required}
                autoFocus={autoFocus}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: params.InputProps.endAdornment,
                }}
                data-testid={testId}
              />
            )}
          />
        );
      }}
      name={name}
    />
  );
};

HookFormAutocompleteChips.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  disableClearable: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  enableGrouping: PropTypes.bool,
  freeSolo: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  sx: PropTypes.object,
  testId: PropTypes.string,
};

HookFormAutocompleteChips.defaultProps = {
  autoFocus: false,
  disabled: false,
  disableClearable: false,
  disableCloseOnSelect: false,
  enableGrouping: false,
  freeSolo: false,
  helperText: null,
  loading: false,
  required: false,
  onChange: () => {},
  sx: {},
  testId: null,
};

export default HookFormAutocompleteChips;
