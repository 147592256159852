import gql from 'graphql-tag';

export const ContactsQuery = gql`
  query ContactsQuery(
    $limit: Int!
    $page: Int!
    $sortField: ContactOrderByColumns!
    $sortDirection: SortOrder!
    $dynamicConditions: QueryContactsWhereWhereConditions!
  ) {
    contacts(
      orderBy: [{ column: $sortField, order: $sortDirection }]
      where: $dynamicConditions
      first: $limit
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        first_name
        last_name
        email
        title
        created_at
        updated_at
        bounced_at
        bounce_reason
        unsubscribed_at
        account {
          id
        }
      }
    }
  }
`;

export const ContactsQuickSearchQuery = gql`
  query ContactsQuery(
    $limit: Int!
    $page: Int!
    $sortField: ContactOrderByColumns!
    $sortDirection: SortOrder!
    $dynamicConditions: QueryContactsWhereWhereConditions!
  ) {
    contacts(
      orderBy: [{ column: $sortField, order: $sortDirection }]
      where: $dynamicConditions
      first: $limit
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        email
        first_name
        last_name
      }
    }
  }
`;

// TODO: refactor ContactsQuery and all uses to use different orderBy variable and toast this one
export const ContactsForDataGridQuery = gql`
  query ContactsForDataGridQuery(
    $limit: Int!
    $page: Int!
    $orderBy: [QueryContactsOrderByClause!]
    $dynamicConditions: QueryContactsWhereWhereConditions!
    $entityType: ContactEntityType
  ) {
    contacts(orderBy: $orderBy, where: $dynamicConditions, first: $limit, page: $page, entityType: $entityType) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        first_name
        last_name
        email
        title
        created_at
        updated_at
        bounced_at
        bounce_reason
        unsubscribed_at
        account {
          id
        }
      }
    }
  }
`;

export const BouncedContactsQuery = gql`
  query BouncedContactsQuery(
    $limit: Int!
    $page: Int!
    $sortField: ContactOrderByColumns!
    $sortDirection: SortOrder!
    $dynamicConditions: QueryContactsWhereWhereConditions!
  ) {
    contacts(
      orderBy: [{ column: $sortField, order: $sortDirection }]
      where: $dynamicConditions
      first: $limit
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        first_name
        last_name
        email
        title
        bounced_at
        bounce_reason
        account {
          id
        }
      }
    }
  }
`;

export const UnsubscribedContactsQuery = gql`
  query UnsubscribedContactsQuery(
    $limit: Int!
    $page: Int!
    $sortField: ContactOrderByColumns!
    $sortDirection: SortOrder!
    $dynamicConditions: QueryContactsWhereWhereConditions!
  ) {
    contacts(
      orderBy: [{ column: $sortField, order: $sortDirection }]
      where: $dynamicConditions
      first: $limit
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        first_name
        last_name
        email
        title
        unsubscribed_at
        account {
          id
        }
      }
    }
  }
`;

export const ContactsForAutocompleteQuery = gql`
  query ContactsForAutocompleteQuery(
    $limit: Int!
    $page: Int!
    $sortField: ContactOrderByColumns!
    $sortDirection: SortOrder!
    $dynamicConditions: QueryContactsWhereWhereConditions!
  ) {
    contacts(
      orderBy: [{ column: $sortField, order: $sortDirection }]
      where: $dynamicConditions
      first: $limit
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        label: email
        value: id
      }
    }
  }
`;

export const ContactByEmailQuery = gql`
  query ContactByEmailQuery($email: Mixed!) {
    contacts(first: 10, where: { AND: [{ column: EMAIL, operator: EQ, value: $email }] }) {
      paginatorInfo {
        total
      }
      data {
        id
      }
    }
  }
`;

export const ContactByEmailForCrmIframeQuery = gql`
  query ContactByEmailForCrmIframeQuery($email: Mixed!) {
    contacts(first: 1, where: { AND: [{ column: EMAIL, operator: EQ, value: $email }] }) {
      paginatorInfo {
        total
      }
      data {
        id
        first_name
        last_name
        email
        title
        address_1
        address_2
        city
        state
        country
        postal_code
        work_phone
        mobile_phone
        home_phone
        fax
        last_email_open_at
        last_email_click_at
        bounced
        bounced_at
        bounce_reason
        unsubscribed
        unsubscribed_at
        gdpr_compliance
        ip_address
        salesforce_url
        dynamics_url
        sugar_crm_url
        created_at
        updated_at
        custom_fields {
          id
          name
          type
          value
          dropdown_options {
            label: value
            value: id
          }
          relationship {
            id
            name
          }
        }
        lists {
          id
          name
        }
        subscriptions {
          name
          id
        }

        account {
          id
          name
          website
          employee_count
          revenue
          description
          billing_street
          billing_city
          billing_state
          billing_zip
          billing_country
          created_at
          updated_at
        }
        lead_owner {
          id
        }
        lead_stage {
          id
        }
        lead_scores {
          lead_score_ruleset {
            id
            name
            contact_score_qualification_threshold
            activity_score_qualification_threshold
            engagement_score_qualification_threshold
            total_score_qualification_threshold
            activity_plus_engagement_qualification_threshold
            active
            is_default
          }
          contact_score
          activity_score
          engagement_score
          manual_score
          total_score
        }
        first_touch {
          id
          type_label
          channel_label
          source {
            name
          }
          is_paid
          created_at
        }
        last_touch {
          id
          type_label
          channel_label
          source {
            name
          }
          is_paid
          created_at
        }
      }
    }
  }
`;

export const ContactQuery = gql`
  query ContactQuery($contact_id: ID!) {
    contact(id: $contact_id) {
      id
      first_name
      last_name
      email
      title
      address_1
      address_2
      city
      state
      country
      postal_code
      work_phone
      mobile_phone
      home_phone
      fax
      last_email_open_at
      last_email_click_at
      bounced
      bounced_at
      bounce_reason
      unsubscribed
      unsubscribed_at
      gdpr_compliance
      ip_address
      salesforce_url
      dynamics_url
      sugar_crm_url
      created_at
      updated_at
      custom_fields {
        id
        name
        type
        value
        dropdown_options {
          label: value
          value: id
        }
        relationship {
          id
          name
        }
      }
      lists {
        id
        name
      }
      subscriptions {
        name
        id
      }

      account {
        id
        name
        website
        employee_count
        revenue
        description
        billing_street
        billing_city
        billing_state
        billing_zip
        billing_country
        created_at
        updated_at
      }
      lead_owner {
        id
      }
      lead_stage {
        id
      }
      lead_scores {
        lead_score_ruleset {
          id
          name
          contact_score_qualification_threshold
          activity_score_qualification_threshold
          engagement_score_qualification_threshold
          total_score_qualification_threshold
          activity_plus_engagement_qualification_threshold
          active
          is_default
        }
        contact_score
        activity_score
        engagement_score
        manual_score
        total_score
      }
      first_touch {
        id
        type_label
        channel_label
        source {
          name
        }
        is_paid
        created_at
      }
      last_touch {
        id
        type_label
        channel_label
        source {
          name
        }
        is_paid
        created_at
      }
      is_sunset
      sunset_contact {
        sunset_until
        created_at
      }
      paused_lead_scoring_rulesets {
        id
        scoring_ruleset_id
        pause_until
        created_at
      }
      facebook_handle
      instagram_handle
      linkedin_handle
      twitter_handle
    }
  }
`;

export const ContactActivityStreamQuery = gql`
  query ContactActivityStreamQuery($id: ID!, $limit: Int!, $page: Int!, $activityTypes: [ContactActivityType!]) {
    contactActivityStream(id: $id, limit: $limit, page: $page, activityTypes: $activityTypes) {
      data
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export const ContactFieldsQuery = gql`
  query ContactFieldsQuery {
    contactFields {
      name
      type
      id
      isStandard
      isLeadField
    }
  }
`;

export const ContactFieldsNewQuery = gql`
  query ContactFieldsNewQuery {
    contactFieldsNew {
      name
      type
      id
      isStandard
      isSetFieldValueEditable
    }
  }
`;

export const DeleteContactMutation = gql`
  mutation DeleteContactMutation($id: ID!) {
    deleteContact(id: $id) {
      id
    }
  }
`;

export const DeleteMultipleContactsMutation = gql`
  mutation DeleteMultipleContactsMutation($input: DeleteContactsInput!) {
    deleteContacts(input: $input) {
      success
    }
  }
`;

export const AddContactsToListMutation = gql`
  mutation AddContactsToListMutation($contact_ids: [ID!]!, $list_id: ID!) {
    addContactsToList(input: { contact_ids: $contact_ids, list_id: $list_id }) {
      id
    }
  }
`;

export const RemoveContactsFromListMutation = gql`
  mutation RemoveContactsFromListMutation($contact_ids: [ID!]!, $list_id: ID!) {
    removeContactsFromList(input: { contact_ids: $contact_ids, list_id: $list_id }) {
      id
    }
  }
`;

export const AddContactToCampaignMutation = gql`
  mutation AddContactToCampaignMutation($contactId: Int!, $campaignId: Int!) {
    addToCampaign(input: { contact_id: $contactId, campaign_id: $campaignId }) {
      success
    }
  }
`;

export const RemoveContactFromCampaignMutation = gql`
  mutation RemoveContactFromCampaignMutation($contactId: Int!, $campaignId: Int!) {
    removeFromCampaign(input: { contact_id: $contactId, campaign_id: $campaignId }) {
      success
    }
  }
`;

export const ContactEngagementQuery = gql`
  query ContactEngagementQuery($contact_id: ID!) {
    statsContactEngagement(contact_id: $contact_id) {
      campaignEmailSends {
        name
        all_time
        last_30_days
        most_recent
        first_ever
      }
      campaignEmailOpens {
        name
        all_time
        last_30_days
        most_recent
        first_ever
      }
      campaignEmailClicks {
        name
        all_time
        last_30_days
        most_recent
        first_ever
      }
      contentDownloads {
        name
        all_time
        last_30_days
        most_recent
        first_ever
      }
      formSubmissions {
        name
        all_time
        last_30_days
        most_recent
        first_ever
      }
      videos {
        name
        all_time
        last_30_days
        most_recent
        first_ever
      }
      visits {
        name
        all_time
        last_30_days
        most_recent
        first_ever
      }
    }
  }
`;

export const LeadScoreRulesetForAutocompleteQuery = gql`
  query LeadScoreRulesetForAutocompleteQuery {
    leadScoreRulesets(first: 50, where: { AND: [{ column: ACTIVE, operator: EQ, value: true }] }) {
      data {
        id
        name
        active
        is_default
      }
    }
  }
`;

export const CreateContactMutation = gql`
  mutation CreateContactMutation($input: CreateContactInput!) {
    createContact(input: $input) {
      id
    }
  }
`;

export const UpdateContactMutation = gql`
  mutation UpdateContactMutation($id: ID!, $patch: PatchableContactFields!) {
    updateContact(input: { id: $id, patch: $patch }) {
      first_name
      last_name
      email
      title
      address_1
      address_2
      city
      state
      country
      postal_code
      work_phone
      mobile_phone
      home_phone
      fax
      gdpr_compliance
      custom_fields {
        id
        name
        type
        value
        dropdown_options {
          label: value
          value: id
        }
      }
      account {
        id
        name
      }
      lead_owner {
        id
      }
      lead_stage {
        id
      }
      linkedin_handle
      twitter_handle
      facebook_handle
      instagram_handle
    }
  }
`;

export const CreateLeadScoreManualAdjustmentMutation = gql`
  mutation CreateLeadScoreManualAdjustmentMutation(
    $contact_id: ID!
    $lead_score_ruleset_id: ID!
    $comment: String!
    $adjustment: Int!
  ) {
    createLeadScoreManualAdjustment(
      input: {
        contact_id: $contact_id
        lead_score_ruleset_id: $lead_score_ruleset_id
        comment: $comment
        adjustment: $adjustment
      }
    ) {
      lead_score_ruleset {
        name
      }
      adjustment
      comment
    }
  }
`;

export const UnbounceContactMutation = gql`
  mutation UnbounceContactMutation($id: ID!) {
    unbounceContact(id: $id) {
      success
    }
  }
`;

export const UnsubscribeContactMutation = gql`
  mutation UnsubscribeContactMutation($id: ID!) {
    unsubscribeContact(id: $id) {
      success
    }
  }
`;

export const ResubscribeContactMutation = gql`
  mutation ResubscribeContactMutation($contact_id: Int!, $reason: String!) {
    resubscribeContact(input: { contact_id: $contact_id, reason: $reason }) {
      success
    }
  }
`;

export const UploadContactImportFileMutation = gql`
  mutation UploadContactImportFileMutation($input: ContactImportUploadFileInput!) {
    uploadContactImportFile(input: $input) {
      headers
      fields {
        id
        name
        attribute_type
      }
      contact_import {
        id
        created_at
        overwrite
        filename
        active
        format
        status
        gdpr_delete
        add_count
        duplicate_count
        overwrite_count
        invalid_contact_count
        invalid_email_count
        invalid_lead_status_count
        invalid_role_count
      }
    }
  }
`;

export const CreateContactImportMutation = gql`
  mutation CreateContactImportMutation($input: CreateContactImportInput!) {
    createContactImport(input: $input) {
      id
    }
  }
`;

export const UpdateContactImportMutation = gql`
  mutation CreateContactImportMutation($input: UpdateContactImportInput!) {
    updateContactImport(input: $input) {
      id
    }
  }
`;

export const ContactsNotesQuery = gql`
  query ContactsNotesQuery(
    $contactId: ID!
    $limit: Int!
    $page: Int!
    $sortField: ContactNoteOrderByColumns!
    $sortDirection: SortOrder!
    $dynamicConditions: QueryContactNotesWhereWhereConditions!
  ) {
    contactNotes(
      contact_id: $contactId
      orderBy: [{ column: $sortField, order: $sortDirection }]
      where: $dynamicConditions
      first: $limit
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        title
        content
        updated_by {
          email
        }
        created_at
        updated_at
      }
    }
  }
`;

export const ContactNoteQuery = gql`
  query ContactNoteQuery($note_id: ID!) {
    contactNote(id: $note_id) {
      id
      title
      content
    }
  }
`;

export const CreateContactNoteMutation = gql`
  mutation CreateContactNoteMutation($input: CreateContactNoteInput!) {
    createContactNote(input: $input) {
      id
    }
  }
`;

export const UpdateContactNoteMutation = gql`
  mutation UpdateContactNoteMutation($id: ID!, $patch: PatchableContactNoteFields!) {
    updateContactNote(input: { id: $id, patch: $patch }) {
      title
      content
    }
  }
`;

export const DeleteContactNoteMutation = gql`
  mutation DeleteContactNoteMutation($id: ID!) {
    deleteContactNote(id: $id) {
      id
    }
  }
`;

export const ExportContactsMutation = gql`
  mutation ExportContactsMutation($input: ExportContactsInput!) {
    exportContacts(input: $input) {
      success
    }
  }
`;
