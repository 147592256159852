import React from 'react';
import { useQuery } from '@apollo/client';
import { UnreadBannerNotificationsQuery } from 'goldilocks/graphql/notifications/queries.graphql';
import * as Sentry from '@sentry/react';
import BannerNotifications from '../BannerNotifications';

const NotificationBanner = () => {
  const { data, loading, error } = useQuery(UnreadBannerNotificationsQuery, {
    pollInterval: import.meta.env.VITE_APP_ENVIRONMENT === 'production' ? 30000 : 0,
  });

  if (loading) {
    return null;
  }

  if (error) {
    // displaying the error fallback here looks ridiculous; just report to Sentry.
    Sentry.captureException(error);
    return null;
  }

  return <BannerNotifications notifications={data.notifications.data} />;
};

export default NotificationBanner;
