import React from 'react';
import { Button } from '@mui/material';
import { useAppContext } from '@/context/AppContext';
import { bindHover } from 'material-ui-popup-state/hooks';

const TopNavItem = ({ label, popupState }: { label: string; popupState: any }) => {
  const { appName } = useAppContext();
  return (
    <Button
      sx={{
        fontSize: '0.875rem',
        fontWeight: 500,
        letterSpacing: { md: '0.09rem', lg: '0.12rem' },
        color: appName === 'Paminga' ? 'text.primary' : 'brand.tangerine',
        whiteSpace: 'nowrap',
      }}
      {...bindHover(popupState)}
    >
      {label}
    </Button>
  );
};

export default TopNavItem;
