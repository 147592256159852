import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Transition } from 'react-transition-group';
import { keyframes } from '@mui/system';

const bounce = keyframes`
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

const rollOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
`;

const TransitionBounceIn = ({ children, transitionIn }) => {
  const animationDuration = transitionIn ? '1s' : 0;

  const transitionStyles = {
    entering: {
      opacity: 1,
      animationName: `${bounce}`,
      animationDuration,
    },
    entered: {
      opacity: 1,
      animationName: `${bounce}`,
      animationDuration,
    },
    exiting: {
      opacity: 0,
      animationName: `${rollOut}`,
      animationDuration,
    },
    exited: {
      opacity: 0,
      animationName: `${rollOut}`,
      animationDuration,
    },
  };

  return (
    <Transition in appear timeout={250}>
      {(state) => (
        <Box
          sx={{
            ...transitionStyles[state],
          }}
        >
          {children}
        </Box>
      )}
    </Transition>
  );
};

TransitionBounceIn.defaultProps = {
  transitionIn: true,
};

TransitionBounceIn.propTypes = {
  children: PropTypes.node.isRequired,
  transitionIn: PropTypes.bool,
};

export default TransitionBounceIn;
