import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useAppContext } from '@/context/AppContext';

const BottomControlBar = ({ disableLeftNav, children, sx }) => {
  const { currentLeftNavWidth } = useAppContext();

  return (
    <Box
      p={2}
      sx={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: (theme) => theme.zIndex.drawer - 1,
        width: '100%',
        backgroundColor: (theme) => theme.palette.background.default,
        borderTop: '1px solid',
        borderTopColor: (theme) => theme.palette.borderColor,
        ...sx,
      }}
    >
      <Box
        sx={{
          marginLeft: disableLeftNav ? 'inherit' : `${currentLeftNavWidth}px`,
          transition: 'all 200ms ease-in-out',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

BottomControlBar.defaultProps = {
  disableLeftNav: false,
  sx: {},
};

BottomControlBar.propTypes = {
  children: PropTypes.node.isRequired,
  disableLeftNav: PropTypes.bool,
  sx: PropTypes.object,
};

export default BottomControlBar;
