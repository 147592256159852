import gql from 'graphql-tag';

export const UnreadNotificationCenterNotificationsQuery = gql`
  query UnreadNotificationCenterNotificationsQuery($limit: Int!, $page: Int!) {
    notifications(
      location: NOTIFICATION_CENTER
      isRead: false
      first: $limit
      page: $page
      orderBy: [{ column: ID, order: DESC }]
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        type
        is_global
        json
        read
        created_at
      }
    }
  }
`;

export const ReadNotificationCenterNotificationsQuery = gql`
  query ReadNotificationCenterNotificationsQuery($limit: Int!, $page: Int!) {
    notifications(
      location: NOTIFICATION_CENTER
      isRead: true
      first: $limit
      page: $page
      orderBy: [{ column: ID, order: DESC }]
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        type
        is_global
        json
        read
        created_at
      }
    }
  }
`;

export const UnreadBannerNotificationsQuery = gql`
  query UnreadBannerNotificationsQuery {
    notifications(location: TOP_BANNER, isRead: false, first: 5, page: 1) {
      data {
        id
        type
        is_global
        json
        expires_at
        created_at
      }
    }
  }
`;

export const UnreadNotificationCenterCountQuery = gql`
  query UnreadNotificationCenterCountQuery {
    notifications(location: NOTIFICATION_CENTER, isRead: false, first: 1, page: 1) {
      paginatorInfo {
        total
      }
    }
  }
`;

export const DeleteNotificationMutation = gql`
  mutation DeleteNotificationMutation($notification_id: Int!) {
    deleteNotification(notification_id: $notification_id) {
      id
    }
  }
`;

export const MarkNotificationsAsReadMutation = gql`
  mutation MarkNotificationsAsReadMutation($notification_ids: [Int!]) {
    markNotificationsAsRead(notification_ids: $notification_ids) {
      success
    }
  }
`;

export const LoginPageSideBarNotificationsQuery = gql`
  query LoginPageSideBarNotificationsQuery {
    goldilocksLoginPageSidebarNotifications(first: 10, page: 1, orderBy: [{ column: ID, order: DESC }]) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        type
        is_global
        json
        read
        created_at
      }
    }
  }
`;

export default UnreadNotificationCenterNotificationsQuery;
