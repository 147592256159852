import { getJWTFromStorage } from './jwtLocalstorage';

export const bootstrapAppData = async () => {
  const data = await getJWTFromStorage();
  if (!data) {
    return null;
  }

  return data;
};

export default bootstrapAppData;
