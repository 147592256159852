import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import { Box, InputAdornment } from '@mui/material';
import { TextField } from 'formik-mui';
import { FastField } from 'formik';

const FormikFastField = ({
  fieldType,
  fieldLabel,
  fieldName,
  helperText,
  autoComplete,
  required,
  autoFocus,
  disabled,
  startAdornment,
  endAdornment,
  id,
  ...rest
}) => {
  const inputProps = {
    autoComplete,
    readOnly: disabled,
    startAdornment,
    endAdornment,
    id,
  };

  return (
    <FastField
      component={TextField}
      fullWidth
      variant="outlined"
      size="small"
      label={fieldLabel}
      name={fieldName}
      type={fieldType}
      required={required}
      helperText={helperText}
      autoFocus={autoFocus}
      InputProps={inputProps}
      InputLabelProps={{
        htmlFor: id,
      }}
      {...rest}
    />
  );
};

FormikFastField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  id: PropTypes.string,
};

FormikFastField.defaultProps = {
  autoComplete: 'off',
  helperText: null,
  required: false,
  autoFocus: false,
  disabled: false,
  startAdornment: null,
  endAdornment: null,
  id: null,
};

export default FormikFastField;
