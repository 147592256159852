import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useWatch } from 'react-hook-form';
import { FormControl, FormControlLabel, FormHelperText, Radio } from '@mui/material';

// this doesnt spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormRadioButton = ({ color, disabled, helperText, label, name, onChange, supressError, value }) => {
  const groupValue = useWatch({ name });

  return (
    <Controller
      render={({ field, fieldState: { invalid, error }, formState: { isSubmitting } }) => {
        return (
          <FormControl error={invalid} color={color}>
            <FormControlLabel
              value={value}
              control={<Radio size="small" color={color} disabled={disabled || isSubmitting} />}
              label={label}
              disabled={disabled || isSubmitting}
              onChange={(e) => {
                field.onChange(e.target.value);
                onChange(e.target.value);
              }}
              checked={value === groupValue}
            />
            <FormHelperText>{error && !supressError ? error.message : helperText}</FormHelperText>
          </FormControl>
        );
      }}
      name={name}
    />
  );
};

HookFormRadioButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  supressError: PropTypes.bool,
};

HookFormRadioButton.defaultProps = {
  color: 'secondary',
  disabled: false,
  helperText: null,
  label: null,
  onChange: () => {},
  supressError: false,
};

export default HookFormRadioButton;
