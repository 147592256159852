import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const CurrencyCell = ({ amount, currency, textAlign }) => {
  // todo: locale based on preference? Browser?
  // todo: do we need to allow currency to vary? Not when showing the amount they were billed...
  const formattedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
  }).format(amount);

  return <Box sx={{ textAlign }}>{formattedAmount}</Box>;
};

CurrencyCell.propTypes = {
  amount: PropTypes.string,
  currency: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'right']),
};

CurrencyCell.defaultProps = {
  amount: '',
  currency: null,
  textAlign: 'right',
};

export default CurrencyCell;
