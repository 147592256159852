import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { useCurrentUser } from '@/context/UserContext';

// this doesn't spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormDate = ({
  autoFocus,
  color,
  format,
  fullWidth,
  helperText,
  label,
  maxDate,
  minDate,
  name,
  onChange,
  required,
  sx,
}) => {
  const { currentUser } = useCurrentUser();
  const [value, setValue] = useState('');

  const findValue = (internalValue, fieldValue) => {
    // if the user has interacted with the picker, we start to use the internal value which is a moment obj
    if (internalValue !== '') {
      return internalValue;
    }

    // on first load with a initialValue of a string date, we have to parse and return it
    if (!(fieldValue instanceof moment)) {
      return moment(fieldValue);
    }

    return fieldValue;
  };

  return (
    <Controller
      render={({ field, fieldState: { invalid, error }, formState: { isSubmitting } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              format={format ?? currentUser?.date_format}
              clearable
              label={label}
              value={findValue(value, field.value)}
              onChange={(onChangeNewValue) => {
                // this handles changes from the popup
                if (onChangeNewValue) {
                  // set internal value (moment object)
                  setValue(onChangeNewValue);
                  // set the form field value to UTC
                  field.onChange(onChangeNewValue.utc().format('YYYY-MM-DD'));
                  onChange(onChangeNewValue.utc().format('YYYY-MM-DD'));
                }
              }}
              disabled={isSubmitting}
              slotProps={{
                textField: {
                  size: 'small',
                  name: field.name,
                  onBlur: field.onBlur,
                  required,
                  fullWidth,
                  error: invalid,
                  helperText: error ? error.message : helperText,
                  autoFocus,
                  sx,
                },
                field: {
                  onChange: (newValue) => {
                    // this handles changes from the input via keyboard
                    if (newValue) {
                      // set internal value (moment object)
                      setValue(newValue);
                      // set the form field value to UTC
                      field.onChange(newValue.utc().format('YYYY-MM-DD'));
                      onChange(newValue.utc().format('YYYY-MM-DD'));
                    }
                  },
                  inputRef: field.ref,
                },
                openPickerButton: {
                  color,
                },
              }}
              maxDate={moment(maxDate)}
              minDate={moment(minDate)}
            />
          </LocalizationProvider>
        );
      }}
      name={name}
    />
  );
};

HookFormDate.propTypes = {
  autoFocus: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
  format: PropTypes.oneOf(['YYYY-MM-DD', 'M/D/YYYY', 'DD/MM/YYYY']),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  sx: PropTypes.object,
};

HookFormDate.defaultProps = {
  autoFocus: false,
  color: 'secondary',
  format: null,
  fullWidth: false,
  helperText: null,
  maxDate: null,
  minDate: null,
  onChange: () => {},
  required: false,
  sx: {},
};

export default HookFormDate;
