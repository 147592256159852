import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';
import NrButton from '@/components/NrMaterialOverrides/NrButton';

const DateRangeButton = ({ children, onClick }: { children: ReactNode; onClick: any }) => {
  return (
    // @ts-ignore
    <NrButton
      onClick={onClick}
      type="secondary"
      fullWidth
      sx={{ letterSpacing: 'inherit', fontSize: '0.64rem', lineHeight: '1rem', fontWeight: 400, width: '100%', px: 2 }}
    >
      <Typography
        variant="body2"
        noWrap
        sx={{ letterSpacing: 'inherit', fontSize: '0.64rem', lineHeight: '1rem', fontWeight: 400 }}
      >
        {children}
      </Typography>
    </NrButton>
  );
};

export default DateRangeButton;
