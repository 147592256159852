import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import formatters from '@/utilities/Format';

const NumberCell = ({ value }) => {
  return <Box sx={{ textAlign: 'right', width: '100%' }}>{formatters.formatNumber(value)}</Box>;
};
NumberCell.propTypes = {
  value: PropTypes.node,
};

NumberCell.defaultProps = {
  value: '',
};

export default NumberCell;
