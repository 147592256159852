import gql from 'graphql-tag';

export const ResetPasswordMutation = gql`
  mutation ResetPassword($password: String!, $token: String!) {
    resetPassword(input: { password: $password, token: $token }) {
      id
      email
    }
  }
`;

export default ResetPasswordMutation;
