import { createTheme, lighten } from '@mui/material/styles';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const commonThemeParams = {
  typography: {
    h1: {
      fontSize: 30,
      lineHeight: 1.5,
      fontWeight: 400,
      marginBottom: '0.3em',
    },
    h2: {
      fontSize: 25,
      lineHeight: 1.5,
      fontWeight: 400,
      marginTop: '0.2em',
      marginBottom: '0.3em',
    },
    h3: {
      fontSize: 22,
      lineHeight: 1.4,
      fontWeight: 400,
      marginTop: '0.1em',
      marginBottom: '0.26em',
    },
    h4: {
      fontSize: 20,
      lineHeight: 1.4,
      fontWeight: 400,
      marginTop: '0.1em',
      marginBottom: '0.28em',
    },
    h5: {
      fontSize: 18,
      lineHeight: 1.4,
      fontWeight: 400,
      marginTop: '0.1em',
      marginBottom: '0.28em',
    },
    h6: {
      fontSize: 16,
      lineHeight: 1.4,
      fontWeight: 400,
      marginTop: '0.1em',
      marginBottom: '0.28em',
    },
    fontFamily: ['Karla', '-apple-system', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    fontSize: 12.25, // todo: this interacts with <Typography> in ways MWard does not understand. 14px here becomes 16px in the UI.
  },
  palette: {
    brand: {
      techBlue: '#0298ff',
      purple: '#9c54ff',
      indigo: '#762dff',
      coral: '#ff8f5d',
      tangerine: '#ffa56c',
      skyBlue: '#0298ff',
      darkBlue: '#29293f',
      pink: '#ff7cb8',
      lightGray: '#f2f2f2',
      midGray: '#d3d3d3',
      green: '#3fab6a',
      red: '#ab3f53',
      paminga: {
        darkBlue: '#1f406e',
        lightBlue: '#0298ff',
        green: '#3fab6a',
      },
    },
    // Create a Color "Palette"!
    techBlue: createColor('#0278ff'),
    dashboards: {
      mailable: '#61b100',
      bounced: '#ff9c00',
      unsubscribed: '#e00008',
    },
    darkBlue: {
      50: '#e5e5e8',
      100: '#adb4c0',
      200: '#8894a8',
      300: '#647691',
      400: '#495c79',
      500: '#344764',
      550: '#2b3e5b',
      600: '#23344f',
      700: '#14233a',
      800: '#091425',
      900: '#061121',
      A100: '#5e5eff',
      A200: '#2b2bff',
      A400: '#0000f7',
      A700: '#0000de',
    },
  },
  spacing: [0, 8, 16, 24, 32, 40, 48, 54, 60, 68, 72],
  components: {
    Paper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          position: 'sticky',
          top: 0,
          zIndex: 2,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          fontSize: '0.875rem',
        },
        columnHeaders: {
          borderTop: '1px solid #e0e0e0',
          backgroundColor: '#f7f7f7',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          minHeight: 42,
        },
        columnHeader: {
          height: 42,
        },
        toolbarContainer: {},
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingBottom: 16,
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 13,
          padding: '6px 12px',
          borderRadius: '12px',
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          lineHeight: '20px',
        },
      },
    },
  },
};

// commonThemeParams.palette.success = {
//   light: '#ccffa0',
//   main: '#ccffa0',
//   dark: '#ccffa0',
//   contrastText: '#000',
// };

// commonThemeParams.palette.warning = {
//   light: '#000',
//   main: commonThemeParams.palette.brand.coral,
//   dark: '#000',
//   contrastText: '#fff',
// };

commonThemeParams.palette.buttons = {
  tangerine: {
    color: '#ffffff',
    backgroundColor: commonThemeParams.palette.brand.tangerine,
    backgroundHoverColor: lighten(commonThemeParams.palette.brand.tangerine, 0.15),
  },
};

// commonThemeParams.palette.info = {
//   light: '#000',
//   main: commonThemeParams.palette.brand.skyBlue,
//   dark: '#000',
//   contrastText: '#000',
// };

// commonThemeParams.palette.error = {
//   light: '#000',
//   main: commonThemeParams.palette.brand.red,
//   dark: '#000',
//   contrastText: '#000',
// };

export default commonThemeParams;
