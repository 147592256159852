import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Box, Checkbox, FormControl, FormControlLabel } from '@mui/material';

// this doesn't spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormCheckboxGroup = ({ color, disabled, label, name, size, sx, value, onChange }) => {
  return (
    <Controller
      name={name}
      render={({ field, formState: { isSubmitting } }) => {
        return (
          <Box>
            <Box ml={1}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      color={color}
                      size={size}
                      sx={sx}
                      {...field}
                      disabled={isSubmitting || disabled}
                      value={value}
                      checked={
                        field.value && field.value.some((existingValue) => String(existingValue) === String(value))
                      }
                      onChange={(event, checked) => {
                        if (checked) {
                          field.onChange([...(field.value ?? []), event.target.value]);
                        } else {
                          field.onChange((field.value ?? []).filter((newValue) => newValue !== event.target.value));
                        }
                        onChange(checked);
                      }}
                    />
                  }
                  color={color}
                  label={label}
                />
              </FormControl>
            </Box>
          </Box>
        );
      }}
    />
  );
};

HookFormCheckboxGroup.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  sx: PropTypes.object,
  value: PropTypes.string.isRequired,
};

HookFormCheckboxGroup.defaultProps = {
  color: 'secondary',
  disabled: false,
  label: null,
  onChange: () => {},
  size: 'small',
  sx: {},
};

export default HookFormCheckboxGroup;
