import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

const HookFormTextField = (props) => {
  return <Input {...props} />;
};

HookFormTextField.propTypes = {
  autoComplete: PropTypes.oneOf(['on', 'off', 'new-password']),
  autoFocus: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning']),
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  forceError: PropTypes.bool,
  formatAsCurrency: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  hidden: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  multiline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  startAdornment: PropTypes.node,
  sx: PropTypes.object,
  type: PropTypes.string.isRequired,
  useHackyFocusHack: PropTypes.bool,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  maskField: PropTypes.bool,
};

HookFormTextField.defaultProps = {
  autoComplete: 'off',
  autoFocus: false,
  color: null,
  defaultValue: '',
  disabled: false,
  endAdornment: null,
  forceError: false,
  formatAsCurrency: false,
  fullWidth: true,
  helperText: null,
  hidden: false,
  id: null,
  max: null,
  min: null,
  multiline: false,
  onChange: () => {},
  placeholder: '',
  required: false,
  rows: null,
  startAdornment: null,
  sx: {},
  useHackyFocusHack: false,
  variant: 'outlined',
  maskField: false,
};

export default HookFormTextField;
