import React from 'react';
import PropTypes from 'prop-types';
import { darken, lighten, useTheme } from '@mui/material/styles';
import { Box, ListItem, ListItemIcon, ListItemText, Tabs, Tooltip } from '@mui/material';
import { AddBox } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { Can } from '@/context/Can';
import { iconMappings } from '@/components/Navigation/constants';

const CustomLink = ({ objItem, children }) => {
  if (objItem.external) {
    return (
      <Box key={objItem.url}>
        <a href={objItem.url}>{children}</a>
      </Box>
    );
  }

  return (
    <Link style={{ textDecoration: 'none' }} to={objItem.url} key={objItem.url}>
      {children}
    </Link>
  );
};
CustomLink.propTypes = {
  objItem: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

const CreateNewLink = ({ objItem }) => {
  if (objItem.createNewExternal) {
    return (
      <Box sx={{ position: 'absolute', top: 13, right: 22 }}>
        <Tooltip title={`Create New ${objItem.createNewLabel}`} arrow placement="right" enterDelay={700}>
          <a href={objItem.createNewUrl} aria-label="Create New">
            <AddBox
              sx={{
                opacity: '0.75',
              }}
            />
          </a>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'absolute', top: 13, right: 22 }}>
      <Tooltip title={`Create New ${objItem.createNewLabel}`} arrow placement="right" enterDelay={700}>
        <Link style={{ color: '#06c', textDecoration: 'none' }} to={objItem.createNewUrl}>
          <AddBox
            sx={{
              opacity: '0.75',
            }}
          />
        </Link>
      </Tooltip>
    </Box>
  );
};
CreateNewLink.propTypes = {
  objItem: PropTypes.object.isRequired,
};

const LeftNavTabs = (props) => {
  const nrTheme = useTheme();
  const location = useLocation();

  let tabValue = props.navItems.find((navItem) => navItem.url === location.pathname);

  if (tabValue === undefined) {
    // if not exact match, find one that starts with it
    // if this still doesn't find anything, we will not highlight a tab
    tabValue = props.navItems.find((navItem) => location.pathname.startsWith(navItem.url));
  }

  return (
    <Tabs orientation="vertical" value={tabValue?.url || false}>
      {props.navItems.map((objItem) => {
        let backgroundColor = 'inherit';
        if (objItem.url === props.locationPath) {
          const highlightActiveLink = nrTheme.palette.mode === 'light' ? darken : lighten;
          backgroundColor = highlightActiveLink(nrTheme.palette.background.paper, 0.04);
        }

        let createNew;
        if (objItem.createNewUrl) {
          createNew = <CreateNewLink objItem={objItem} />;
        }

        return (
          // .replace returns the label with no spaces
          <Can I="viewAny" a={objItem.label.replace(/\s/g, '')} key={objItem.url} value={objItem.url}>
            <Box>
              <CustomLink objItem={objItem}>
                <ListItem
                  button
                  sx={{
                    paddingLeft: '22px',
                    width: '100%',
                    minHeight: '48px',
                    justifyContent: 'inherit',
                    color: nrTheme.palette.text.primary,
                    backgroundColor: `${backgroundColor}`,
                  }}
                >
                  <ListItemIcon sx={{ color: (theme) => theme.palette.text.primary, minWidth: 28 }}>
                    {iconMappings[objItem.icon]}
                  </ListItemIcon>
                  <ListItemText
                    primary={objItem.label}
                    className={props.showHideSidebarItems}
                    sx={{ whiteSpace: 'nowrap' }}
                  />
                  {createNew}
                </ListItem>
              </CustomLink>
            </Box>
          </Can>
        );
      })}
    </Tabs>
  );
};

LeftNavTabs.propTypes = {
  navItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
  showHideSidebarItems: PropTypes.string.isRequired,
  locationPath: PropTypes.string.isRequired,
};

export default LeftNavTabs;
