import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';

const HookFormFieldArray = ({ keyName, name, render }) => {
  const fieldArray = useFieldArray({
    name,
    keyName,
  });

  return render(fieldArray);
};

HookFormFieldArray.propTypes = {
  keyName: PropTypes.string,
  name: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
};

HookFormFieldArray.defaultProps = {
  keyName: 'id',
};

export default HookFormFieldArray;
