import { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import { getLocalStorageWithExpiry } from '@/utilities/localstorage';

const BaseErrorFallback = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = getLocalStorageWithExpiry(import.meta.env.VITE_APP_LOCALSTORAGE_USER_KEY);

  useEffect(() => {
    // we might want to make this optional, not sure if there are cases where it could spam errors yet
    if (
      props.error &&
      props.error?.message !== 'You are currently unauthenticated.' &&
      Number(currentUser?.product?.id) !== 26372
    ) {
      const { graphQLErrors, networkError } = props.error;
      if (graphQLErrors) {
        graphQLErrors.forEach((gqlError) => {
          if (!gqlError?.extensions?.validation) {
            Sentry.captureException(props?.error, { extra: gqlError });
          }
        });
      }
      if (networkError) {
        Sentry.captureException(props?.error, { extra: networkError });
      }
    }
  }, [currentUser?.product?.id, props.error]);

  useEffect(() => {
    if (props.displaySnackbar) {
      enqueueSnackbar(props.snackbarMessage, {
        variant: 'error',
      });
    }
  }, [props.displaySnackbar, props.snackbarMessage, enqueueSnackbar]);

  return props.children;
};

BaseErrorFallback.defaultProps = {
  error: null,
  displaySnackbar: false,
  snackbarMessage: 'There was a problem completing your request',
};

BaseErrorFallback.propTypes = {
  error: PropTypes.object,
  displaySnackbar: PropTypes.bool,
  snackbarMessage: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default BaseErrorFallback;
