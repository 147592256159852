import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const Heading = ({
  color,
  convertToUppercase,
  label,
  marginBottom,
  paddingBottom,
  paddingLeft,
  subHeading,
  variant,
  sx,
}) => {
  let labelForDisplay = label;
  if (convertToUppercase) {
    labelForDisplay = label.toUpperCase();
  }

  return (
    <Box pl={paddingLeft} pb={paddingBottom} mb={marginBottom}>
      <Typography variant={variant} pl={0} pb={0} mb={0} sx={{ letterSpacing: '0.12rem', color, ...sx }}>
        <Box dangerouslySetInnerHTML={{ __html: labelForDisplay }} />
      </Typography>
      {subHeading ? (
        <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>{subHeading}</Typography>
      ) : null}
    </Box>
  );
};

Heading.defaultProps = {
  color: 'inherit',
  convertToUppercase: true,
  marginBottom: null,
  paddingBottom: 1,
  paddingLeft: 1,
  subHeading: null,
  variant: 'h4',
  sx: {},
};

Heading.propTypes = {
  color: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  convertToUppercase: PropTypes.bool,
  label: PropTypes.string.isRequired,
  marginBottom: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingLeft: PropTypes.number,
  subHeading: PropTypes.string,
  sx: PropTypes.object,
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};

export default Heading;
