import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { Field } from 'formik';
import { Checkbox } from 'formik-mui';

const FormikCheckbox = ({ name, label, helperText, color, size, ...rest }) => {
  let helperTextComponent;
  if (helperText) {
    helperTextComponent = <FormHelperText>{helperText}</FormHelperText>;
  }

  if (!label) {
    return (
      <>
        <FormControl component="fieldset">
          <Field component={Checkbox} type="checkbox" name={name} color={color} size={size} {...rest} />
        </FormControl>
        {helperTextComponent}
      </>
    );
  }

  return (
    <>
      <FormControl component="fieldset">
        <FormControlLabel
          control={<Field component={Checkbox} type="checkbox" name={name} color={color} size={size} {...rest} />}
          label={label}
          color={color}
        />
      </FormControl>
      {helperTextComponent}
    </>
  );
};

FormikCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  color: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium']),
};

FormikCheckbox.defaultProps = {
  helperText: null,
  color: 'secondary',
  size: 'small',
  label: null,
};

export default FormikCheckbox;
