import React from 'react';
import PropTypes from 'prop-types';
import { Box, Drawer } from '@mui/material';

const DrawerRight = ({ children, open, variant, ...rest }) => {
  return (
    <Drawer anchor="right" open={open} variant={variant} {...rest}>
      <Box sx={{ minWidth: '400px', maxWidth: '500px' }}>{children}</Box>
    </Drawer>
  );
};

DrawerRight.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['temporary', 'permanent', 'persistent']),
};

DrawerRight.defaultProps = {
  children: null,
  variant: 'temporary',
};

export default DrawerRight;
