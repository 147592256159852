import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputAdornment, OutlinedInput, InputLabel } from '@mui/material';
import { ArrowDropDown, Business, ManageAccounts } from '@mui/icons-material';
import { usePopupState, bindHover } from 'material-ui-popup-state/hooks';
import CascadingMenu from '@/components/Navigation/CascadingMenu';
import NrMenuItem from '@/components/Navigation/TopNav/NrMenuItem';

const SettingsToggle = ({ settingsCategory }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'navMarketingCenter' });

  return (
    <>
      <FormControl variant="outlined" size="small">
        <InputLabel htmlFor="date-range-button">Choose Settings</InputLabel>
        <OutlinedInput
          id="choose-settings-button"
          value={`${settingsCategory} Settings`}
          label="Choose Settings"
          endAdornment={
            <InputAdornment position="end">
              <ArrowDropDown />
            </InputAdornment>
          }
          readOnly
          sx={{
            input: {
              cursor: 'pointer',
              textOverflow: 'ellipsis',
            },
          }}
          {...bindHover(popupState)}
        />
      </FormControl>
      <CascadingMenu
        popupState={popupState}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <NrMenuItem
          value="personal"
          menuItemText="Account Settings"
          menuItemUrl="/settings/account/profile"
          menuItemIcon={<Business />}
        />
        <NrMenuItem
          value="personal"
          menuItemText="Personal Settings"
          menuItemUrl="/settings/personal/profile"
          menuItemIcon={<ManageAccounts />}
        />
      </CascadingMenu>
    </>
  );
};

SettingsToggle.propTypes = {
  settingsCategory: PropTypes.oneOf(['Account', 'Personal']).isRequired,
};

export default SettingsToggle;
