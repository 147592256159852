import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { Box, Grid } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '@/context/UserContext';
import { AbilityContext } from '@/context/Can';
import { AppProvider } from '@/context/AppContext';
import themeLight from 'goldilocks/themes/themeLightGoldilocks';
import themeDark from 'goldilocks/themes/themeDarkGoldilocks';
import defineAbilityFor from 'goldilocks/ability';
import { logout } from '@/utilities/jwtLocalstorage';
import MainContentWrapper from '@/components/Navigation/MainContentWrapper';
import * as Sentry from '@sentry/react';

const NoNavigationLayout = (props) => {
  const { currentUser } = useCurrentUser();
  const history = useHistory();

  useEffect(() => {
    let coreLocation = history.location.pathname.split('/')[1];
    history.listen((location) => {
      const newCoreLocation = location.pathname.split('/')[1];
      try {
        if (newCoreLocation !== coreLocation) {
          // Only check for an update on a major route change.
          // For example /home -> /marketing-center.
          navigator.serviceWorker.getRegistrations().then((regs) => regs.forEach((reg) => reg.update()));
        }
      } catch (error) {
        // afaik these should be harmless
        // but id like to at least log the error so if a user ends up with a problem i dont know about we can try and debug
        // eslint-disable-next-line no-console
        Sentry.captureException(error);
      }

      // Send "page views" to NR capture process.
      if (import.meta.env.VITE_APP_ENVIRONMENT === 'production' && window.$__MA !== undefined) {
        window.$__MA.doCapture(`${import.meta.env.VITE_APP_ENDPOINT}${location.pathname}`, document.location, 5);
      }

      // Set the new location.
      coreLocation = newCoreLocation;
    });
  }, [history]);

  const currentTheme = currentUser?.theme === 'themeDark' ? themeDark : themeLight;

  const snackbarStyles = makeStyles({
    success: {
      backgroundColor: currentTheme.palette.success.main,
      color: currentTheme.palette.success.contrastText,
      fontSize: '0.875rem',
    },
    warning: {
      backgroundColor: currentTheme.palette.warning.main,
      color: currentTheme.palette.warning.contrastText,
      fontSize: '0.875rem',
    },
    error: {
      backgroundColor: currentTheme.palette.error.main,
      color: currentTheme.palette.error.contrastText,
      fontSize: '0.875rem',
    },
    info: {
      backgroundColor: currentTheme.palette.info.main,
      color: currentTheme.palette.info.contrastText,
      fontSize: '0.875rem',
    },
  });
  const snackbarClasses = snackbarStyles();

  const ability = defineAbilityFor(currentUser);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <AppProvider>
          <SnackbarProvider
            variant="success"
            maxSnack={4}
            preventDuplicate
            classes={{
              variantSuccess: snackbarClasses.success,
              variantError: snackbarClasses.error,
              variantWarning: snackbarClasses.warning,
              variantInfo: snackbarClasses.info,
            }}
          >
            <AbilityContext.Provider value={ability}>
              <Grid container spacing={0} sx={{ minWidth: '864px' }}>
                <Grid item xs={12}>
                  <Box
                    position="fixed"
                    sx={{
                      width: '100%',
                      minWidth: '1019px',
                      transition: 'all 200ms ease-in-out',
                      backgroundColor: (theme) => theme.palette.background.default,
                      zIndex: (theme) => theme.zIndex.drawer - 1,
                    }}
                  >
                    <Grid container direction="row" justifyContent="flex-end" alignItems="right" py="4px" px={2}>
                      <Grid item>
                        {props.disableLogout ? null : (
                          <Box
                            onClick={logout}
                            sx={{ color: (theme) => theme.palette.brand.techBlue, cursor: 'pointer' }}
                          >
                            Sign Out
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <MainContentWrapper suppressNavTopMargin={props.disableLogout}>{props.children}</MainContentWrapper>
                </Grid>
              </Grid>
            </AbilityContext.Provider>
          </SnackbarProvider>
        </AppProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

NoNavigationLayout.propTypes = {
  children: PropTypes.node,
  disableLogout: PropTypes.bool,
};

NoNavigationLayout.defaultProps = {
  children: '',
  disableLogout: false,
};

export default NoNavigationLayout;
