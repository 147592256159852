import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { MenuItem } from '@mui/material';

// todo: These dropdowns do not allow selection via keyboard. Pretty lame for power users.

const FormikSelect = ({ name, items, label, required, helperText, disabled, ...rest }) => {
  return (
    <div className="FormikSelect">
      <Field
        id={`${name}-select`}
        component={TextField}
        type="text"
        name={name}
        label={label}
        select
        variant="outlined"
        helperText={helperText}
        size="small"
        fullWidth
        required={required}
        disabled={disabled}
        {...rest}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Field>
    </div>
  );
};

FormikSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

FormikSelect.defaultProps = {
  required: false,
  helperText: null,
  disabled: false,
};

export default FormikSelect;
