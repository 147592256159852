import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Tooltip } from '@mui/material';
import MD5 from 'crypto-js/md5';
import GeneralUtilities from '@/utilities/General';
import { useCurrentUser } from '@/context/UserContext';

const Gravatar = ({ email, firstName, lastName, size, sx }) => {
  const gravatarEmailHash = MD5(email);
  const { currentUser } = useCurrentUser();

  const getInitials = () => {
    let initials = '';
    let firstInitial;

    if (firstName) {
      firstInitial = firstName.charAt(0);
    }

    let lastInitial;
    if (lastName) {
      lastInitial = lastName.charAt(0);
    }

    if (firstInitial !== undefined) {
      initials = firstInitial;
    }

    if (lastInitial !== undefined) {
      initials += lastInitial;
    }

    if (initials) {
      return initials;
    }

    return null;
  };

  const stringAvatar = () => {
    if (!getInitials()) {
      return {};
    }

    return {
      children: `${getInitials()}`,
    };
  };

  const gravatarDefault = getInitials() ? '404' : 'mp';

  const stringForColor = firstName || lastName || email ? `${firstName} ${lastName} ${email}` : 'undefined';

  return (
    <Tooltip
      title={currentUser.product.is_sandbox ? 'Your are logged in to your Sandbox Instance' : ''}
      arrow
      placement="left"
    >
      <Avatar
        src={`https://www.gravatar.com/avatar/${gravatarEmailHash}?d=${gravatarDefault}`}
        sx={{
          width: `${size}px`,
          height: `${size}px`,
          backgroundColor: GeneralUtilities.stringToColor(stringForColor),
          outline: currentUser.product.is_sandbox ? '3px solid red' : 'inherit',
          ...sx,
        }}
        {...stringAvatar()}
        data-testid="gravatar"
      />
    </Tooltip>
  );
};

Gravatar.propTypes = {
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.number,
  sx: PropTypes.object,
};

Gravatar.defaultProps = {
  email: null,
  firstName: null,
  lastName: null,
  size: 40,
  sx: {},
};

export default Gravatar;
