import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { convertDateTimeFromUTCToLocal } from '@/components/utilities/DateTime';
import { useCurrentUser } from '@/context/UserContext';

// this doesn't spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormDateTime = ({
  autoFocus,
  closeOnSelect,
  color,
  disabled,
  disableFuture,
  disablePast,
  format,
  fullWidth,
  helperText,
  label,
  maxDateTime,
  minDateTime,
  minutesStep,
  name,
  onChange,
  required,
  sx,
}) => {
  const [value, setValue] = useState('');
  const { currentUser } = useCurrentUser();

  const getDateTime = (dateTimeForMoment) => {
    return moment(dateTimeForMoment).utc().tz(currentUser.time_zone);
  };

  return (
    <Controller
      render={({ field, fieldState: { invalid, error }, formState: { isSubmitting } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDateTimePicker
              timezone={currentUser.time_zone}
              label={label}
              value={value !== '' ? value : convertDateTimeFromUTCToLocal(field.value, currentUser)}
              onChange={(onChangeNewValue) => {
                // this handles changes from the popup
                if (onChangeNewValue) {
                  setValue(onChangeNewValue);
                  const clonedValue = onChangeNewValue.clone();
                  const formattedValueInUtc = clonedValue.utc().set('second', 0).format('YYYY-MM-DD HH:mm:ss');
                  // Set the form field value to UTC timezone, MySQL format.
                  field.onChange(formattedValueInUtc);
                  onChange(formattedValueInUtc);
                }
              }}
              maxDateTime={getDateTime(maxDateTime)}
              minDateTime={getDateTime(minDateTime)}
              minutesStep={minutesStep}
              ampm={format ? format.endsWith('h:mm A') : currentUser.time_format === 'h:mm A'}
              disabled={disabled || isSubmitting}
              disableFuture={disableFuture}
              disablePast={disablePast}
              format={format ?? `${currentUser.date_format} ${currentUser.time_format}`}
              closeOnSelect={closeOnSelect}
              slotProps={{
                textField: {
                  size: 'small',
                  name: field.name,
                  onBlur: field.onBlur,
                  required,
                  fullWidth,
                  error: invalid,
                  helperText: error ? error.message : helperText,
                  sx: { minWidth: 215, ...sx },
                  'data-no-dnd': 'true',
                  autoFocus,
                },

                field: {
                  onChange: (newValue) => {
                    if (newValue) {
                      setValue(newValue);
                      const clonedValue = newValue.clone();
                      const formattedValueInUtc = clonedValue.utc().set('second', 0).format('YYYY-MM-DD HH:mm:ss');
                      // Set the form field value to UTC timezone, MySQL format.
                      field.onChange(formattedValueInUtc);
                      onChange(formattedValueInUtc);
                    }
                  },
                  inputRef: field.ref,
                  minutesStep,
                },

                toolbar: {
                  hidden: false,
                },

                openPickerButton: {
                  color,
                },

                popper: {
                  'data-no-dnd': 'true',
                },
              }}
            />
          </LocalizationProvider>
        );
      }}
      name={name}
    />
  );
};

HookFormDateTime.propTypes = {
  autoFocus: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  format: PropTypes.oneOf([
    'YYYY-MM-DD HH:mm',
    'M/D/YYYY HH:mm',
    'DD/MM/YYYY HH:mm',
    'YYYY-MM-DD h:mm A',
    'M/D/YYYY h:mm A',
    'DD/MM/YYYY h:mm A',
  ]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  maxDateTime: PropTypes.oneOf([PropTypes.string, null]), // this explicitly only accepts dates in as strings in utc and initial values as utc string
  minDateTime: PropTypes.oneOf([PropTypes.string, null]), // this explicitly only accepts dates in as strings in utc and initial values as utc string
  minutesStep: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  sx: PropTypes.object,
};

HookFormDateTime.defaultProps = {
  autoFocus: false,
  closeOnSelect: false,
  color: 'secondary',
  disabled: false,
  disableFuture: false,
  disablePast: false,
  format: null,
  fullWidth: false,
  helperText: null,
  maxDateTime: null,
  minDateTime: null,
  minutesStep: 1,
  onChange: () => {},
  required: false,
  sx: {},
};

export default HookFormDateTime;
