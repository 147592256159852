import React from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache, from, ApolloLink } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import PropTypes from 'prop-types';
import { getLocalStorageWithExpiry } from '@/utilities/localstorage';
// import EchoPusherLink from './EchoPusherLink';

const link = createUploadLink({
  uri: import.meta.env.VITE_APP_GRAPHQL_ENDPOINT,
});

const authLink = new ApolloLink((operation, forward) => {
  const token = getLocalStorageWithExpiry(import.meta.env.VITE_APP_LOCALSTORAGE_AUTH_KEY) || '';
  operation.setContext(({ headers }) => ({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      ...headers,
    },
  }));
  return forward(operation);
});

const errorLink = onError((error) => {
  // eslint-disable-next-line no-console
  console.log('ApolloWrapper error', error);
  const { graphQLErrors } = error;
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions?.code) {
        // laravel sets code to UNAUTHENTICATED
        // when an AuthenticationError is thrown in a resolver
        case 'UNAUTHENTICATED':
          // simply reload the page to cause the login form to render
          // return window.location.reload();
          window.location.reload();

        // no default
      }
    }
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 350,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 7,
    retryIf: (error, operation) => {
      // only retry queries (not mutations)
      return !!error && operation?.query?.definitions?.every((definition) => definition.operation === 'query');
    },
  },
});

// we dont use this for anything yet so removed for now
// add to array below when needed again from([authLink, errorLink, echoLink, link])
// const echoLink = new EchoPusherLink({
//   host: import.meta.env.VITE_APP_WEBSOCKET_HOST,
//   port: import.meta.env.VITE_APP_WEBSOCKET_PORT,
//   key: 'nr-lighthouse-pusher',
//   authEndpoint: import.meta.env.VITE_APP_WEBSOCKET_AUTH_ENDPOINT,
// });

const cache = new InMemoryCache({ addTypename: false });

const defaultOptions = {
  // The watchQuery function is used by the useQuery() hook, so set policies for useQuery() here.
  // https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.watchQuery
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none', // Apollo Error Policies: https://www.apollographql.com/docs/react/data/error-handling/#graphql-error-policies
  },
  // The query function is used when we leverage the Apollo client directly.
  // https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.query
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
};

const client = new ApolloClient({ link: from([authLink, errorLink, retryLink, link]), cache, defaultOptions });

const ApolloWrapper = (props) => <ApolloProvider client={client}>{props.children}</ApolloProvider>;

ApolloWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApolloWrapper;
