const index = {
  formatCurrency: (number, strCurrency, strLocale) => {
    return new Intl.NumberFormat(strLocale || 'en-US', {
      style: 'currency',
      currency: strCurrency || 'USD',
    }).format(number);
  },

  formatNumber: (number, intNumberOfDecimals, strLocale) => {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
    return new Intl.NumberFormat(strLocale || 'en-US', {
      RoundingType: 'fractionDigits',
      minimumFractionDigits: intNumberOfDecimals || 0,
      maximumFractionDigits: intNumberOfDecimals || 0,
    }).format(number);
  },

  formatPercent: (numerator, denominator, decimals = 1, locale = 'en-US') => {
    const rawNumber = numerator / (denominator || 1);

    return new Intl.NumberFormat(locale, {
      style: 'percent',
      RoundingType: 'fractionDigits',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(rawNumber);
  },

  getPositiveDeltaIndicator: (int) => {
    if (int > 0) {
      return '+';
    }
    return '';
  },
};

export default index;
