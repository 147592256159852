import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'formik-mui';
import { Field } from 'formik';

const FormikField = ({
  fieldType,
  fieldLabel,
  fieldName,
  fullWidth,
  helperText,
  required,
  autoFocus,
  disabled,
  multiline,
  rows,
  autoComplete,
  startAdornment,
  endAdornment,
  id,
  useHackyFocusHack,
  ...rest
}) => {
  const objInputProps = {
    autoComplete,
    startAdornment,
    endAdornment,
    id,
  };

  if (fieldType === 'number') {
    objInputProps.role = 'spinbutton';
  }

  // this is hacky as hell.
  // for some reason rendering an input inside a dndKit item causes normal autoFocus to fail
  // this fixes it for reasons i dont understand https://stackoverflow.com/a/56066985
  // please never use this or pass this prop
  // ill come clean it up at some point
  if (autoFocus && useHackyFocusHack) {
    objInputProps.inputRef = (input) => {
      input && input.focus();
    };
  }

  return (
    <Field
      component={TextField}
      fullWidth={fullWidth}
      disabled={disabled}
      variant="outlined"
      size="small"
      label={fieldLabel}
      name={fieldName}
      type={fieldType}
      required={required}
      helperText={helperText}
      autoFocus={autoFocus}
      inputProps={{ 'aria-labelledby': fieldName }}
      /* Disabling no-duplicate-props as our linter is apparently case-insensitive and sees inputProps and InputProps as the same. They are not: https://material-ui.com/api/text-field/ */
      /* eslint-disable-next-line react/jsx-no-duplicate-props */
      InputProps={objInputProps}
      InputLabelProps={{ id: fieldName }}
      multiline={multiline}
      rows={rows}
      {...rest}
    />
  );
};

FormikField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  id: PropTypes.string,
  useHackyFocusHack: PropTypes.bool,
};

FormikField.defaultProps = {
  autoComplete: 'off',
  helperText: null,
  fullWidth: true,
  required: false,
  autoFocus: false,
  disabled: false,
  multiline: false,
  rows: null,
  startAdornment: null,
  endAdornment: null,
  id: null,
  useHackyFocusHack: false,
};

export default FormikField;
