import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const DrawerRightContent = ({ children, ...rest }) => {
  return (
    <Box p={2} mt={1} {...rest}>
      {children}
    </Box>
  );
};

DrawerRightContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DrawerRightContent;
