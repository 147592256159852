import ExcelJS from 'exceljs';
import { getValueFromPath } from '@/components/View/FieldChooser/utilities';
import stringUtilities from './String';
import arrayUtilities from './Array/arrayUtilities';

const jsonToCsvDownload = (data, name, delimiter) => {
  const items = data;
  const filename = name || `export.csv`;
  const d = delimiter || `,`;

  const header = Array.from(new Set(items.reduce((r, e) => [...r, ...Object.keys(e)], [])));
  let csv = items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName] || '')).join(d));
  csv.unshift(header.join(d));
  csv = csv.join('\r\n');

  const blob = new Blob([csv], {
    type: 'text/plain;charset=utf-8',
  });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
    return;
  }
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const jsonToExcelDownload = async (data, name, handleError) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Add Columns to the Worksheet
    worksheet.columns = arrayUtilities.getColumnsFromData(data).map((column) => {
      return {
        header: column
          .split('.')
          .map((path) => stringUtilities.createCapWordsString(path))
          .join(' // '),
        key: column,
        width: 32,
      };
    });

    worksheet.getRow(1).eachCell((cell) => {
      // Style the Header row to stand out.
      const modifiedCell = {
        font: { bold: true },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DCDCDC' }, // grey header
        },
        border: {
          top: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        },
      };

      // Assign the modified properties to the cell
      Object.assign(cell, modifiedCell);
    });

    // Add Data to the Columns
    data.forEach((row) => {
      const rowData = [];

      worksheet.columns.forEach((column) => {
        // Access the correct value from the dot notation path
        const value = getValueFromPath(column.key.split('.'), row);

        // Add the value to the row data
        rowData.push(value);
      });

      // Add the row to the worksheet
      worksheet.addRow(rowData);
    });

    // Download the compiled Excel File
    // Create a Blob from the workbook data
    const blob = await workbook.xlsx.writeBuffer();

    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(
      new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    );
    link.download = name;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  } catch (e) {
    handleError();
  }
};

/**
 * @param {object} currentUser //we need the currentUser to grab their preferred date_format but can't get it here as that's a hook and we're not in a Component.
 * @param dateRange //the date ranged used in generating the data
 * @param {string} chartName //the name you would give to the data/key metric, etc. ["form submissions", "campaign stats", etc.]
 * @param {string | null} objectLabel //the name of the [campaign, form, list, whatever]
 * @returns {`${string}${string}${string}`}
 */
export const createDownloadFileName = (currentUser, dateRange, chartName, objectLabel = null) => {
  const dateRangeString = `${dateRange.start_date.format(currentUser.date_format)}-${dateRange.end_date.format(
    currentUser.date_format
  )}`;

  let sanitizedChartName = '';
  if (chartName) {
    sanitizedChartName = `${chartName.replace(' - ', '_').split(' ').join('_').toLowerCase()}-`;
  }

  let sanitizedLabel = '';
  if (objectLabel) {
    sanitizedLabel = `${objectLabel.replace(' - ', '_').split(' ').join('_').toLowerCase()}-`;
  }

  return `${sanitizedChartName}${sanitizedLabel}${dateRangeString}`;
};

export default jsonToCsvDownload;
