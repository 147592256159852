import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthLayout from 'goldilocks/layouts/AuthLayout';
import Routes from 'goldilocks/routes';
import UnAuthedLayout from 'goldilocks/layouts/UnAuthed';
import CustomerSurveyRoute from '../customerSurveyRoute';

const UnauthenticatedApp = () => {
  if (['/survey/icp'].includes(window.location.pathname)) {
    return (
      <Router>
        <UnAuthedLayout>
          <CustomerSurveyRoute />
        </UnAuthedLayout>
      </Router>
    );
  }

  return (
    <Router>
      <AuthLayout>
        <Routes />
      </AuthLayout>
    </Router>
  );
};

export default UnauthenticatedApp;
