import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { setLocalStorageWithExpiry } from '@/utilities/localstorage';
import { UserContext } from '@/context/UserContext';

// Separated from userContext as we have a lightweight UserProvider in the FormRenderer.
const UserProvider = (props) => {
  const [currentUser, setCurrentUser] = useState(props.currentUser);

  // memoize the full context value
  const contextValue = useMemo(() => {
    // Updates a single property on our currentUser immutably in both UserContext and local storage.
    const handleUserUpdate = (strProperty, mixVal) => {
      const objNewUser = update(currentUser, {
        [strProperty]: { $set: mixVal },
      });

      // Update our currentUser in local storage. If our user reloads the app, we grab the user from there so we need to keep it current.
      setLocalStorageWithExpiry(
        import.meta.env.VITE_APP_LOCALSTORAGE_USER_KEY,
        objNewUser,
        currentUser.product.session_duration * 60 * 60 * 1000 // session_duration stores the number of hours that should elapse before expiration. Convert to milliseconds.
      );

      setCurrentUser(objNewUser);
    };

    // Updates a single property on our currentUser.product immutably in both UserContext and local storage.
    const handleProductUpdate = (strProperty, mixVal) => {
      const objNewUser = update(currentUser, {
        product: {
          [strProperty]: { $set: mixVal },
        },
      });

      // Update our currentUser in local storage. If our user reloads the app, we grab the user from there so we need to keep it current.
      setLocalStorageWithExpiry(
        import.meta.env.VITE_APP_LOCALSTORAGE_USER_KEY,
        objNewUser,
        currentUser.product.session_duration * 60 * 60 * 1000 // session_duration stores the number of hours that should elapse before expiration. Convert to milliseconds.
      );

      setCurrentUser(objNewUser);
    };

    return {
      currentUser,
      setCurrentUser,
      handleUserUpdate,
      handleProductUpdate,
    };
  }, [currentUser]);

  return <UserContext.Provider value={contextValue} {...props} />;
};
UserProvider.defaultProps = {
  currentUser: {
    id: 1,
    date_format: 'Y-m-d',
    time_format: 'H:i:s',
    time_zone: 'America/Denver',
  },
};
UserProvider.propTypes = {
  currentUser: PropTypes.shape({}),
};

export default UserProvider;
