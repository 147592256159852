import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';

// this doesn't spread a ...rest prop on purpose.
// if you are missing a mui prop, add it.
// if you want to add a onChange, onBlur, or onFocus, please contact Travis, or be really mindful of performance implications
const HookFormAutocompleteSegmentBuilderFields = memo(
  ({
    autoFocus,
    disabled,
    disableClearable,
    helperText,
    label,
    loading,
    multiple,
    name,
    onChange,
    onMouseDown,
    options,
    required,
    sx,
  }) => {
    const [currentValue, setCurrentValue] = useState(null);
    const [inputValue, setInputValue] = React.useState('');

    return (
      <Controller
        render={({ field: { ref, ...field }, fieldState: { invalid, error }, formState: { isSubmitting } }) => {
          return (
            <Autocomplete
              // these next 4 props let it be a controlled input https://mui.com/material-ui/react-autocomplete/#controlled-states
              value={field?.value ? field.value : currentValue}
              onChange={(_, newValue) => {
                setCurrentValue(newValue);
                field.onChange(newValue || null);
                onChange(newValue);
              }}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              inputValue={inputValue}
              onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
              }}
              groupBy={(option) => option.group || null}
              onBlur={field.onBlur}
              options={options}
              multiple={multiple}
              disabled={disabled || isSubmitting}
              disableClearable={disableClearable}
              noOptionsText="No options were found"
              size="small"
              sx={sx}
              renderInput={(params) => (
                <TextField
                  // this doesn't autofocus like textfield for some reason
                  inputRef={ref}
                  {...params}
                  error={invalid}
                  helperText={error ? error.message : helperText}
                  label={label}
                  required={required}
                  autoFocus={autoFocus}
                  variant="outlined"
                  onMouseDown={onMouseDown}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress size={20} sx={{ mr: 3 }} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  // in dndkit dragging an input will cause this to flash from input to label. prevent that
                  InputLabelProps={field.value !== null ? { shrink: true } : {}}
                />
              )}
            />
          );
        }}
        name={name}
      />
    );
  }
);

HookFormAutocompleteSegmentBuilderFields.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  disableClearable: PropTypes.bool,
  helperText: PropTypes.string,
  InputLabelProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func,
  onMouseDown: PropTypes.func,
  required: PropTypes.bool,
  sx: PropTypes.object,
};

HookFormAutocompleteSegmentBuilderFields.defaultProps = {
  autoFocus: false,
  disabled: false,
  disableClearable: false,
  helperText: null,
  InputLabelProps: {},
  loading: false,
  multiple: false,
  onChange: () => {},
  onMouseDown: () => {},
  required: false,
  sx: {},
};

export default HookFormAutocompleteSegmentBuilderFields;
