import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, IconButton, InputAdornment, Stack } from '@mui/material';
import queryString from 'query-string';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import NrButton from '../../NrMaterialOverrides/NrButton';
import { ResetPasswordMutation } from './queries.graphql';
import useHandleApolloErrors from '../../utilities/HandleApolloErrors';
import { FormikField } from '../../FormikFields';

const ResetPassword = ({ buttonLabel }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loginUser] = useMutation(ResetPasswordMutation);
  const { enqueueSnackbar } = useSnackbar();
  const { handleApolloErrors } = useHandleApolloErrors();
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const location = useLocation();
  const queryParams = queryString.parse(location.search, {
    parseNumbers: true,
  });
  const { token } = queryParams;

  const lowercaseRegex = /(?=.*[a-z])/;
  const uppercaseRegex = /(?=.*[A-Z])/;
  const numericRegex = /(?=.*[0-9])/;
  const validationSchema = Yup.object().shape({
    passwordRequiresSpecialCharacters: Yup.boolean(),
    password: Yup.string()
      .min(6, 'Your password must contain at least 6 characters')
      .matches(lowercaseRegex, 'Please include at least 1 lowercase letter')
      .matches(uppercaseRegex, 'Please include at least 1 uppercase letter')
      .matches(numericRegex, 'Please include at least 1 number')
      .required('Please enter a new password'),
  });

  const displaySnackbar = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    const variables = {
      variables: {
        password: values.password,
        token,
      },
    };

    try {
      await loginUser({
        ...variables,
      });
      setSubmitting(false);
      setResetSuccessful(true);
    } catch (error) {
      setSubmitting(false);
      handleApolloErrors(error, setFieldError, displaySnackbar);
    }
  };

  return (
    <Box>
      {resetSuccessful ? (
        <Box>
          <Box sx={{ display: 'inline-block' }}>Your password has been set.</Box>
          <Box
            sx={{
              display: 'inline-block',
              cursor: 'pointer',
              color: (theme) => theme.palette.brand.techBlue,
            }}
          >
            <Link to="/login">&nbsp;Click here to login</Link>.
          </Box>
        </Box>
      ) : (
        <Formik initialValues={{ password: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {(formik) => (
            <Form>
              <Stack direction="column" spacing={2}>
                <FormikField
                  id="new-password-input"
                  fieldType={passwordVisible ? 'text' : 'password'}
                  fieldLabel="New Password"
                  fieldName="password"
                  autoFocus
                  required
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {passwordVisible ? (
                          <Visibility data-testid="hidePassword" />
                        ) : (
                          <VisibilityOff data-testid="showPassword" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <NrButton
                  onClick={formik.submitForm}
                  disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                >
                  {buttonLabel}
                </NrButton>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

ResetPassword.defaultProps = {
  buttonLabel: 'Reset Password',
};

ResetPassword.propTypes = {
  buttonLabel: PropTypes.string,
};

export default ResetPassword;
