import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';
import { Formik, Form } from 'formik';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HookFormTextField } from '@/components/HookFormFields';
import { useSnackbar } from 'notistack';
import { Stack, Button, Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { setLocalStorageWithExpiry } from '@/utilities/localstorage';
import { useCurrentUser } from '@/context/UserContext';
import { FormikField } from '@/components/FormikFields';
import NrButton from '@/components/NrMaterialOverrides/NrButton';
import useHandleApolloErrors from '@/components/utilities/HandleApolloErrors';
import queryString from 'query-string';
import TransitionBounceIn from '@/components/Transitions/BounceIn';
import FadeInScale from '@/components/Transitions/FadeInScale';
import MfaConfiguration from '@/components/MfaConfiguration';
import { LoginUserMutation } from './queries.graphql';

const LoginForm = () => {
  const [mfaRequired, setMfaRequired] = useState(false);
  const [mfaSetupRequired, setMfaSetupRequired] = useState(false);
  const [usingMfaRecoveryCode, setUsinMfaRecoveryCode] = useState(false);
  const [userLoginResponse, setUserLoginResponse] = useState(null);
  const history = useHistory();
  const { currentUser, setCurrentUser } = useCurrentUser();
  const { reload } = useAuth();
  const [loginUser] = useMutation(LoginUserMutation);
  const { enqueueSnackbar } = useSnackbar();
  const { handleApolloErrors } = useHandleApolloErrors();
  const loginFormValidationSchema = Yup.object().shape({
    username: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter your username')
      .typeError('Please enter your username'),
    password: Yup.string().required('Please enter your password').typeError('Please enter your password'),
  });
  const twoFactorFormValidationSchema = Yup.object().shape({
    mfaCode: Yup.string().required('Please enter your code').typeError('Please enter your code'),
  });

  useEffect(() => {
    // user is already logged in, redirect to home
    if (currentUser && history.location.pathname === '/login') {
      history.push('/home');
    }
  }, [history, currentUser]);

  const twoFactorFormMethods = useForm({
    shouldUnregister: true,
    mode: 'onTouched',
    defaultValues: {
      mfaCode: '',
    },
    resolver: yupResolver(twoFactorFormValidationSchema),
  });

  const {
    formState: { isSubmitting: twoFactorFormIsSubmitting },
    setError: twoFactorFormSetError,
  } = twoFactorFormMethods;

  const displaySnackbar = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
    });
  };

  const sendNrFormSubmission = async (username) => {
    if (import.meta.env.VITE_APP_ENVIRONMENT === 'production' && window.$__MA !== undefined) {
      const formData = {
        EmailAddress: username,
        form_id: '8e2cb1bb-02d9-452c-8624-e47dcafe50b7',
        __mauuid: window.$__MA.getVisitorId().v,
      };

      const url = `${import.meta.env.VITE_APP_APPS_ENDPOINT}/data/public/IncomingForm/Ma/submit`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: queryString.stringify(formData),
      });
    }
  };

  const setLocalStorageAndLogin = (loginUserResponse) => {
    const loginResponse = loginUserResponse;
    const ttl = loginResponse.loginUser.product.session_duration * 60 * 60 * 1000; // session_duration stores the number of hours that should elapse before expiration. Convert to milliseconds.

    setLocalStorageWithExpiry(import.meta.env.VITE_APP_LOCALSTORAGE_AUTH_KEY, loginResponse.loginUser.auth_token, ttl);

    // Remove auth_token so it is not stored on currentUser (it's already in local storage, that's the only place we need it).
    delete loginResponse.loginUser.auth_token;

    if (mfaSetupRequired) {
      loginResponse.loginUser.mfa_enabled = true;
    }

    // Store our currentUser in local storage. We'll load our currentUser from there if the app is refreshed.
    setLocalStorageWithExpiry(import.meta.env.VITE_APP_LOCALSTORAGE_USER_KEY, loginResponse.loginUser, ttl);

    // Store our loginUser as currentUser in UserContext.
    setCurrentUser(loginResponse.loginUser);

    // this reloads the app bootstrap which re-checks local storage for a non-expired JWT, which will now be there, so the user will be logged in and <AuthenticatedApp/> will be loaded.
    reload();
    // if the path is the login route, redirect to home, else just render whatever page
    if (history.location.pathname === '/login' || history.location.pathname === '/') {
      history.push('/home');
    }
  };

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    const variables = {
      variables: {
        email: values.username,
        password: values.password,
      },
    };

    try {
      setSubmitting(true);
      const { data: loginResponse } = await loginUser({
        ...variables,
      });

      // Send a "form submission" to the NR form handler. This will associate the user's email address with their `__mauuid`.
      // A NR Form called "Goldilocks Login Capture" exists specifically for this purpose.
      sendNrFormSubmission(values.username);

      if (loginResponse.loginUser.mfa_enabled) {
        setMfaRequired(true);
        setUserLoginResponse(loginResponse);
        // we 100% dont want to set anything that makes a person authed in localstorage or anything here
        // if you think you do - DONT
        // add it to the function above
        // we want if someone reloads this page, they HAVE TO re-sign in
      } else if (loginResponse.loginUser.product.mfa_enabled_at) {
        // this means that at the product level we are forcing mfa, but the user has not set it up yet
        // force them to configure it
        setMfaSetupRequired(true);
        setUserLoginResponse(loginResponse);
      } else {
        setLocalStorageAndLogin(loginResponse);
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      handleApolloErrors(error, setFieldError, displaySnackbar);
    }
  };

  const handleTwoFactorSubmit = async (data) => {
    try {
      // seems a little crazy we'd need the jwt for this call right?
      // well actually cause we use stateless auth
      // this endpoint would have no idea who the hells code to check
      // so passing it with the request allows it to validate the code
      const jwt = userLoginResponse.loginUser.auth_token;
      const url = `${import.meta.env.VITE_APP_LARAVEL_ENDPOINT}/two-factor-challenge`;
      let mfaBody = { code: data.mfaCode };
      if (usingMfaRecoveryCode) {
        mfaBody = { recovery_code: data.mfaCode };
      }
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify(mfaBody),
      });
      const statusCode = response.status;

      if (statusCode === 204) {
        setLocalStorageAndLogin(userLoginResponse);
      } else if (statusCode >= 400 || statusCode < 500) {
        twoFactorFormSetError('mfaCode', { message: 'There was a problem validating this code. Please Try again.' });
      } else {
        displaySnackbar('There was a problem completing your request', 'error');
        Sentry.captureException(response);
      }
    } catch (error) {
      displaySnackbar('There was a problem completing your request', 'error');
      Sentry.captureException(error);
    }
  };

  if (mfaSetupRequired) {
    return (
      <MfaConfiguration
        productLevelForcedConfigure
        userJwt={userLoginResponse.loginUser.auth_token}
        onSetupComplete={() => {
          setLocalStorageAndLogin(userLoginResponse);
        }}
      />
    );
  }

  return mfaRequired ? (
    <FormProvider {...twoFactorFormMethods}>
      <form onSubmit={twoFactorFormMethods.handleSubmit(handleTwoFactorSubmit)}>
        <FadeInScale>
          <Stack spacing={2} alignItems="center">
            {usingMfaRecoveryCode ? (
              <Box sx={{ width: '100%' }}>
                <HookFormTextField name="mfaCode" label="Recovery Code" type="text" fullWidth required />
                <Button
                  size="small"
                  onClick={() => {
                    setUsinMfaRecoveryCode(false);
                  }}
                >
                  Use 2FA Code
                </Button>
              </Box>
            ) : (
              <Box sx={{ width: '100%' }}>
                <HookFormTextField name="mfaCode" label="Secure 2FA Code" type="text" fullWidth required autoFocus />
                <Button
                  size="small"
                  onClick={() => {
                    setUsinMfaRecoveryCode(true);
                  }}
                >
                  Use Recovery Code
                </Button>
              </Box>
            )}
            <NrButton
              dataTestId="twoFactorButton"
              disabled={twoFactorFormIsSubmitting}
              buttonType="submit"
              isLoading={twoFactorFormIsSubmitting}
            >
              Submit
            </NrButton>
          </Stack>
        </FadeInScale>
      </form>
    </FormProvider>
  ) : (
    <Formik
      initialValues={{ username: '', password: '' }}
      validationSchema={loginFormValidationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <TransitionBounceIn>
            <Stack direction="column" alignItems="center" spacing={2}>
              <FormikField fieldType="text" fieldLabel="Email Address" fieldName="username" autoFocus />
              <FormikField fieldType="password" fieldLabel="Password" fieldName="password" />

              <NrButton
                dataTestId="loginButton"
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                buttonType="submit"
                isLoading={formik.isSubmitting}
              >
                Login
              </NrButton>
            </Stack>
          </TransitionBounceIn>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
