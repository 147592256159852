import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Transition } from 'react-transition-group';
import { keyframes } from '@mui/system';

const animationDuration = '1s';

const bounce = keyframes`
  0% {
    opacity: 0;
    transform: scale(.9, .9);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
`;

const rollOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
`;

const TransitionFadeInScale = ({ children }) => {
  const defaultStyle = {
    transition: `all 1000ms ease-in-out`,
    opacity: 0,
  };

  // this is what you would do if you want the rubberBand animation to start while others run
  //  also shows a additional animation on dismiss
  const transitionStyles = {
    entering: {
      opacity: 1,
      animationName: `${bounce}`,
      animationDuration,
    },
    entered: {
      opacity: 1,
      animationName: `${bounce}`,
      animationDuration,
    },
    exiting: {
      opacity: 0,
      animationName: `${rollOut}`,
      animationDuration,
    },
    exited: {
      opacity: 0,
      animationName: `${rollOut}`,
      animationDuration,
    },
  };

  return (
    <Transition in appear timeout={1000}>
      {(state) => (
        <Box
          sx={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </Box>
      )}
    </Transition>
  );
};

TransitionFadeInScale.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TransitionFadeInScale;
