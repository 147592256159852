import React from 'react';
import { FormControl, FormLabel, Stack, Switch, Tooltip } from '@mui/material';
import { Brightness3 as DarkIcon, Brightness7 as LightIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { useCurrentUser } from '@/context/UserContext';
import { dynamicMutation } from '@/utilities/dynamicGraphQL';
import DataLoading from '../../../DataLoading';

const ThemeToggle = () => {
  const { currentUser, handleUserUpdate } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [mutatePreference, { loading: mutationLoading, error: mutationError }] = useMutation(
    dynamicMutation('updateCurrentUser', [{ fieldName: 'theme', type: 'String!' }], ['theme']),
    {
      onCompleted() {
        enqueueSnackbar(`Your theme preference has been saved.`, {
          variant: 'success',
        });
      },
    }
  );

  const handleSwitchChange = () => {
    let newTheme;
    if (currentUser.theme === 'themeLight') {
      newTheme = 'themeDark';
    } else {
      newTheme = 'themeLight';
    }

    // update the value in userContext.
    handleUserUpdate('theme', newTheme);

    mutatePreference({
      variables: { id: currentUser.id, theme: newTheme },
    }).then(() => {
      if (mutationError) {
        enqueueSnackbar(`We had trouble updating your preference. We've been notified and will figure it out .`, {
          variant: 'error',
        });
        // todo: error handling for this?
      }
    });
  };

  if (mutationLoading) return <DataLoading padding={0} />;

  return (
    <FormControl component="div" variant="outlined" size="small">
      <FormLabel component="legend" sx={{ fontSize: '12px' }}>
        Choose Your Theme
      </FormLabel>
      <Stack direction="row" spacing={1} alignItems="center" pb={1}>
        <Tooltip title="Light Theme" placement="top" arrow enterDelay={700}>
          <LightIcon
            onClick={currentUser.theme === 'themeDark' ? handleSwitchChange : null}
            sx={{ cursor: currentUser.theme === 'themeDark' ? 'pointer' : 'default' }}
          />
        </Tooltip>
        <Switch checked={currentUser.theme === 'themeDark'} onChange={handleSwitchChange} size="small" />
        <Tooltip title="Dark Theme" placement="top" arrow enterDelay={700}>
          <DarkIcon
            onClick={currentUser.theme === 'themeLight' ? handleSwitchChange : null}
            sx={{ cursor: currentUser.theme === 'themeLight' ? 'pointer' : 'default' }}
          />
        </Tooltip>
      </Stack>
    </FormControl>
  );
};

export default ThemeToggle;
